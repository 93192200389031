import * as React from 'react';
import { styled } from '@mui/material/styles';
import "../Css/MCLloyd.css"
import {
    Grid,
    CardActions,
    Divider,
    Card,
    IconButton,
    Typography,
    Collapse,
    Avatar
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RenderDivider from '../../Component/RenderDivider';
import { useTranslation } from "react-i18next";
import HorsePlaceholder from '../../img/ProfileHorsePlaceholder.svg';

import MCLloydSlider from './MCLloydSlider';
import MyRecord from '../../Component/MyRecord/MyRecord';
import { useState, useContext } from 'react';
import { getMCLloydCardList } from '../../utils';
import HorseContext from '../../utils/horseContext';
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    //   color: '#fff'
}));


const MockData = [{
    season: '23/24 Season',
    horseName: 'GOLDEN SIXTY',
    RaceNum: 8,
    placeNo: '2',
    Title: 'WONG NAI CHUNG GAP HANDICAP',
    workout: 'Class 4, 1200M, Turf, "B" Course',
    local: 'Sha Tin',
    time: '30/04/2023',
    raceClass: '3',
    distance: '1600M',
    track: 'Turf',
    course: 'C+3',
    going: 'Good',
    trainer: 'K W Lui',
    jockey: 'C Y Ho',
    finishTime: '1:43.34',
    videoLink: 'test',
}]

export default function MCLloyd(props) {
    const { data: { horse } } = props;
    const [expanded, setExpanded] = React.useState(false);
    const { t, i18n } = useTranslation();

    const [horseData, setHorseData] = React.useState([]);
    const { clientData, businessDate } = useContext(HorseContext);
    React.useEffect(() => {
        const newData = getMCLloydCardList(horse, clientData, businessDate);
        console.log('【newData---】', newData)
        setHorseData(newData)
    }, [horse])
    const getRecordData = () => {
        const arr = []
        horse?.map((h) => {
            if (h.horseFormRecord.length !== 0) {
                arr.push(h)
            }
        })
        return arr
    }
    // console.log('【------------MCLloyd data】', horse)
    return (
        <Grid container style={{ position: 'relative',backgroundColor:'white', paddingBottom: 28}} >
            <div className="MCLloyd-Header">
                <div id='PRA_Title' className="MCLloyd-Title PlayFair">
                    {t("Lbl_MacL_Analysis")}
                </div>
                <div style={{ flex: 1 }} />
            </div>
            {
                horseData.map((item, idx) => {
                    return (
                        <MCLloydCard data={item} idx={idx} itemStyle={{ marginBottom: idx + 1 === horseData.length ? 0 : 16 }} />
                    )
                })
            }
            <div style={{height: 12}}/>
        </Grid>
    );
}


const MCLloydCard = (props) => {
    const { data, idx, itemStyle } = props;
    const { t, i18n } = useTranslation();

    const [expanded, setExpanded] = React.useState(false);
    const [isImgLoading,setImgIsLoading] = useState(true);
    const [isImgError,setImgIsError] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const i = data;
    
    const handleImageLoad = () => {
        setImgIsLoading(false);
    };
    const handleImageError = () => {
        setImgIsError(true);
        setImgIsLoading(false);
    };

    console.log('【------------MCLloydCard data】', data, idx)
    return (
        <Card className='MCLloyd-root' mb={2} px={2} style={{...itemStyle, ...{backgroundColor:'white'}}} id={'McLloydCard'+idx} >
            <CardActions disableSpacing onClick={handleExpandClick}>
                <div className="MCLloyd-flex">
                    <Avatar className="MCLloyd-avatar"
                        src={isImgLoading ? 
                            HorsePlaceholder : isImgError ? 
                            HorsePlaceholder : `${window.globalConfig.SITE_DOMAIN}${window.globalConfig.HORSE_IMAGE_PATH}${i.brandNumber}_l.jpg`}
                        onLoad={handleImageLoad}
                        onError={handleImageError}
                        // src={`${window.globalConfig.SITE_DOMAIN}${window.globalConfig.HORSE_IMAGE_PATH}${i.brandNumber}_l.jpg`}
                    />
                    <Typography paragraph className='MCLloyd-headerTitle' id="PRA_Title">
                        {i.horseName}
                    </Typography>
                </div>
                <ExpandMore
                    expand={expanded}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon id="PRA_ArrowIcon" />
                </ExpandMore>
            </CardActions>

            <Collapse in={expanded} timeout="auto" unmountOnExit className='MCLloydCollapse'>

                <Grid container className='MCLloyd-Card' pb={6} key={`PRA_RaceNum_${idx}`}>
                    <MyRecord 
                        propId={data.brandNumber}
                        propOwnerType={data.ownerType}
                        data={data?.horseFormRecord_ForMyRecord}
                        isTitle={false} type='postRace' />

                    <Grid container>
                        <Grid container mt={2} mb={2.5}>
                            <Divider variant="middle" style={{ background: '#CED3D9', opacity: 0.3, flex: 1, margin: 0 }} />
                        </Grid>

                        <MCLloydSlider data={data} idx={idx} />
                    </Grid>
                    
                  

                </Grid>
            </Collapse>
        </Card>
    )
}