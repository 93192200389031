import { errorToMobile } from '../utils'
const uri = window.globalConfig.GRAPHQL_URL;
const request = async (variables, query, ttx, errorCode) => {
    try {
        const requestBody = {
            variables,
            query
        }
        const options = {
            method: 'POST',
            mode: "cors",
            credentials: "omit",
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                "accept": "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "application/json",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "cross-site"
            },
            body: JSON.stringify(requestBody),
        }
        const response = await fetch(uri, options);
        const data = await response.json();
    
        const { errors, data: graphQLData } = data;
        // console.log('GraphQL error1111:', graphQLData, errors);
        if (errors) {
            console.log('GraphQL errors:', errors?.[0]?.message, response?.status, JSON.stringify(errors));
            throw {
                message: errors?.[0]?.message, 
                code: response?.status,
            };
        } else {
            return graphQLData;
        }
    } catch (error) {
        // query isn't whitelisted
        // if (error?.code === 200 && error?.message) {
        //     errorToMobile(error?.code, error?.message)
        // } else {
            // console.log('ttx("Lbl_NetWork_Fail")', ttx("Lbl_NetWork_Fail"))
            const code = `(HO-71-M${errorCode})`;
            const title = ttx("Lbl_NetWork_Fail") + code;
            const dec = title;
            errorToMobile(code, title, dec)
        // }
        
        return {
            message: error?.message,
            code:error?.code,
        }
    }
   
}
export default request;