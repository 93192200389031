import { useEffect, useState, useContext } from 'react'
import { useTranslation, Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import Header from '../Component/Header';
import './index.css'
import { getHorseRaceFormDetail } from '../request/requestList'
import MyRecord from '../Component/MyRecord/MyRecord'
import SectionalTime from './components/SectionalTime'
import IncidentReport from './components/IncidentReport';

import ResultTable from './components/ResultTable';
import ExplainPlaDrawer from '../FormRecords/ExplainPlaDrawer'

import PostRaceAnalysis from './components/PostRaceAnalysis';
import RaceDetailMenu from './components/RaceDetailMenu';
import { useLocation } from 'react-router-dom';
import { checkIfPlaceNoValid } from '../utils'
import dayjs from 'dayjs';
import HorseContext from '../utils/horseContext';
import LoadingOverlay from '../Component/loading/loading-overlay';
import CommentsOnRunning from './components/CommentsOnRunning';

const DividerPadding = (props) => <div style={{height: 36, ...props.style}}></div>


const RaceDetail = (props) => {
    const { raceNo, date, isHistory } = useLocation().state
    const { t, i18n } = useTranslation();
    const { id, ownerType, lang } = useParams();
    const [horseData, setHorseData] = useState([])
    const [plaOpen, setPlaOpen] = useState(false)
    const [raceRecord, setRaceRecord] = useState([])
    const [fullResult, setFullResult] = useState([])
    const { businessDate, setbusinessDate } = useContext(HorseContext);
    const [Load,setLoad] = useState(true);
    useEffect(() => {
        getHorseRaceFormDetail({
            horses: [{
                "brandNumber": id,
                "ownerType": ownerType,
            }]
        }, t).then((res) => {
            const date = res?.timeOffset?.ts;
            const formatDate = dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD");
            date && setbusinessDate(formatDate);
            setHorseData(res)
            setLoad(false)
        })
    }, [])

    const clickPla = () => {
        setPlaOpen(true)
    }
    const noSectionalTime = (record) => {
        let result = true;
        record?.sectionInfos?.map((item) => {
            if (item.sectionTime || item.ruPos > 0) {
                result = false;
            }
        })
        return result;
    }
    const showSectionalTime = () => raceRecord?.[0]?.sectionInfos.length < 1 && noSectionalTime(raceRecord?.[0]) && raceRecord?.[0]?.raceTimeSplits?.length<1;
    const getTabContentId = () => {
        const originArr = ['RD_FR_container', 'PostRaceAnalysisTitle', 'SectionalTimeTitle', 'RD_RIR_Tilte','CommentsOnRunning','FullResultsTitle'];
        let filterArr = [];
        let resultArr = []
        if(!checkIfPlaceNoValid(raceRecord?.[0])){
            filterArr.push('PostRaceAnalysisTitle')
        }
        if (!raceRecord?.[0]?.incidentReport) {
            filterArr.push('RD_RIR_Tilte')
        }
        if (showSectionalTime()) {
            filterArr.push('SectionalTimeTitle')
        }
        if(!showCommentsOnRunning()){
            filterArr.push('CommentsOnRunning')
        }
        resultArr = originArr.filter(item => !filterArr.includes(item));
        return resultArr
    }
    useEffect(() => {
        let resultData = []
        let arr = []
        const originData = horseData?.horse?.[0]?.horseFormRecord || []
        console.log(horseData, 'horseData')
        if (isHistory) {
            horseData?.horse?.[0]?.horseFormRecord?.map((item) => {
                if (item.raceNo === raceNo && item.date === date) {
                    resultData = item.runnerResults
                    arr.push(item)
                    setRaceRecord(arr)
                }
            })
        } else {
            const tempData = originData?.filter((item) => !item.overseas) || [];
            setRaceRecord(tempData)
            resultData = tempData?.[0]?.runnerResults
        }
        setFullResult(resultData)
    }, [horseData])
    const getHorseName = () => {
        const horse = horseData?.horse?.[0];
        return lang === 'ch' ? horse?.name_ch === "" ? "未命名" : horse?.name_ch : horse?.name_en === horse?.brandNumber ? "UNNAMED" : horse?.name_en;
    }
    console.log(!!(raceRecord?.[0]?.racingComments?.chiOfficialComments || raceRecord?.[0]?.racingComments?.engOfficalComments),'com')
    const showCommentsOnRunning = () => !!((raceRecord?.[0]?.racingComments?.chiOfficialComments &&raceRecord?.[0]?.racingComments?.chiHeader)||(raceRecord?.[0]?.racingComments?.engOfficalComments&&raceRecord?.[0]?.racingComments?.engHeader));
    return (
        <>
            {Load&&<LoadingOverlay/>}
            <RaceDetailMenu tabContentId={getTabContentId()} />
            <div className='dr_container'>
                <MyRecord isTitle={false} type='raceDetail' data={raceRecord} />
                <DividerPadding style={{height: 20}}/>
                {
                    checkIfPlaceNoValid(raceRecord?.[0]) && <>
                        <PostRaceAnalysis data={{ ...raceRecord?.[0], ...{
                            brandNumber: id,
                            trackCode: raceRecord?.[0]?.track?.code,
                            venueCode: raceRecord?.[0]?.venue?.code,
                            _distance: raceRecord?.[0]?.distance,
                        } }} />
                        <DividerPadding />
                    </>
                }
                {!showSectionalTime() ?
                    <>
                        <SectionalTime data={raceRecord?.[0]} horseName={getHorseName()} />
                        <DividerPadding />
                    </> :null
                } 
                
                {showCommentsOnRunning() ? 
                    <>
                        <CommentsOnRunning data={raceRecord?.[0]?.racingComments}/>
                        <DividerPadding />
                    </> :null
                }
                
                {horseData?.horse?.[0] && raceRecord?.[0]?.incidentReport ?
                    <>
                        <IncidentReport data={raceRecord?.[0]} name={i18n.language == 'ch' ? horseData?.horse?.[0].name_ch : horseData?.horse?.[0].name_en} />
                        <DividerPadding />
                    </> :null
                }


                <ResultTable onClickPla={clickPla} data={fullResult} />
            </div>
            <ExplainPlaDrawer open={plaOpen} setOpen={setPlaOpen} />
        </>
    )
}
export default RaceDetail;