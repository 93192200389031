
import styles from '../Css/WinningMoments.module.css'
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { 
    useEventListener, 
    isContain, 
    getLatestWinMoment,
    checkIfNeedAnimate,
    updateFireWorks,
 } from '../../utils';
import WMCard from './WMCard';
import lottie from 'lottie-web';
import { useParams } from 'react-router-dom';
import  '../Css/winningMonents.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import HorseContext from '../../utils/horseContext';
const mock = [{
    season: '22/23 Season',
    racetitle: 'THE BOC PRIVATE WEALTH JOCKEY CLUB MILE',
    position: 'Sha Tin',
    date: '30/04/2023',

    raceClass: 'G1',
    distance: '1600M',
    finishTime: '1:33.34',
    trainer: 'K W Lui',
    Jockey: 'C Y HO',
    videoReplayUrl: true,
}]

function WinningMoments(props){
    const { data, hasWinMoments } = props;
    const { id, ownerType, lang } = useParams();

    const { t, i18n } = useTranslation();
    const [boomState, setBoomState] = useState('block');
    const [ifNeedBooms, setifNeedBooms] = useState(false);
    const [isBoomReady, setisBoomReady] = useState(false);
    const [wmDataSource, setwmDataSource] = useState(mock);
    const navigate = useNavigate();
    const showMore = data.filter(i => i.winningHorse).length > 1;

    const { businessDate } = useContext(HorseContext); 
    // console.log('【businessDate------------】', businessDate)
    const setFireAnimate = () => {
        if (!ifNeedBooms) return 

        lottie.play();
        updateFireWorks(wmDataSource[0]?.date, id); // 更新动作

        setTimeout(() => {
            lottie.stop();
            setBoomState('none');
        },1800)
    }

    const onScroll = () => {
        const winMomentSection = document.getElementById('WinningMomentsTitle');
        if(isContain(winMomentSection)) setFireAnimate();
    }

    useEventListener('scroll', onScroll)

    useEffect(() => {
        const wmData = getLatestWinMoment(data, t);
        // console.log('wmData----', wmData)
        if (wmData.length) {
            const date = wmData[0]?.date;

            const animate = checkIfNeedAnimate(date, id, businessDate);
            // console.log('【animateanimate】', animate)
            if (animate) {
                const lottieAnimate = lottie.loadAnimation({
                    container: document.getElementById('bm'),
                    renderer: 'svg',
                    loop: true,
                    autoplay: false,
                    path: window.location.origin + '/hoe/firebooms.json',
                    
                })
                lottieAnimate.addEventListener("data_ready",() => {
                    setisBoomReady(true)
                })
            }
            setifNeedBooms(animate);
            setwmDataSource(wmData);
        }
        
    },[])
    return(
        <div id='WinningMoments' className={styles.Base}>
            <div className={styles.Header}>
                <div id='WinningMomentsTitle' className={`Title/Title1-Bold ${styles.Title} PlayFair`} >
                {t("Lbl_WinningMoments")}
                </div>
                <div style={{flex:1}}/>

                {showMore && <div className={`Label2-Medium ${styles.More}`}   id={"See_All_W"}  
                    onClick={()=>{
                        // lottie.play();
                        // clearTimeout(timer.current);
                        // timer.current = setTimeout(() => {
                        //     lottie.stop();
                        // },1800)
                        const filterData = data.filter(i => i.winningHorse); 
                        // console.log('【filterData---】', JSON.parse(JSON.stringify(filterData)), JSON.parse(JSON.stringify(data)))
                        navigate(`/${i18n.language}/winningMoment?cuno=${window.cuno}`,{state:{data: filterData}} )
                    }}
                >
                    {t("Lbl_SeeAll")}
                </div>}
            </div>
            
            <WMCard boomState={boomState} isBoomReady={isBoomReady} dataSource={wmDataSource}/>
        </div>
     )
}
export default WinningMoments;