import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from 'react-router-dom';
import NewsCard from "../Component/whatNewsCard";
import { Tabs, Tab, Grid } from '@mui/material';
import './Css/HorseTabs.css'
import { OnGoBackBtnClick, shareToMobile, isNull } from "../utils";
import dayjs from 'dayjs';
import HorseContext from '../utils/horseContext';
import Header from "../Component/Header";
import LoadingOverlay from "../Component/loading/loading-overlay";

function Notification(props) {

    const { t, i18n } = useTranslation();
    const { id, lang } = useParams();
    const [value, setValue] = useState(0)
    const [currentSelectVal, setCurrentSelectVal] = useState(0)
    const { businessDate, setbusinessDate, clientData } = useContext(HorseContext);
    const [horseData, setHorseData] = useState([])
    const [newsRenderData, setNewsRenderData] = useState([])
    const [promotion,setPromotion] = useState([])
    const [Load,setLoad] = useState(true);

    const { state } = useLocation();
    const getHorseTab = (data) => {
        if (data.length) {
            let newData = data?.map(item => {
                const ownerType = []
                return { ...item, ...ownerType };
            })
            newData.unshift({
                brandNumber: "-1",
                id: "-1",
                name_ch: t("Lbl_All"),
                name_en: 'All',
                ownerType: '-1',
            })
            setHorseData(newData)
        } else {
            setHorseData([{
                brandNumber: "-1",
                id: "-1",
                name_ch: t("Lbl_All"),
                name_en: 'All',
                ownerType: '-1',
            }])
        }
    }
    useEffect(() => {
        if (state?.data?.length) {
            getRenderData(state?.data);
            getHorseTab(state?.data)
        } else {
            getHorseTab([])
        }
        
        const fetchData = async()=>{
            try {
            const response = await fetch('Config/PromotionConfig.json');
            const data = await response.json();
            console.log(data)
            setPromotion(data)
            } catch (error) {
            console.error('Error fetching JSON:', error);
            } finally {
                setLoad(false)
            }
        }
        fetchData();
    }, [])
    const onTabsChange = (_, val) => {
        setValue(val)
        setCurrentSelectVal(val)
    }
    const getWinningData = (itemData) => {
        const filterData = []
        const horseName = lang === 'ch' ? itemData?.name_ch === "" ? "未命名" : itemData?.name_ch : itemData?.name_en === itemData.brandNumber ? "UNNAMED" : itemData?.name_en
        const threeDaysBefore = new Date(businessDate).getTime() - 2 * 24 * 60 * 60 * 1000;
        if(itemData.horseFormRecord!==undefined){
            for (const item of itemData.horseFormRecord) {
                const dateObj = new Date(item.date.replace('+08:00', ''))
                const dateTimeBefore = dateObj.getTime();
                if (dateTimeBefore >= threeDaysBefore) {
                    filterData.push({ ...item, horseName: horseName })
                }
            }
        }

        console.log("filterData", filterData)
        return filterData;
    }
    // const isAllOtherStatus = (data) => {
    //     let resultBoolean = true;
    //     data?.map((item) => {
    //         if (item.runnerStatus.toLowerCase() === 'entry' || item.runnerStatus === 'reserve' || item.runnerStatus.toLowerCase() === 'declared' || item.runnerStatus.toLowerCase() === 'scratched' || item.runnerStatus.toLowerCase() === 'winningmoment') {
    //             resultBoolean = false;
    //         }
    //     })
    //     return resultBoolean;
    // }
    const isSOD = (race) => {
        return dayjs(race.date, "YYYY-MM-DD").format("YYYY-MM-DD") === businessDate;
    }
    const getRenderData = (propData) => {
        let resultData = [];
        propData?.map((item) => {
            item.futureEntry?.map((entry)=>{
                if (entry?.runnerStatus) {
                    resultData.push({
                        sortId: entry?.runnerStatus === 'Entry' ? 4 : 5,
                        brandNumber: item?.brandNumber,
                        runnerStatus: isNull(entry?.runnerStatus === 'Entry' ? 'Entry' : 'reserve'),
                        horseName: isNull(lang === 'ch' ? item?.name_ch === "" ? "未命名" : item?.name_ch : item?.name_en === item.brandNumber ? "UNNAMED" : item?.name_en),
                        className: isNull(lang === 'en' ? entry?.className.english : entry?.className.chinese),
                        distance: isNull(entry?.distance),
                        track: isNull(lang === 'en' ? entry?.track?.english : entry?.track?.chinese),
                        date: isNull(entry ? entry?.date.replace("+08:00", "") : entry?.date.replace("+08:00", "")),
                        reserveNo: isNull(entry?.reserveNo),
                        raceNo: isNull(entry?.raceNo),
                        startTime: isNull(entry?.startTime),
                        drawNo: isNull(entry?.barrierDrNo),
                        raceCourse: isNull(lang === 'en' ? entry?.venue.english : entry?.venue.chinese),
                    })
                }
            })
            item.futureRaceCard?.map((race) => {
                if (race?.isHorseOwner) {
                    if (race?.runnerStatus && (race?.runnerStatus !== 'Standby' && !isSOD(race))||(race?.runnerStatus == 'Scratched' && isSOD(race) )) {
                        resultData.push({
                            sortId: race?.runnerStatus === 'Declared' ? 3 : 6,
                            brandNumber: item?.brandNumber,
                            runnerStatus: isNull(race?.runnerStatus),
                            horseName: isNull(lang === 'ch' ? item?.name_ch === "" ? "未命名" : item?.name_ch : item?.name_en === item.brandNumber ? "UNNAMED" : item?.name_en),
                            horseNo: isNull(race?.horseNo),
                            className: isNull(lang === 'en' ? race?.className.english : race?.className.chinese),
                            distance: isNull(race?.distance),
                            track: isNull(lang === 'en' ? race?.track?.english : race?.track?.chinese),
                            date: isNull(race ? race?.date.replace("+08:00", "") : race?.date.replace("+08:00", "")),
                            jockey: lang === 'en' ? race?.jockey?.name_en : race?.jockey?.name_ch,
                            raceNo: isNull(race?.raceNo),
                            startTime: isNull(race?.startTime),
                            drawNo: isNull(race?.barrierDrNo),
                            raceCourse: isNull(lang === 'en' ? race?.venue.english : race?.venue.chinese),
                        })
                    }
                }
                return ''
            })
            const filterWinningData = getWinningData(item);
            filterWinningData?.map((data) => {
                if (data?.winningHorse&&!data?.overseas) {
                    resultData.push({
                        sortId: 2,
                        brandNumber: item?.brandNumber,
                        runnerStatus: 'winningMoment',
                        horseNo: isNull(data?.runnerResults?.filter((i) => Number(i.placeNo) === 1 && i.barrierDrNo === data.barrierDrNo)[0]?.horseNo),
                        raceNo: isNull(data.raceNo),
                        track: isNull(lang === 'en' ? data?.track?.english : data?.track?.chinese),
                        horseName: isNull(data.horseName),
                        className: isNull(lang === 'en' ? data?.className?.english : data?.className?.chinese),
                        distance: isNull(data?.distance),
                        raceName: isNull(lang === 'en' ? data?.raceName?.english : data?.raceName?.chinese),
                        date: isNull(data ? data?.date.replace("+08:00", "") : data?.date.replace("+08:00", "")),
                    })
                }
                return ''
            })
        })

        setNewsRenderData(resultData)
        // if (resultData.length === 0 || isAllOtherStatus(resultData)) {
        //     setDisplayNoMsg(true)
        // }
    }
    const renderMyhorseItem = () => {
        if (horseData) {
            let newArr = horseData;
            return (
                <Tabs
                    id='hosreTab'
                    variant="scrollable"
                    scrollButtons={false}
                    value={value}
                    onChange={onTabsChange}>
                    {newArr.map((item, index) => (<Tab id={item.brandNumber + '_tab'} className='menu-tab' label={lang === 'ch' ? item?.name_ch === "" ? "未命名" : item?.name_ch : item?.name_en === item.brandNumber ? "UNNAMED" : item?.name_en} />))}
                </Tabs>
            )
        }

    }

    const renderPromotionCard = ()=>{
        console.log(promotion)
        if(promotion.length>0){
            return(           
                promotion.map(item=>{
                    return(
                        <NewsCard data={item}
                            className='owner' 
                            id="promotionIcon" 
                            newsContent={lang === "en" ? item.contentEN:decodeURIComponent(item.contentCH)}
                            newsOperator={lang === "en" ? item.buttonEN:decodeURIComponent(item.buttonCH)} 
                            newsType={"promotion"} 
                            isNoti={true}
                            pathCH={item.pathCH}
                            pathEN={item.pathEN}
                        />
                    )
                })
            )
        }
        return null  
    }
    
    function FormatDateToString(d, pvalue) {
        let wName = ''
        const dateDefaultFormat = 'YYYY-MM-DD';
        const dayNum = dayjs(d, dateDefaultFormat).day();
        switch (dayNum) {
            case 0:
                wName = lang === "en" ? "Sunday" : "星期日"
                break;
            case 1:
                wName = lang === "en" ? "Monday" : "星期一"
                break;
            case 2:
                wName = lang === "en" ? "Tuesday" : "星期二"
                break;
            case 3:
                wName = lang === "en" ? "Wednesday" : "星期三"
                break;
            case 4:
                wName = lang === "en" ? "Thursday" : "星期四"
                break;
            case 5:
                wName = lang === "en" ? "Friday" : "星期五"
                break;
            case 6:
                wName = lang === "en" ? "Saturday" : "星期六"
                break;
            default:
                break;
        }

        if (pvalue === "promotion") {
            if (lang === "en") {
                return wName + ", " + dayjs(d, dateDefaultFormat).format('D MMMM YYYY');
            } else {
                return dayjs(d, dateDefaultFormat).format('YYYY年M月D日') + " (" + wName + ") "
            }
        }
        else {
            if (lang === "en") {

                return dayjs(d, dateDefaultFormat).format('D MMMM YYYY');
            }
            else {
                return dayjs(d, dateDefaultFormat).format('YYYY年M月D日')
            }

        }
    }

    const renderNewsCard = () => {
        let renderArr = []
        const data = newsRenderData;
        data.sort((a, b) => {
            if (a.sortId !== b.sortId) {
                return a.sortId - b.sortId;
            } else {
                const raceNoA = typeof a.raceNo === 'string' ? Number(a.raceNo?.replace('P', '')) : Number(a.raceNo)
                const raceNoB = typeof b.raceNo === 'string' ? Number(b.raceNo?.replace('P', '')) : Number(b.raceNo)
                const dateA = new Date(a.date)?.getTime()
                const dateB = new Date(b.date)?.getTime()
                if (dateA === dateB) {
                    if (raceNoA !== raceNoB) {
                        return raceNoA - raceNoB;
                    } else {
                        return a.horseNo - b.horseNo;
                    }
                } else {
                    return dateA - dateB;
                }
            }
        });
        renderArr = data?.filter(item => {
            const brandNumber = horseData[currentSelectVal]?.brandNumber
            return brandNumber && !brandNumber.includes("-1") ? item.brandNumber === brandNumber : true
        }).map((item) => {
            const type = item.runnerStatus?.toLowerCase()
            let show = true;
            let content = ''
            let operator = ''
            const newDate = FormatDateToString(item.date);
            switch (type) {
                case "entry":
                    content = lang === "en" ? `${item.horseName} will run in the ${item.className}, ${item.distance}M (${item.track}) on ${newDate}.` : `${item.horseName}將於${newDate}出賽(${item.className}${item.distance}米${item.track})。`
                    operator = "Lbl_Share"
                    break;
                case "reserve":
                    content = lang === "en" ? `${item.horseName} is Reserve ${item.reserveNo} in the ${item.className}, ${item.distance}M (${item.track}) on ${newDate}.` : `${item.horseName}暫於${newDate}(${item.className}${item.distance}米${item.track})列為後備馬匹${item.reserveNo}。`
                    operator = "Lbl_Share"
                    break;
                case "scratched":
                    content = lang === "en" ? `${item.horseName} scratched from ${newDate} ${item.raceCourse} Race Meeting.` : `${item.horseName}退出${newDate}${item.raceCourse}競賽。`
                    break;
                case "declared":
                    content = lang === "en" ? `${item.horseName} will be ridden by ${item.jockey} in Race ${item.raceNo} (${item.distance}M) with No.${item.horseNo} at ${dayjs(item.startTime).format("HH:mm")} on ${newDate}, Drawn ${item.drawNo} ${item.raceCourse} Racecourse.` : `${item.horseName}已被列為${newDate}第${item.raceNo}場(${item.distance}米, ${dayjs(item.startTime).format("HH:mm")}開跑)第${item.horseNo}號馬, 騎師${item.jockey}, 第${item.drawNo}檔, ${item.raceCourse}馬場。`
                    operator = "Lbl_Share"
                    break;
                case "winningmoment":
                    content = lang === "en" ? `Congratulations! ${item.horseName} wins the ${item.className}, ${item.distance}M (${item.track}) - ${item.raceName} on ${newDate}! Let's celebrate the victorious moment!` : `恭喜！${item.horseName}勝出${newDate}(${item.className}${item.distance}米${item.track})${item.raceName}！慶祝光輝時刻！`
                    // operator = "Lbl_WinMoment_ViewPhoto"
                    break;
                default:
                    show = false;
                    break;
            }
            return show && <NewsCard data={item} isNoti={true} className={'owner'} entry='notification' id={type} newsType={type} newsOperator={operator} newsContent={content} />
        })
        if (
            (!renderArr.length && value !== 0) || 
            (!renderArr.length && !promotion.length && value === 0)) {
            return  <>
            <div className="news-card-container">
                <div className="news-content-wrapper undefined-ch-min-height card-bottom news-card-no-message">
                    <div className="">{t('Lbl_NoMessage')}</div>
                </div>
            </div>
        </>
        }
        return renderArr;
    }
    return (
        <>
           {Load&&<LoadingOverlay/>}
            <div className="horse-tabs-wrapper-root">
                <div id='floatingDiv' className="horse-tabs-wrapper">
                    <Header title={t('Lbl_Notification')}/>
                    {
                        renderMyhorseItem()
                    }
                </div>
                <div id="notify_container" className="TabletBaseContainer" style={{
                        marginTop: 100,
                        height: window.innerHeight - 100,
                        overflowY: "scroll"
                    }}>
                        <div style={{ paddingTop: 16 }}>
                        {
                            value === 0 ?
                                renderPromotionCard()
                            : null
                        }
                        {
                            renderNewsCard()
                        }
                        </div>
                    
                    <div style={{ height: 16 }}>
                        <p style={{ color: 'white' }}></p>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Notification;