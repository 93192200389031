import {useEffect, useRef, useState} from 'react';

const OnGoBackBtnClick = (navigate) => {
    const isMainPage = ["/hoe/ch", "/hoe/en"].includes(window.location.pathname);

    if (isMainPage && window.globalConfig.isLocal) return;

    if (isMainPage) {
        window.MBSSClient && 
        window.MBSSClient.onBackPressed &&
        window.MBSSClient.onBackPressed();
        return ;
    } 

    // other page, normally -1
    navigate(-1);
}
export const imageShare=(title,url)=>{

    window.MBSSClient && 
    window.MBSSClient.imageShare &&
    window.MBSSClient.imageShare(title,url);
    return ;
}
export const DownloadForm=(url)=>{
    window.MBSSClient && 
    window.MBSSClient.downloadFormURL &&
    window.MBSSClient.downloadFormURL(url);
    return ;
}

export const DownFileOrOpenURL = (key) => {
  const {
      SMARTVISION_kEY,
      SMARTVISION_URL,
  } = window.globalConfig;
  
  let link = ''
  switch (key) {
      case SMARTVISION_kEY:
        link = SMARTVISION_URL;
          break;
      default:
        link = key;
          break;
  }
 
  DownloadForm(link)
}

const GetStringFromURL = (value) => {
    const aUrl = window.location.search.toLowerCase();
    const reg = new RegExp("(^|&)" + value + "=([^&]*)(&|$)");
    const res = aUrl.substr(1).match(reg);
    if (res && res[2]) {
        return res[2];
    }
    return null;
}

export const shareToMobile=(title,url)=>{   
    window.MBSSClient && 
    window.MBSSClient.share &&
    window.MBSSClient.share(title,url);
    return ;
}
export const useScroll = () =>{
  
  const [scrollPosition, setScrollPosition] = useState(0);  
  
  useEffect(() => {  
    const handleScroll = () => {  
      setScrollPosition(window.scrollY);  
    };  
  
    window.addEventListener('scroll', handleScroll);  
    return () => {  
      window.removeEventListener('scroll', handleScroll);  
    };  
  }, []);  
  return [scrollPosition, setScrollPosition];  
}
export const racecardToMobile=(meetingId,raceId)=>{   
    const lang = window.globalConfig.isLangUS ? 'en' : 'ch';
    const resUrl = window.globalConfig.RaceCard3in1URL.replace('{{lang}}', lang).replace('{{meetingId}}', meetingId).replace('{{raceId}}', raceId)
    window.MBSSClient && 
    window.MBSSClient.downloadFormURL &&
    window.MBSSClient.downloadFormURL(resUrl);
}

function useEventListener(eventName, handler, element = window) {
    const saveHandler = useRef();

    useEffect(() => {
        saveHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            // create event to ref
            const eventListener = event => saveHandler.current(event);

            element.addEventListener(eventName, eventListener);

            // clear
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // eventName or element change�Areload
    );
};

function scrollIfContain(dom, scrollDom, type) {
    const screenHeight = scrollDom.clientHeight;
    
    const scrollTop = scrollDom.scrollTop;
    const offsetTop = dom?.offsetTop || 0;
    const domsclientHeight = dom?.clientHeight || 0;
    // console.log('[scrollTop-----]', scrollTop)
    // console.log('[offsetTop-----]', offsetTop)
    // console.log('[clientHeight-----]', domsclientHeight)

    if (type === 'down') {
        return offsetTop - scrollTop <= screenHeight
    }
    if (type === 'up') {
        return scrollTop - offsetTop >= domsclientHeight
    }

    return 0;
  }

function isContain(dom) {
  const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  
  const scrollTop = document.documentElement.scrollTop;
  const offsetTop = dom?.offsetTop || 0;

  return offsetTop - scrollTop <= screenHeight;
}

function changePrice2money(number, decimals, dec_point, thousands_sep) {
    /*
    * 参数说明：
    * number：要格式化的数字
    * decimals：保留几位小数
    * dec_point：小数点符号
    * thousands_sep：千分位符号
    * */
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return '' + Math.floor(n * k) / k;
    };
    s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
    var re = /(-?\d+)(\d{3})/;
    console.log(s)
    while (re.test(s[0])) {
    s[0] = s[0].replace(re, "$1" + sep + "$2");
    }
    if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

function formatNum(num) {
    var value = Math.round(parseFloat(num) * 100) / 100;
    var arrayNum = value.toString().split(".");
    if (arrayNum.length == 1) {
        return value.toString() + ".00";
    }
    if (arrayNum.length > 1) {
        if (arrayNum[1].length < 2) {
            return value.toString() + "0";
        }
        return value;
    }
}

function getOwnerType(id, clientData) {
    const tempObj = clientData?.find(i => i.brandNumber === id)
    return tempObj?.ownerType
}

function initDataFromMobile(setclientData,setCuno) {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("data")
    const cuno = urlParams.get("cuno")
    console.log("url", data);
    console.log("urcunol", cuno);
    if (data &&  data !== "null" && data !== "[]") {
        var json = JSON.parse(data)
        const modifiedData = json?.map(obj => {
            const { brandNo, ...rest } = obj;
            return { brandNumber: brandNo, ...rest };
        }) || [];

        setclientData(modifiedData) 
        setCuno(cuno);
        window.cuno = cuno;
    }
}


export function mapVenueName(code,lang){
    switch (code){
        case 'HV':
            return lang=='ch'?"跑馬地":"Happy Valley "
        case 'ST':
            return lang=='ch'?"沙田":"Sha Tin ";
        case 'CH':
            return lang=='ch'?"從化":"Conghua ";
        default:
            return "--"
    }
}
export function mapMeetingType(code,lang){
    switch (code){
        case 'N':
            return lang=='ch'?"夜間賽事":"Night Race"
        case 'T':
            return lang=='ch'?"黃昏賽事":"Twilight Race";
        case 'D':
            return lang=='ch'?"日間賽事":"Day Race";
        default:
            return "--"
    }
}
function getVideoInfo(obj) {
    const isUS = window.globalConfig.isLangUS;
    const tempObj = {
        link: '',
    }

    if (obj?.videoReplayURL1200mp4) {
        tempObj.link = isUS ? obj?.videoReplayURL1200mp4?.english: obj?.videoReplayURL1200mp4?.chinese;
    }

    return tempObj
}

function openDeviceRotation(isOpen) {
    window.MBSSClient && 
    window.MBSSClient.openDeviceRotation &&
    window.MBSSClient.openDeviceRotation(isOpen);
    return ;
}

function errorToMobile(code, title = 'GraphQLError', des = ''){
    const url = window.globalConfig.GRAPHQL_URL;
    window.MBSSClient && 
    window.MBSSClient.errorAlert &&
    window.MBSSClient.errorAlert(code,title,des,url);
    return ;
}

function isIOS() {
    return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
            ['iPhone', 'iPad', 'iPod'].includes(navigator.platform) ||
            navigator.vendor.includes('Apple')
    ;
}

const validVenueCode = ['ST', 'HV'];

const isValidVenueCode = (code) => validVenueCode.includes(code);

export * from './matchTWContent';
export * from './matchWMContent';
export * from './matchMCLContent';
export {
    OnGoBackBtnClick,
    GetStringFromURL,
    useEventListener,
    isContain,
    scrollIfContain,
    changePrice2money,
    initDataFromMobile,
    getOwnerType,
    formatNum,
    getVideoInfo,
    openDeviceRotation,
    errorToMobile,
    isIOS,
    isValidVenueCode
}