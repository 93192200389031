import React, { useState, useEffect } from 'react';
import navigate_top from '../../img/Trackwork/navigate_top.svg';
import navigate_down from '../../img/Trackwork/navigate_down.svg';
import './TrackDrawer.css';
import TrackCard from './TrackCard';
import sign from '../../img/Trackwork/sign.svg';
import ExplainDrawer from './ExplainDrawer';
import { useTranslation } from "react-i18next";
import Divider from '@mui/material/Divider';

const TrackDrawer = (props) => {
    const { dateContent, currentDate } = props; 
    const [expend, setExpend] = useState(false);
    const [fl, setFl] = useState('');
    const [open, setOpen] = React.useState(false);
    const { t, i18n } = useTranslation();
    const isCh = i18n.language === 'ch';
    const onExpendClick = () => {
      
      setExpend(!expend);
  
      if(!expend) {
        setTimeout(() => setFl(`${document.getElementsByClassName('track-drawer')[0].clientHeight- 16}px`))
      }else {
        setFl('')
      }
    }

    useEffect(() => {
      // date change reset
      setExpend(false);
      setFl('')
    }, [currentDate])

    return <React.Fragment>
      <div 
        id="TW_drawer_switch"
        className={`drawer-control`}
        style={{
          bottom: fl, 
        }}
        onClick={onExpendClick}> 
          <img src={expend ? navigate_down : navigate_top} />
        </div>
      <div className={`track-drawer ${expend ?' track-drawer-isExpend': ''}`}>
          <div className="drawer-workout PlayFair">
              <div>{t('Lbl_TrackWork_DrawerTitle')}</div>
             
              <div id="TW_IndexIcon"><img src={sign} onClick={() => setOpen(true)}/></div>
          </div>
          <Divider variant="middle" style={{ background: '#CED3D9', opacity: '0.3' }} />
          <TrackCard dateContent={dateContent} currentDate={currentDate}/>
      </div>  

      <ExplainDrawer open={open} setOpen={setOpen} showExtra={true}/>
    </React.Fragment>
}



export default TrackDrawer;