import React, { useState } from 'react';
import { Drawer } from '@mui/material';
import { useTranslation } from "react-i18next";
import close from '../img/Trackwork/close.svg';
import './Css/ExplainPlaDrawer.css';

const ExplainDrawer = ({ open, setOpen }) => {
    const { t, i18n } = useTranslation();
    const gear = t("Lbl_Gear_Title");
    const rt = t("Lbl_RT_Title");
    const rider = t("Lbl_Rider_Title");

    console.log('[gear]-----', i18n.language)
    let dataMap = {
        "DISQ": t('Lbl_FormRecord_DISQ'),
        "DNF": t('Lbl_FormRecord_DNF'),
        "FE": t('Lbl_FormRecord_FE'),
        "ML": t('Lbl_FormRecord_ML'),
        "PU": t('Lbl_FormRecord_PU'),
        "TNP": t('Lbl_FormRecord_TNP'),
        "TO": t('Lbl_FormRecord_TO'),
        "UR": t('Lbl_FormRecord_UR'),
        "VOID": t('Lbl_FormRecord_VOID'),
        "WR": t('Lbl_FormRecord_WR'),
        "WV": t('Lbl_FormRecord_WV'),
        "WV-A": t('Lbl_FormRecord_WVA'),
        "WX": t('Lbl_FormRecord_WX'),
        "WX-A": t('Lbl_FormRecord_WXA'),
        "WXNR": t('Lbl_FormRecord_WXNR'),
    }



    const IndexList = () => {

        const listItem = [];

        for (const [k, v] of Object.entries(dataMap)) {
            listItem.push(
                <div id={'PlaItem' + listItem.length} className="indexItem-content-pla">
                    <div className="indexItem-content-pla-left"><span>{k}</span></div>
                    <div className="indexItem-content-pla-right">{v}</div>
                </div>
            )
        }
        console.log('listItem---', listItem)
        return listItem.map(i => i)
    }
    return <Drawer
        className='indexDrawerPla'
        anchor={"bottom"}
        open={open}
        onClose={() => setOpen(false)}
    >
        <div className='indexExplain-drawer'>
            <div className='indexExplain-header PlayFair'>
                <span id='PlaTitle'>{t('Lbl_FormRecord_SpecialIndex')}</span>
                <img id='PlaClose' src={close} onClick={() => setOpen(false)} />
            </div>
            <IndexList />
        </div>

    </Drawer>
}

export default ExplainDrawer;