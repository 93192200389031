import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import 'dayjs/locale/zh-hk';
import dayjs from 'dayjs';
import minMax from "dayjs/plugin/minMax";

dayjs.extend(minMax)
 // load translation using http -> see /public/assets (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: window.globalConfig.isLangUS ? 'en' : 'ch',
        fallbackLng:'en',
        debug:true,
        ns: ["translations"],
        defaultNS: "translations",
        backend: {
            /* translation file path */
            loadPath: './assets/i18n/translations/{{lng}}.json'
        },
        keySeparator: false, // we use content as keys

        interpolation: {
        escapeValue: false
        }
    });
    export default i18n;