import React, { useEffect, useState, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material/'
import replayIcon from '../img/recordTableReplay.svg'
import replayAIcon from '../img/recordTableA.svg'
import formTablePlaInfo from '../img/formTablePlaInfo.svg'
import SeasonTabs from './SeasonTabs'
import "./Css/FormTable.css"
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';

import PlayVideoIcon from '../Component/PlayVideoIcon';
import { getVideoInfo, isIOS } from '../utils'

var startX = 0
var startY = 0
var scrolling = false
var scrollingX = false;
var scrollingY = false;

const SeasonalTable = (props) => {
    const { disclaimerFooter } = props;
    const navigate = useNavigate()
    const [horseName, setHorseName] = useState('')
    const { lang, id, ownerType } = useParams();
    const { t, i18n } = useTranslation();
    const [leftVal, setLeftVal] = useState(0)
    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState([])
    const [open, setOpen] = useState(false)
    const [videoUrl, setVideoUrl] = useState('');
    const CustomTableCell = (props) => {
        return (<TableCell id='FR_form_blank' onClick={() => props.handleToRaceDetail?.(props.data)} style={props.customStyle} className={`season-cell no-border-right ${props.customClass}`}>{props.children}</TableCell>)
    }
    const CustomLabelCell = (props) => {
        return (<TableCell id='FR_form_label' onClick={() => props.onHandleClick?.()} style={props.customStyle} width={100} className='Label2-Medium  label-cell'>{props.children}</TableCell>)
    }
    const CustomValCell = (props) => {
        return (<TableCell id='FR_form_cell' className='Label1-Medium content-cell'>{props.children}</TableCell>)
    }
    const CustomEmptyCell = (props) => {
        return (<TableCell id='FR_form_emptyCell' className={`season-empty-cell`}  colSpan={3}>{props.children}</TableCell>)
    }
    const getRunningPos = (infoData) => {
        let result = '--';
        if (infoData.length) {
            let posArr = []
            posArr = infoData?.map((item) => {
                if (item.ruPos === -1 || item.ruPos === 0) {
                    return ''
                } else {
                    return item.ruPos
                }
            })
            result = posArr.join(' ')
        }
        if (result.trim()) {
            return result;
        } else {
            return '--'
        }
    }
    const jockeyTrainerName = (data, type) => {
        let result = '';
        if (!data[type]?.['code']) return '--'
        if (lang === 'en') {
            result = data[type]?.['name_en']
        } else {
            result = data[type]?.['name_ch']
        }
        if (result?.trim?.() === '') {
            result = data[type]?.['name_en']
        }
        return result || '--';
    }
    const getVenue = (item) => {
        let result = ''
        if (!item.overseas) {
            result = item.venue?.code ? lang === 'en' ? item.venue?.code : item.venue?.chinese : '--';
        } else {
            result = item.racecourse?.code ? lang === 'en' ? item.racecourse?.code : item.racecourse?.chinese : '--';
        }
        return result;

    }
    const getRenderData = () => {
        const originData = props.tableData?.horseFormRecord;
        let newData = originData?.reduce((acc, item) => {
            let newData = { season: item.season, data: [] };
            if (!acc[newData.season]) {
                acc[newData.season] = [];
            }
            acc[newData.season].push(item);
            return acc;
        }, {});
        let renderData = []
        props.tableData?.involvedSeasons?.map((sea) => {
            if (Object.keys(newData).includes(sea)) {
                let seaData = []
                seaData = newData[sea]?.map((item) => {
                    return {
                        raceNo: item.raceNo,
                        raceName: lang === 'en' ? item.raceName?.english : item.raceName?.chinese,
                        track: lang === 'en' ? item.track?.english : item.track?.chinese,
                        distance: lang === 'en' ? item.distance + 'M' : item.distance + '米',
                        course: item.course?.code ? lang === 'en' ? item.course?.english : item.course?.chinese : '--',
                        class: item.overseas ? item.className?.code ? item.className?.code : '--' : lang === 'en' ? item.className?.english : item.className?.chinese,
                        venue: getVenue(item),
                        raceIndex: item.raceIndex === -1 && item.overseas ? lang === 'en' ? 'Overseas' :"海外": item.raceIndex,
                        date: item.date,
                        Pla: Number(item.placeNo) > 0 ? Number(item.placeNo) : item.resultIndex?.trim() == "" ? "--" : item.resultIndex?.trim(),
                        G: item.going?.code ? lang === 'en' ? item.going?.english : item.going?.chinese : '--',
                        Dr: item.runnerStatus === 'Ran' ? item.barrierDrNo > 0 ? item.barrierDrNo : '--' : '--',
                        Rtg: Number(item.rating) > 0 ? item.rating : '--',
                        Trainer: jockeyTrainerName(item, 'trainer'),
                        Jockey: jockeyTrainerName(item, 'jockey'),
                        // DH: 平头马
                        LBW: item.runnerStatus === 'Ran' ?
                                item.marginOrLBW.code.trim()=="DH" ?   
                                            lang==='en' ? item.marginOrLBW.english : item.marginOrLBW.chinese 
                                                                    : 
                                item.marginOrLBW.code.trim() ? 
                                            lang === 'en' ? item.marginOrLBW?.code : item.marginOrLBW?.chinese.trim() ? item.marginOrLBW?.chinese : item.marginOrLBW.code 
                                : '--' 
                        : '--',
                        WinOdds: item.runnerStatus === 'Ran' ? item.winOdds ? item.winOdds : '--' : '--',
                        ActWt: item.actualWeight > 0 ? item.actualWeight : '--',
                        FinishTime: item.runnerStatus === 'Ran' ? item.finalTimeOfRace ? item.finalTimeOfRace.trim()==''?'--':item.finalTimeOfRace : '--' : '--',
                        DeclarHorseWt: item.runnerStatus === 'Ran' ? item.horseWeight > 0 ? item.horseWeight : '--' : '--',
                        Gear: item.runnerStatus === 'Ran' ? item.gear ? item.gear : '--' : '--',
                        sectionInfos: item.runnerStatus === 'Ran' ? getRunningPos(item.sectionInfos) : '--',
                        videoLink: item.runnerStatus === 'Ran' ? getVideoInfo(item).link : '--',
                    }
                })
                renderData.push({ season: sea, data: seaData })
            } else {
                renderData.push({ season: sea, data: null })
            }
        })
        const horseName = lang === 'en' ? props.tableData?.name_en : props.tableData?.name_ch
        setHorseName(horseName)
        setTotalData(renderData)
        setData(renderData)
        console.log('renderData----', renderData)
    }
    const scrollRef = useRef(null);
    useEffect(() => {
        const seasonCellDom = document.getElementsByClassName("season-cell")[3]
        setLeftVal(seasonCellDom?.clientWidth)
        getRenderData()

        const touchmovefunction = (sRef, dx, dy, e) => {
            if (Math.abs(dx) > Math.abs(dy)) {
                // X 轴滚动
                console.log('X 轴滚动')
                if(!scrolling) {
                    scrollingX = true;
                    scrollingY = false;
                }
            
            } else {
                // Y 轴滚动
                if(!scrolling) {
                    scrollingX = false;
                    scrollingY = true;
                }
                console.log('Y 轴滚动')
            }

            if (scrollingX) {
                if (dx < 0 && sRef && sRef.current && typeof sRef.current.scrollLeft=== 'number') sRef.current.scrollLeft = sRef.current?.scrollLeft + Math.abs(dx)
                if (dx > 0 && sRef && sRef.current && typeof sRef.current.scrollLeft=== 'number') sRef.current.scrollLeft = sRef.current?.scrollLeft - dx
            }

            if (scrollingY) {
                if (dy < 0 && sRef && sRef.current && typeof sRef.current.scrollTop=== 'number') sRef.current.scrollTop = sRef.current.scrollTop + Math.abs(dy)
                if (dy > 0 && sRef && sRef.current && typeof sRef.current.scrollTop=== 'number') sRef.current.scrollTop = sRef.current.scrollTop - dy
            }

            startX = e.touches[0].clientX
            startY = e.touches[0].clientY
        }

        const handleTouchMove = (event) => {
            event.preventDefault();
            const deltaX = event.touches[0].clientX - startX;
            const deltaY = event.touches[0].clientY - startY;
            
            if (scrolling) {
                touchmovefunction(scrollRef, deltaX, deltaY, event);
               
                return;
            }; 

            touchmovefunction(scrollRef, deltaX, deltaY, event)
            scrolling = true
        }
        const handleTouchStart = (event) => {
            startX = event.touches[0].clientX;
            startY = event.touches[0].clientY;
        }
        const handleTouchEnd = (event) => {
            scrolling = false
        }
        if (isIOS()) {
            scrollRef.current && scrollRef.current.addEventListener && scrollRef.current.addEventListener('touchstart', handleTouchStart);
            scrollRef.current && scrollRef.current.addEventListener && scrollRef.current.addEventListener('touchmove', handleTouchMove, { passive: false });
            scrollRef.current && scrollRef.current.addEventListener && scrollRef.current.addEventListener('touchend', handleTouchEnd);
        }

        return () => {  
            if (isIOS()) {
                scrollRef.current && scrollRef.current.addEventListener && scrollRef.current.removeEventListener('touchstart', handleTouchStart); 
                scrollRef.current && scrollRef.current.addEventListener && scrollRef.current.removeEventListener('touchmove', handleTouchMove, { passive: false }); 
                scrollRef.current && scrollRef.current.addEventListener && scrollRef.current.removeEventListener('touchend', handleTouchEnd); 
            }
        }; 
    }, [])
    const onClickGear = () => {
        props.onClickGear()
    }
    const onClickPla = () => {
        props.onClickPla()
    }
    const renderEmptyCell = (obj) => {
        if (!obj.data) {
            return (
                <TableHead stickyHeader>
                    <TableRow style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)"}}>
                        <CustomEmptyCell>{t('Lbl_FormRecord_NoRunning')}</CustomEmptyCell>
                        <TableCell colSpan={10} style={{ backgroundColor: 'white' }} ></TableCell>
                    </TableRow>
                </TableHead>)
        }
    }
    const handleToRaceDetail = (dataItem) => {
        navigate(`/${i18n.language}/RaceDetail/${id}/${ownerType}?cuno=${window.cuno}`, { state: { date: dataItem.date, raceNo: dataItem.raceNo, isHistory: true } })
    }
    return (
        <>  
            <TableContainer id='FR_detail_tableContainer' className='table-container' component={Paper} ref={scrollRef}>
                <Table>
                    <TableHead id='FR_form_labelHead'>
                        <TableRow id='FR_form_labelRow'>
                            <CustomTableCell customClass={"label-season-cell"} customStyle={{ backgroundColor: "#6C7280"}}>{ }</CustomTableCell>
                            <CustomLabelCell customStyle={{ minWidth: "74px" }} onHandleClick={onClickPla}> {t('Lbl_FormRecord_Pla')} <img id='FR_detail_PlaInfo' style={{ 'vertical-align': 'middle' }} alt='' src={formTablePlaInfo} /></CustomLabelCell>
                            <CustomLabelCell>{t('Lbl_FormRecord_G')}</CustomLabelCell>
                            <CustomLabelCell>{t('Lbl_FormRecord_Dr')}</CustomLabelCell>
                            <CustomLabelCell>{t('Lbl_FormRecord_Rtg')}</CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "74px" }}>{t('Lbl_FormRecord_Trainer')}</CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "74px" }}>{t('Lbl_FormRecord_Jockey')}</CustomLabelCell>
                            <CustomLabelCell>{t('Lbl_FormRecord_LBW')}</CustomLabelCell>
                            {/* <CustomLabelCell>{t('Lbl_FormRecord_WinOdds')}</CustomLabelCell> */}
                            <CustomLabelCell customStyle={{ minWidth: "90px" }}>{t('Lbl_FormRecord_ActWt')}</CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "74px" }}>{t('Lbl_FormRecord_RunningPosition')}</CustomLabelCell>
                            <CustomLabelCell>{t('Lbl_FormRecord_FinishTime')}</CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "90px" }}>{t('Lbl_FormRecord_DHW')}</CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "60px" }} onHandleClick={onClickGear} >{t('Lbl_FormRecord_Gear')} <img id='FR_detail_GearInfo' style={{ 'vertical-align': 'middle' }} alt='' src={formTablePlaInfo} /></CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "100px" }}>{t('Lbl_FormRecord_VideoReplay')}</CustomLabelCell>
                        </TableRow>
                    </TableHead>
                    {data?.map((seasonObj, index) => (
                        <React.Fragment key={index}>
                            <TableHead id={'FR_form_titleHead' + index} stickyHeader>
                                <TableRow id={'FR_form_titleRow' + index} className='seson-title-row'>
                                    <CustomTableCell customStyle={{ backgroundColor: "#EDE7E1", padding: "4px 8px", color: "#0A2240", fontSize: 14, fontWeight: 700 }} >{`${seasonObj.season} ${t('Lbl_FormRecord_Season')}`}</CustomTableCell>
                                    {new Array(13).fill().map((_, index) => <TableCell key={index} className='season-title-cell' ></TableCell>)}
                                </TableRow>
                            </TableHead>
                            {seasonObj.data ?
                                (<TableBody>
                                    {
                                        seasonObj.data?.map((dataItem, dataIndex) => (
                                            <TableRow id={'FR_form_bodyRow' + dataIndex} key={dataIndex} style={{ borderBottom: "1px solid #CED3D9"}}>
                                            <CustomTableCell data={dataItem} handleToRaceDetail={(dataItem.raceIndex!='Overseas'&&dataItem.raceIndex!='海外')?handleToRaceDetail:null} index={dataIndex} customClass={"season-body-cell"}>
                                                    <span className='Label2-Medium'>{`${moment(dataItem.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}(${dataItem.raceIndex})`}</span>
                                                    <p className='Label1-Bold' >{`${dataItem.venue}, ${dataItem.track}, ${dataItem.course=='--'?'':dataItem.course+", "}${dataItem.class}, ${dataItem.distance}`}</p>
                                                </CustomTableCell>
                                                {/* <TableCell style={{ left: leftVal }} className='cell-shadow-label cell-shadow '></TableCell> */}
                                                <CustomValCell index={dataIndex}>{dataItem.Pla}</CustomValCell>
                                                <CustomValCell index={dataIndex}>{dataItem.G}</CustomValCell>
                                                <CustomValCell index={dataIndex}>{dataItem.Dr}</CustomValCell>
                                                <CustomValCell index={dataIndex}>{dataItem.Rtg}</CustomValCell>
                                                <CustomValCell index={dataIndex}>{dataItem.Trainer}</CustomValCell>
                                                <CustomValCell index={dataIndex}>{dataItem.Jockey}</CustomValCell>
                                                <CustomValCell index={dataIndex}>{dataItem.LBW}</CustomValCell>
                                                {/* <CustomValCell index={dataIndex}>{dataItem.WinOdds}</CustomValCell> */}
                                                <CustomValCell index={dataIndex}>{dataItem.ActWt}</CustomValCell>
                                                <CustomValCell index={dataIndex}>{dataItem.sectionInfos}</CustomValCell>
                                                <CustomValCell index={dataIndex}>{dataItem.FinishTime}</CustomValCell>
                                                <CustomValCell index={dataIndex}>{dataItem.DeclarHorseWt}</CustomValCell>
                                                <CustomValCell index={dataIndex}>{dataItem.Gear}</CustomValCell>
                                                <CustomValCell index={dataIndex}>
                                                    <div className='video-wapper'>
                                                        {dataItem.videoLink&&dataItem.videoLink!='--' ? <PlayVideoIcon
                                                            id='FR_detail_replayIcon'
                                                            iconSize={23}
                                                            videoLink={dataItem.videoLink} /> : '--'}
                                                        {/* <img id='FR_detail_replayAIcon' alt='' src={replayAIcon} /> */}
                                                    </div>
                                                </CustomValCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>) : renderEmptyCell(seasonObj)}
                        </React.Fragment>
                    ))}
                </Table>
                {disclaimerFooter()}
            </TableContainer >
        </>
    );
};

export default SeasonalTable;