import '../Css/MenuTabs.css'
import { useEffect, useMemo, useState, useRef } from "react";
import { Tabs, Tab, Grid } from '@mui/material';
import { useTranslation, Trans } from "react-i18next";
import { useParams } from 'react-router-dom';
import Back from '../../img/Back.svg'
import HorsePlaceholder from '../../img/ProfileHorsePlaceholder.svg'
import { useScroll } from '../../utils/index'
import { identity } from 'lodash';
import { useNavigate } from "react-router-dom";
import { OnGoBackBtnClick } from "../../utils";


function MenuTabs({ tabContentId, handleClickMenuTabs, horsePropData }) {
    const navigate = useNavigate();
    const [scrollPosition, _] = useScroll()
    const { t, i18n } = useTranslation();
    const { id, lang } = useParams();
    const [value, setValue] = useState(0)
    const [isSelect, setIsSelect] = useState(false);
    const [currentSelectVal, setCurrentSelectVal] = useState(0)
    const [tabDisable, setTabDisable] = useState(true)
    const { horseData = {} } = horsePropData
    const [avatar, setAvatar] = useState()
    const menuItemDom = tabContentId?.map((item) => document.getElementById(item))
    const [myOffset, setMyOffset] = useState(0)
    const [isLongTitle, setIsLongTitle] = useState(false)
    const titleRef = useRef(null)
    const onTabsChange = (_, val) => {
        setIsSelect(true)
        if (tabDisable) return
        menuItemDom[val].scrollIntoView()
        if (menuItemDom[val].getBoundingClientRect().top <= 110) {
            window.scrollTo(0, window.pageYOffset - 110)
        }
        setValue(val)
        setCurrentSelectVal(val)
    }
    useEffect(() => {
        const menuAvatar = document.getElementById('menuAvatar');
        setAvatar(`${window.globalConfig.SITE_DOMAIN}${window.globalConfig.HORSE_IMAGE_PATH}${id}_l.jpg`)
        menuAvatar?.addEventListener("error", () => {
            setAvatar(HorsePlaceholder)
        })
    }, [])
    useEffect(() => {
        const floatingDiv = document.getElementById('floatingDiv');
        const offsetVal = window.pageYOffset
        if (offsetVal <= 200) {
            floatingDiv.style['transform'] = 'translateY(-100px)';
            floatingDiv.style['visibility'] = 'unset';
            setValue(0)
            setTabDisable(true)
        }
        else {

            floatingDiv.style['transform'] = 'none';
            floatingDiv.style['transition'] = 'transform 250ms cubic-bezier(0, 0, 0.2, 1) 0ms';
            setTabDisable(false)
        }
        setMyOffset(offsetVal)
    }, [scrollPosition])
    useEffect(() => {
        if (getHorseName().length >= 15) {
            setIsLongTitle(true)
        }
    }, [tabDisable])
    const autoChangeValue = () => {
        menuItemDom?.map((item, index) => {
            const rect = item?.getBoundingClientRect()
            if (rect?.top > 100 && rect?.top < 120) {
                if (isSelect) {
                    currentSelectVal === Number(index) ? setValue(Number(index)) : setValue(currentSelectVal)
                    setIsSelect(false)
                } else {
                    setValue(Number(index))
                }
            }
            return "";
        })
    }
    const offsetMemo = useMemo(() => {
        autoChangeValue()
        return ""
    }, [myOffset])
    const getHorseName = () => {
        let name = ""
        if (Object.keys(horseData || {})?.length && horseData?.horse) {
            name = lang === 'ch' ? horseData?.horse[0]?.name_ch === "" ? "未命名" : horseData?.horse[0]?.name_ch : horseData?.horse[0]?.name_en === horseData?.horse[0]?.brandNumber ? "UNNAMED" : horseData?.horse[0]?.name_en
        } else {
            if (lang === "ch") {
                name = "未命名"
            }
            if (lang === "en") {
                name = "UNNAMED"
            }
        }
        return name;
    }
    const getMenuName = (id) => {
        let name = ''
        switch (id) {
            case "ProfileTitle":
                name = t('Lbl_Profile')
                break;
            case "WinningMomentsTitle":
                name = t('Lbl_WinningMoments')
                break;
            case "FormRecordTitle":
                name = t('Lbl_FormRecord')
                break;
            case "TrackworkRecordsTitle":
                name = t('Lbl_TrackWork')
                break;
            case "MovementRecordTitle":
                name = t('Lbl_MovementRecord')
                break;
            case "VeterinaryRecordTitle":
                name = t('Lbl_VeterinaryRecord')
                break;
            default:
                break;
        }
        return name;
    }
    return (
        <div id='floatingDiv' className="menu-tabs-wrapper">
            <div className='menu-title-wrapper'>
                <div onClick={() => OnGoBackBtnClick(navigate)} className='title-back Label2-Bold'>
                    <img src={Back} alt='' />
                    {/* {t('Lbl_Back')} */}
                </div>
                <div className='title-style'>
                    <img id="menuAvatar" className='title-horse-avatar' alt='' src={avatar} />
                    <p ref={titleRef} className={`title-horse-name PlayFair Headline/H2-Bold' ${isLongTitle && 'longerTitle'}`}>{getHorseName()}</p>
                </div>
            </div>
            {/* <div className='menu-title-wrapper'>
                <div>
                    <img src={Back} alt='' />
                    <p className='title-back'>{t('Lbl_Back')}</p>
                </div>
                <div>
                    <img className='title-horse-avatar' alt='' />
                    <p className='title-horse-name PlayFair'>GOLDEN SIXTY</p>
                </div>
            </div> */}
            <Tabs
                id='menuTab'
                variant="scrollable"
                scrollButtons={false}
                value={value}
                onChange={onTabsChange}>
                {tabContentId?.map((itemId) => (<Tab id={itemId + '_tab'} key={itemId + '_tab'} className='menu-tab' label={getMenuName(itemId)} />))}
            </Tabs>
        </div>
    )
}

export default MenuTabs;