import './css/RaceDetailMenu.css'
import { useEffect, useMemo, useState } from "react";
import { Tabs, Tab, Grid } from '@mui/material';
import { useTranslation, Trans } from "react-i18next";
import { useParams } from 'react-router-dom';
import HorsePlaceholder from '../../img/ProfileHorsePlaceholder.svg'
import { useScroll } from '../../utils/index'
import Header from '../../Component/Header';
function RaceDetailMenu({ tabContentId }) {
    const [scrollPosition, _] = useScroll()
    const { t, i18n } = useTranslation();
    const { id, lang } = useParams();
    const [value, setValue] = useState(0)
    const [isSelect, setIsSelect] = useState(false);
    const [currentSelectVal, setCurrentSelectVal] = useState(0)
    const [avatar, setAvatar] = useState()
    const [menuItemDom, setMenuItemDom] = useState([])
    const [myOffset, setMyOffset] = useState(0)
    const onTabsChange = (_, val) => {
        setIsSelect(true)
        if (Number(val) === 0) {
            window.scrollTo(0, 0)
        } else {
            menuItemDom[val]?.scrollIntoView()
            if (menuItemDom[val]?.getBoundingClientRect().top <= 110) {
                window.scrollTo(0, window.pageYOffset - 110)
            }
        }
        setValue(val)
        setCurrentSelectVal(val)
    }
    useEffect(() => {
        const arr = tabContentId?.map((item) => document.getElementById(item))
        setMenuItemDom(arr)
    }, [tabContentId])
    useEffect(() => {
        const menuAvatar = document.getElementById('menuAvatar');
        setAvatar(`${window.globalConfig.SITE_DOMAIN}${window.globalConfig.HORSE_IMAGE_PATH}${id}_l.jpg`)
        menuAvatar?.addEventListener("error", () => {
            setAvatar(HorsePlaceholder)
        })
        // var st =document.querySelector('meta[name="description"]')?.content;
        // document.querySelector('meta[name="app-webview-title"]')?.setAttribute("content",t('Lbl_RD_Title'))
     
    }, [])
    useEffect(() => {
        const offsetVal = window.pageYOffset
        const tabElement = document.getElementById('menuTab')
        if (offsetVal <= 60) {
            tabElement.style.display = "none"
        } else {
            tabElement.style.display = "flex"
        }
        setMyOffset(offsetVal)
    }, [scrollPosition])
    const autoChangeValue = () => {
        menuItemDom?.map((item, index) => {
            const rect = item?.getBoundingClientRect()
            console.log(rect?.top)
            if (rect?.top > 100 && rect?.top < 120) {
                if (isSelect) {
                    currentSelectVal === Number(index) ? setValue(Number(index)) : setValue(currentSelectVal)
                    setIsSelect(false)
                } else {
                    setValue(Number(index))
                }
            }
            return "";
        })
    }
    const offsetMemo = useMemo(() => {
        autoChangeValue()
        return ""
    }, [myOffset])
    const getMenuName = (id) => {
        let name = ''
        switch (id) {
            case "RD_FR_container":
                name = t('Lbl_RaceDetails')
                break;
            case "PostRaceAnalysisTitle":
                name = t('Lbl_MacL_Analysis')
                break;
            case "SectionalTimeTitle":
                name = t('Lbl_SectionalTime')
                break;
            case "RD_RIR_Tilte":
                name = t('Lbl_RacingIncidentReport')
                break;
            case "FullResultsTitle":
                name = t('Lbl_FullResults')
                break;
            case "CommentsOnRunning":
                name = t('Lbl_CommentsOnRunning')
            default:
                break;
        }
        return name;
    }
    return (
        <div id='floatingDiv' className="detail-menu-tabs-wrapper">
            {/* <Helmet>
                <title>{t('Lbl_RD_Title')}</title>
                <meta name="app-webview-title" content={t('Lbl_RD_Title')}/>
                
            </Helmet> */}
            <Header title={t('Lbl_RD_Title')}/> 
            <Tabs
                id='menuTab'
                variant="scrollable"
                scrollButtons={false}
                value={value}
                onChange={onTabsChange}>
                {tabContentId?.map((itemId) => (<Tab id={itemId + '_tab'} key={itemId + '_tab'} label={getMenuName(itemId)} />))}
            </Tabs>
        </div>
    )
}

export default RaceDetailMenu;