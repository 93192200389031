
import React, { useEffect, useState, useRef }  from 'react';
import styles from '../Css/WinningMoments.module.css'
import { Divider, Grid, Paper,Typography, Box } from '@mui/material';
import { useTranslation } from "react-i18next";

import winnerBadge from '../../img/WinningMoment/winnerBadge.svg';
import location from '../../img/WinningMoment/location.svg';
import date from '../../img/WinningMoment/date.svg';

import PlayVideoIcon from '../../Component/PlayVideoIcon';

function WMCard({boomState, isInfoPage = false, dataSource, isBoomReady}){
    const { t, i18n } = useTranslation();

    const isEn = i18n.language === 'en';
    const isSmallWithScreen = window.innerWidth < 466;

    const isEnSmallWidthScreen = isEn && isSmallWithScreen;

    const [calcLeft, setCalcLeft] = useState(0);
    useEffect(() => {
        if (!isInfoPage) {
            // fix HOEA-685 for ios
            if (window.innerWidth > 480) {
                setCalcLeft("calc(50% - 256px)")
            }
        }
    }, [isBoomReady]);
    return(
        <React.Fragment>
            <Grid container px={2} style={{ position: 'relative'}}>
                {!isInfoPage && <div id="bm" className={styles.boomsfire} style= {{display: boomState, left: calcLeft}}></div>}
                            
                {
                    dataSource.map((i, idx) => {
                        // console.log('【-----i.hasVideo】', i.hasVideo)
                        const hasReplayVideo =  i?.videoReplayUrl?.length > 0;
                        const hasCVideo =  i?.highLightsVideoUrl?.length > 0;

                        const showVideo = hasReplayVideo || hasCVideo;
                        return (
                            <Grid container className={styles.TopContainer} mb={isInfoPage && 3} id={i.season+'_WMCard'}>

                                <div id={`WM_winnerImage_${idx}`} className={styles.winnerBadge_noVideo}>
                                    <img src={winnerBadge} className={styles.winnerBadge_img_noVideo}/>
                                </div>
                                <Grid container item px={2} pb={showVideo? 2 : 0}>
                                    <Grid container item>
                                        <Grid item xs={12} mt={1} >
                                            <Typography id={`WM_seasonText_${idx}`} className={`Label3-Regular ${styles.GridTitle}`}>{i.season}</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{
                                            maxWidth: "calc(100% - 74px)"
                                        }}>
                                            <Typography id={`WM_cardTitle_${idx}`} className={`Title/Title1-Bold ${styles.BigTitle} PlayFair`}>{i.racetitle}</Typography>
                                        </Grid>
                                        <Grid container xs={12}>
                                            <Grid id={`WM_location_${idx}`} xs={4} className={styles.linkToSmartV_item}>
                                                <div><img src={location}/></div>
                                                <Typography className={`Label2-Medium ${styles.GridTitle}`} >
                                                    {i.position}
                                                </Typography>
                                            </Grid>
                                            <Grid id={`WM_date_${idx}`} xs={4} className={styles.linkToSmartV_item}>
                                                <div><img src={date}/></div>
                                                <Typography className={`Label2-Medium ${styles.GridTitle}`}>
                                                    {i.date}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={1.5} mb={2}>
                                        <Divider variant="middle"  style={{background:'#CED3D9',opacity:0.3, flex: 1, margin: 0}}/>
                                    </Grid>
                                    <Grid container item >
                                        <Grid item xs={4} mb={2}>
                                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_Race')}</Typography>
                                            <Typography id={`WM_raceNo_${idx}`} className={`Label1-Bold ${styles.GridContent}`}>{i.raceNo}</Typography>
                                        </Grid>
                                        <Grid item xs={4} mb={2}>
                                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_RaceClass')}</Typography>
                                            <Typography id={`WM_raceClass_${idx}`} className={`Label1-Bold ${styles.GridContent}`}>{i.raceClass}</Typography>
                                        </Grid>
                                        <Grid item xs={4} mb={2}>
                                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_Distance')}</Typography>
                                            <Typography id={`WM_distance_${idx}`}  className={`Label1-Bold ${styles.GridContent}`}>{i.distance}</Typography>
                                        </Grid>
                                        <Grid item xs={4} mb={2}>
                                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_Track')}</Typography>
                                            <Typography id={`WM_track_${idx}`}  className={`Label1-Bold ${styles.GridContent}`}>{i.track}</Typography>
                                        </Grid>
                                        <Grid item xs={4} mb={2}>
                                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_Course')}</Typography>
                                            <Typography id={`WM_course_${idx}`}  className={`Label1-Bold ${styles.GridContent}`}>{i.course}</Typography>
                                        </Grid>
                                        <Grid item xs={4} mb={2}>
                                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_Going')}</Typography>
                                            <Typography id={`WM_going_${idx}`}  className={`Label1-Bold ${styles.GridContent}`}>{i.going}</Typography>
                                        </Grid>
                                        <Grid item xs={4} mb={2}>
                                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_Trainer')}</Typography>
                                            <Typography id={`WM_trainer_${idx}`} className={`Label1-Bold ${styles.GridContent}`}>{i.trainer}</Typography>
                                        </Grid>
                                        <Grid item xs={4} mb={2}>
                                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_Jockey')}</Typography>
                                            <Typography id={`Wm_jockey_${idx}`} className={`Label1-Bold ${styles.GridContent}`}>{i.Jockey}</Typography>
                                        </Grid>
                                        <Grid item xs={4} mb={2}>
                                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_FinishTime')}</Typography>
                                            <Typography id={`Wm_finishTime_${idx}`} className={`Label1-Bold ${styles.GridContent}`}>{i.finishTime}</Typography>
                                        </Grid>
                                    </Grid>

                                    {showVideo && <><Grid container mb={2}>
                                        <Divider variant="middle" style={{ background: '#CED3D9', opacity: 0.3, flex: 1, margin: 0 }} />
                                    </Grid>
                                    <Grid container justifyContent="flex-end" gap={2}>
                                        { isEn && <>
                                            {i.videoReplayUrl && <Grid textAlign="left" xs={isEnSmallWidthScreen ? '': 4} sm={2}>
                                                <div className={styles.bottomItem}>
                                                    <PlayVideoIcon
                                                        iconSize={18}
                                                        id='WM_raceReplayImg'
                                                        videoLink={i.videoReplayUrl}
                                                        content={<Typography id='WM_raceReplayText' marginLeft="4px" className={`Label2-Bold ${styles.RaceText}`}>
                                                            {t("Lbl_RaceReplay")}
                                                        </Typography>} />
                                                </div>
                                            </Grid>
                                            }
                                            {i.highLightsVideoUrl && <Grid className='WM_raceHightLIght_btn' ml={isEnSmallWidthScreen? 2: 0} xs={isEnSmallWidthScreen ? '': 4} sm={2}>
                                                <div className={styles.bottomItem}>
                                                    <PlayVideoIcon
                                                        iconSize={18}
                                                        id='WM_raceHightLIght'
                                                        videoLink={i.highLightsVideoUrl}
                                                        isComplimentaryVideoReady={true}
                                                        content={<Typography id='WM_HighLights' marginLeft="4px" className={`Label2-Bold ${styles.RaceText}`}>
                                                            {t("Lbl_HighLights_Cantonese")}
                                                        </Typography>} />
                                                </div>
                                            </Grid>
                                            }
                                        </>
                                        }
                                        { !isEn && <>
                                            {i.highLightsVideoUrl_Mandarin && <Grid className='WM_raceHightLIght_btn' ml={0} xs={""} sm={0}>
                                                <div className={styles.bottomItem}>
                                                    <PlayVideoIcon
                                                        iconSize={18}
                                                        id='WM_raceHightLIght'
                                                        videoLink={i.highLightsVideoUrl_Mandarin}
                                                        isComplimentaryVideoReady={true}
                                                        content={<Typography id='WM_HighLights' marginLeft="4px" className={`Label2-Bold ${styles.RaceText}`}>
                                                            {t("Lbl_HighLights_Mandarin")}
                                                        </Typography>} />
                                                </div>
                                            </Grid>
                                            }
                                            {i.highLightsVideoUrl && <Grid className='WM_raceHightLIght_btn' ml={0} xs={""} sm={0}>
                                                <div className={styles.bottomItem}>
                                                    <PlayVideoIcon
                                                        iconSize={18}
                                                        id='WM_raceHightLIght'
                                                        videoLink={i.highLightsVideoUrl}
                                                        isComplimentaryVideoReady={true}
                                                        content={<Typography id='WM_HighLights' marginLeft="4px" className={`Label2-Bold ${styles.RaceText}`}>
                                                            {t("Lbl_HighLights_Cantonese")}
                                                        </Typography>} />
                                                </div>
                                            </Grid>
                                            }
                                            
                                            {i.videoReplayUrl && <Grid textAlign="right" xs={12} sm={2}>
                                                <div className={styles.bottomItem} style={{ justifyContent: "flex-end" }}>
                                                    <PlayVideoIcon
                                                        iconSize={18}
                                                        id='WM_raceReplayImg'
                                                        videoLink={i.videoReplayUrl}
                                                        content={<Typography id='WM_raceReplayText' marginLeft="4px" className={`Label2-Bold ${styles.RaceText}`}>
                                                            {t("Lbl_RaceReplay")}
                                                        </Typography>} />
                                                </div>
                                            </Grid>
                                            }
                                            
                                        </>}
                                    </Grid>
                                    </>
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </React.Fragment>
     )
}

export default WMCard;