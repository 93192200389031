import { Typography, Divider, Avatar } from "@mui/material";
import styles from '../Css/RacingFixture.module.css'
import RaceTicket from "./RaceTicket";
import { useTranslation, Trans } from "react-i18next";
import { useEffect, useState,useContext } from "react";
import HorseContext from '../../utils/horseContext';
import moment from "moment";

function RacingFixture({ data,upcoming }) {
  const { t, i18n } = useTranslation();
  const [meetingData, setMeetingData] = useState([]);
  const [totalUpcomingData, setTotalUpcomingData] = useState([]);
  const { businessDate } = useContext(HorseContext); 
  const [showUpcoming,setShowUpcoming] = useState(true);
  useEffect(()=>{
    console.log(upcoming, 'horsedata')
    console.log(businessDate,'businessDate')
  },[])
  useEffect(()=>{
    if(upcoming.length>0&&data.length<=0){
      setMeetingData(upcoming)
    }
  },[upcoming])
  useEffect(() => {

    console.log(data, 'horsedata')
    console.log(upcoming, 'horsedata')
    //check if have upcoming race
    
    // let totalUpcoming = data.horse.reduce((acc, curr) => {
    //   const upcoming = curr.upcomingMeeting;
    //   const concat = acc.concat(upcoming)
    //   return concat
    // }, [])
    //Remove duplicate upcoming
    // totalUpcoming = totalUpcoming.filter((item, index, self) => index === self.findIndex((o) => o.startTime === item.startTime));
    // setTotalUpcomingData(totalUpcoming)
    //concat all race card

    if(data.length<=0) return;

    let totalFutureRace = data?.horse?.reduce((acc, curr, index) => {
      let futureRaceCard = curr.futureRaceCard;

      console.log(futureRaceCard, 'index')

      if (curr.futureRaceCard.length > 0) {
        //correct horse
        futureRaceCard = futureRaceCard.filter(item => item.isHorseOwner == true);
        futureRaceCard = futureRaceCard.map((item) => {
          return { ...item, name_ch: curr.name_ch, name_en: curr.name_en, trainer: curr.trainer }
        })
      }
      console.log(futureRaceCard, 'ff')
      const concat = acc.concat(futureRaceCard)
      console.log(concat, 'concat')
      return concat
    }, [])
    //Remove other horses race card
    // totalFutureRace= totalFutureRace.filter(item=>item.isHorseOwner==true)
    console.log(totalFutureRace, 'tt')
    if (totalFutureRace?.length > 0) {
      totalFutureRace = totalFutureRace.map(item => {
        return { ...item, horseNo:item.horseNo,sortString: item.date + item.raceNo.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + item.horseNo.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) }
      }).filter(item=>item.runnerStatus.toLowerCase() !== 'scratched'&&item.runnerStatus.toLowerCase()!=="standby"&&(item.raceStatus.toLowerCase()=="declared"||item.raceStatus.toLowerCase()=="result"||item.raceStatus.toLowerCase()=="closed"));
      totalFutureRace.sort((a, b) => a.sortString - b.sortString);
    }
    console.log(totalFutureRace,'tt')
    //&&moment(totalFutureRace[0].date,"YYYY-MM-DD").format("YYYY-MM-DD")!=businessDate
    //filter out closed or abandoned meeting.
    upcoming = upcoming.filter((item)=>{
      return (item.status=="STARTED"||item.status=="DECLARED"||item.status=="DEFINED")
    })
    
    //remove the duplicate meeting in both future race and upcoming meeting in total upcoming
    let totalUpcoming = upcoming.filter((item) => { return !totalFutureRace.some((itemB) => item.id == itemB.meetingId) })
    console.log(totalUpcoming,'totalUpcoming')
    //setTotalUpcomingData(totalUpcoming)
    console.log(businessDate,'bus')
    //fixed for story HOEA-778
    totalFutureRace = totalFutureRace?.concat(totalUpcoming).filter((item)=>new Date(moment(item.date,"YYYY-MM-DD").format("YYYY-MM-DD")).getTime() >= new Date(businessDate).getTime())
    totalFutureRace?.sort((a, b) => {
      if(moment(a.startTime).valueOf()<moment(b.startTime).valueOf()){
        return -1
      }else if (moment(a.startTime).valueOf()>moment(b.startTime).valueOf()){
        return 1
      }
      if(a.horseNo<b.horseNo){
        return -1
      }else{
        return 1
      }
    })
    setMeetingData(totalFutureRace)

    // const totalFutureRace = data.horse.map(item=>item.futureRaceCard);
    console.log(totalUpcoming, 'upcoming')
    console.log(totalFutureRace, 'totalFutureRace')
  }, [data])

 useEffect(()=>{
  console.log(meetingData,'meet')
  const meet = meetingData.filter(item=>item.raceStatus=="Declared"||item.raceStatus=="Closed")
  if(meet.length>0){
    setShowUpcoming(true)
  }else{
    //check if there is general meeting
    const general = meetingData.filter(item=>item.status)
    console.log(general,'gen')
    if(general.length>0){
      setShowUpcoming(true)
    }else
      setShowUpcoming(false)
  }
  console.log(meet,'meet');
 },[meetingData])
  return (
    <div id="UpcomingRaceContainer" className={styles.Base}>
      {
        meetingData.length > 0 &&showUpcoming ?
          <>
            <Typography id="UpcomingRace"  className={`Title/Title1-Bold ${styles.Title} PlayFair`}>
              {t('Lbl_UpcomingRace')}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', gap: "12px", marginTop: '12px', marginBottom: '16px' }}>
              {/*
                raceData for demo the owner have horse that will attend next race.
                Type for next race type, AWT or Turf, default is Turf.
                jockey is for demo, if no jockey then the share button will not display.
              */}
              {/* {totalUpcomingData.length>0?totalUpcomingData.map((item,index)=>{
                  let appearedDateArray=[];
                  return(meetingData.map((meeting, idx) => {
                    if(meeting.date==item.date){
                      appearedDateArray.push(meeting.date)
                      return (
                        <RaceTicket  raceData = {meeting} idx={idx}/>
         
                      )
                    }else if(!appearedDateArray.includes(item.date)){
                      return(
                        <RaceTicket  upcomingData = {item} idx={idx}/>
                      )
                    }else{
                      return null
                    }
                  }));
                }
                
                ):null} */}

              {meetingData.map((item, index) => {
                if (item.raceNo && Number.isInteger(item.raceNo)&&item.runnerStatus!="Scratched") {
                  if(item.raceStatus=="Declared"||item.raceStatus=="Closed"){
                  return (
                    <RaceTicket raceData={item} idx={index} key={index} />
                  )
                  }else {
                    return (<></>)
                  }

                } else {
                  return (
                    <RaceTicket upcomingData={item} idx={index} key={index}  />
                  )
                }
              })}
              {/* <RaceTicket jockey="s" raceData={raceData2}/>
                <RaceTicket  jockey="s" type={"AWT"} raceData={raceData1}/>
                <RaceTicket /> */}
            </div>

            {/* <Divider className={styles.Divider} /> */}
          </> : null
      }

    </div>
  )
}
export default RacingFixture;