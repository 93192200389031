import { Typography,Divider, Avatar } from "@mui/material";
import styles from '../Css/RacingFixture.module.css'
import NoSilk from '../../img/EmptySilk.png'
import { shareToMobile,racecardToMobile,mapVenueName,mapMeetingType } from "../../utils";
import { useTranslation, Trans } from "react-i18next";
import moment from "moment";
import 'moment/locale/zh-cn' 
import 'moment/locale/en-nz' 
import { useEffect } from "react";
import Jersey from '../../img/Jersey.svg'
import TrainerAvatar from "./TrainerAvatar";
function RaceTicket(props){
    const { idx } = props;
    const{t,i18n} = useTranslation()
    useEffect(()=>{
        console.log("raceticket")
    },[])
    console.log(props.raceData,"racedata")
    const langLink = i18n.language === 'ch' ? 'Chinese' : 'English';
    const isRaceDataTrackCodeAWT = props.raceData?.track?.code&&props.raceData?.track?.code=='AWT';
    return( 
    <>

    {(props.raceData&&props.raceData?.runnerStatus!=='Standby')||props.upcomingData&&props.upcomingData?.races[0]?.postTime?
    <div className={styles.TicketsContainer} id={`RacingFixture_${isRaceDataTrackCodeAWT? `raceData_${idx}`: `upcomingData_${idx}`}`}>
        <div className={styles.TicketBase}>
            
            {props.raceData?
                <>
                {isRaceDataTrackCodeAWT ?
                <div id="AWTRaceColor" className={styles.TicketColor} style={{backgroundColor: '#C38165'}}/>
                :
                <div id="OtherRaceColor" className={styles.TicketColor} />
                }
               
                <div className={styles.RaceSection}>
                
                    <div id="Race" className={styles.Race}>
                        <Typography className="fontSize14 lineHeight20">{t("Lbl_RaceNo")}</Typography> 
                        <Typography className="fontSize22 lineHeight26 PlayFair" id={`TicketsItem_raceNo_${idx}`}>{props.raceData.raceNo}</Typography> 
                    </div>
                    <div className={styles.RaceTime}>
                        <Typography id="RaceDate" className="fontSize18 lineHeight23 PlayFair">
                        {moment(props?.raceData?.startTime,"YYYY-MM-DD").format("DD/MM")}</Typography> 
                        <Typography id="RaceWeek" className="fontSize14 lineHeight20">
                      ({i18n.language=='ch'?moment(props?.raceData?.startTime,"YYYY-MM-DD").locale('zh-cn').format("dddd").toUpperCase(): moment(props?.raceData?.startTime,"YYYY-MM-DD").format("ddd").toUpperCase()})</Typography> 
                        <Typography id="RaceTime" className="fontSize18 lineHeight23 PlayFair">
                        {moment(props?.raceData?.startTime,"YYYY-MM-DDTHH:mm:ss").format("HH:mm")}</Typography> 
                    </div>
                
                </div>
                </>
            :props.upcomingData?  
                <div className={styles.RaceSection}>
                
                    <div id="Race" className={styles.Race}>
                        <Typography className="Label2-Bold PlayFair fontSize18 lineHeight23">{moment(props.upcomingData?.races[0]?.postTime,"YYYY-MM-DD").format("DD/MM")}</Typography> 
                        <Typography className="fontSize14 lineHeight20" >({i18n.language=='ch'?moment(props.upcomingData?.races[0]?.postTime,"YYYY-MM-DDTHH:mm:ss").locale('zh-cn').format("dddd").toUpperCase():moment(props.upcomingData?.races[0]?.postTime,"YYYY-MM-DD").format("ddd").toUpperCase()})</Typography> 
                    </div>
                   
    
                </div>
            :null
            }
            <div className={styles.RaceDetail}>
                <div className={styles.ClassDetail}>
                    {props.raceData?
                    <>
                        <Typography id="HorseNoAndName" className="fontSize18 lineHeight23 PlayFair" style={{overflowWrap:'anywhere'}}>{props.raceData.horseNo+". "}{i18n.language=='ch'?props.raceData.name_ch:props.raceData.name_en}</Typography> 
                        {props.raceData.jockey&&(!props.raceData.jockey.name_en.includes('--')||!props.raceData.jockey.name_ch.includes('--'))&&
                            <div id="ShareButton" className={styles.shareBtn} 
                                onClick={()=>{
                                    if(i18n.language === 'ch'){
                                        shareToMobile(t("Lbl_NextRaces_Tip",{
                                            Name:props.raceData.name_ch,
                                            Date:moment(props.raceData.startTime,'YYYY-MM-DDTHH:mm:ss').locale('zh-cn').format('ddddMM月D日'),
                                            Class:props.raceData.className?.chinese,
                                            Distance:props.raceData.distance,
                                            Racecourse:props.raceData.venue.chinese,
                                            RaceNo:props.raceData.raceNo,
                                            HorseNo:props.raceData.horseNo,
                                            Jockey:props.raceData.jockey?.name_ch
                                        }), window.globalConfig.RACECARD_URL.replace('{{lang}}', langLink))
                                        console.log(t("Lbl_NextRaces_Tip",{
                                            Name:props.raceData.name_ch,
                                            Date:moment(props.raceData.startTime,'YYYY-MM-DDTHH:mm:ss').locale('zh-cn').format('ddddMM月D日'),
                                            Class:props.raceData.className?.chinese,
                                            Distance:props.raceData.distance,
                                            Racecourse:props.raceData.venue.chinese,
                                            RaceNo:props.raceData.raceNo,
                                            HorseNo:props.raceData.horseNo,
                                            Jockey:props.raceData.jockey?.name_ch
                                        }))
                                    }else{
                                        shareToMobile(t("Lbl_NextRaces_Tip",{
                                            Name:props.raceData.name_en,
                                            Date:moment(props.raceData.startTime,'YYYY-MM-DDTHH:mm:ss').format('dddd (Do MMMM)'),
                                            Class:props.raceData.className?.english,
                                            Distance:props.raceData.distance,
                                            Racecourse:props.raceData.venue.english,
                                            RaceNo:props.raceData.raceNo,
                                            HorseNo:props.raceData.horseNo,
                                            Jockey:props.raceData.jockey?.name_en
                                        }), window.globalConfig.RACECARD_URL.replace('{{lang}}', langLink))
                                        console.log(t("Lbl_NextRaces_Tip",{
                                            Name:props.raceData.name_en,
                                            Date:moment(props.raceData.startTime,'YYYY-MM-DDTHH:mm:ss').format('dddd (Do MMMM)'),
                                            Class:props.raceData.className?.english,
                                            Distance:props.raceData.distance,
                                            Racecourse:props.raceData.venue.english,
                                            RaceNo:props.raceData.raceNo,
                                            HorseNo:props.raceData.horseNo,
                                            Jockey:props.raceData.jockey?.name_en
                                        }))
                                    }

                                    // shareToMobile("GOLDEN SIXTY will take his place in Race 8, a Group One over 1600M and will be ridden by C Y Ho at Sha Tin Racecourse on Wednesday (12 October)",
                                    // window.globalConfig.RACECARD_URL.replace('{{lang}}', 'English'))
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66503 7.08796C5.42887 7.08796 5.19955 6.96654 5.0747 6.74935C4.88947 6.42817 5.00351 6.01848 5.3285 5.83468L9.66656 3.66671C9.99155 3.48231 10.4061 3.59501 10.5921 3.91742C10.7773 4.2386 10.6633 4.6483 10.3383 4.83209L6.00008 7.00006C5.89423 7.05973 5.77895 7.08796 5.66503 7.08796Z" fill="#836B55"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0019 12.4213C9.88784 12.4213 9.77255 12.3931 9.66671 12.3334L5.32822 9.83213C5.00324 9.64907 4.88932 9.23936 5.07455 8.91743C5.25903 8.59563 5.6742 8.48231 5.99993 8.66672L10.3384 11.168C10.6634 11.351 10.7773 11.7608 10.5921 12.0827C10.4666 12.2999 10.2373 12.4213 10.0019 12.4213Z" fill="#836B55"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.59424 3.49999C9.59424 5.0626 10.8649 6.33339 12.4276 6.33339C13.9902 6.33339 15.2609 5.0626 15.2609 3.49999C15.2609 1.93751 13.9902 0.666725 12.4276 0.666725C10.8649 0.666725 9.59424 1.93751 9.59424 3.49999ZM10.6567 3.5C10.6567 2.52402 11.4508 1.72923 12.4276 1.72923C13.4043 1.72923 14.1984 2.52402 14.1984 3.5C14.1984 4.47611 13.4043 5.27091 12.4276 5.27091C11.4508 5.27091 10.6567 4.47611 10.6567 3.5Z" fill="#836B55"/>
                                    <path d="M12.4276 6.08339C11.003 6.08339 9.84424 4.92454 9.84424 3.49999H9.34424C9.34424 5.20067 10.7268 6.58339 12.4276 6.58339V6.08339ZM15.0109 3.49999C15.0109 4.92454 13.8521 6.08339 12.4276 6.08339V6.58339C14.1283 6.58339 15.5109 5.20067 15.5109 3.49999H15.0109ZM12.4276 0.916725C13.8521 0.916725 15.0109 2.07558 15.0109 3.49999H15.5109C15.5109 1.79944 14.1283 0.416725 12.4276 0.416725V0.916725ZM9.84424 3.49999C9.84424 2.07558 11.003 0.916725 12.4276 0.916725V0.416725C10.7269 0.416725 9.34424 1.79944 9.34424 3.49999H9.84424ZM12.4276 1.47923C11.3126 1.47923 10.4067 2.38601 10.4067 3.5H10.9067C10.9067 2.66202 11.5889 1.97923 12.4276 1.97923V1.47923ZM14.4484 3.5C14.4484 2.38601 13.5425 1.47923 12.4276 1.47923V1.97923C13.2662 1.97923 13.9484 2.66202 13.9484 3.5H14.4484ZM12.4276 5.52091C13.5425 5.52091 14.4484 4.61411 14.4484 3.5H13.9484C13.9484 4.33812 13.2662 5.02091 12.4276 5.02091V5.52091ZM10.4067 3.5C10.4067 4.61411 11.3126 5.52091 12.4276 5.52091V5.02091C11.5889 5.02091 10.9067 4.33812 10.9067 3.5H10.4067Z" fill="#836B55"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.59412 12.6668C9.59412 14.2293 10.8648 15.5001 12.4274 15.5001C13.9901 15.5001 15.2608 14.2293 15.2608 12.6668C15.2608 11.1042 13.9901 9.8334 12.4274 9.8334C10.8648 9.8334 9.59412 11.1042 9.59412 12.6668ZM10.6566 12.6668C10.6566 11.6907 11.4506 10.8959 12.4274 10.8959C13.4042 10.8959 14.1983 11.6907 14.1983 12.6668C14.1983 13.6428 13.4042 14.4376 12.4274 14.4376C11.4506 14.4376 10.6566 13.6428 10.6566 12.6668Z" fill="#836B55"/>
                                    <path d="M12.4274 15.2501C11.0029 15.2501 9.84412 14.0912 9.84412 12.6668H9.34412C9.34412 14.3673 10.7267 15.7501 12.4274 15.7501V15.2501ZM15.0108 12.6668C15.0108 14.0912 13.852 15.2501 12.4274 15.2501V15.7501C14.1282 15.7501 15.5108 14.3673 15.5108 12.6668H15.0108ZM12.4274 10.0834C13.852 10.0834 15.0108 11.2422 15.0108 12.6668H15.5108C15.5108 10.9661 14.1282 9.5834 12.4274 9.5834V10.0834ZM9.84412 12.6668C9.84412 11.2422 11.0029 10.0834 12.4274 10.0834V9.5834C10.7267 9.5834 9.34412 10.9661 9.34412 12.6668H9.84412ZM12.4274 10.6459C11.3125 10.6459 10.4066 11.5527 10.4066 12.6668H10.9066C10.9066 11.8287 11.5888 11.1459 12.4274 11.1459V10.6459ZM14.4483 12.6668C14.4483 11.5527 13.5424 10.6459 12.4274 10.6459V11.1459C13.2661 11.1459 13.9483 11.8287 13.9483 12.6668H14.4483ZM12.4274 14.6876C13.5424 14.6876 14.4483 13.7808 14.4483 12.6668H13.9483C13.9483 13.5048 13.2661 14.1876 12.4274 14.1876V14.6876ZM10.4066 12.6668C10.4066 13.7808 11.3125 14.6876 12.4274 14.6876V14.1876C11.5888 14.1876 10.9066 13.5048 10.9066 12.6668H10.4066Z" fill="#836B55"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666687 7.77784C0.666687 9.34048 1.93748 10.6112 3.49996 10.6112C5.06257 10.6112 6.33335 9.34048 6.33335 7.77784C6.33335 6.21519 5.06257 4.9445 3.49996 4.9445C1.93748 4.9445 0.666687 6.21519 0.666687 7.77784ZM1.72919 7.77784C1.72919 6.80171 2.5232 6.00702 3.49996 6.00702C4.47685 6.00702 5.27087 6.80171 5.27087 7.77784C5.27087 8.75398 4.47685 9.54866 3.49996 9.54866C2.5232 9.54866 1.72919 8.75398 1.72919 7.77784Z" fill="#836B55"/>
                                    <path d="M3.49996 10.3612C2.07554 10.3612 0.916687 9.20241 0.916687 7.77784H0.416687C0.416687 9.47856 1.79941 10.8612 3.49996 10.8612V10.3612ZM6.08335 7.77784C6.08335 9.2024 4.9245 10.3612 3.49996 10.3612V10.8612C5.20063 10.8612 6.58335 9.47856 6.58335 7.77784H6.08335ZM3.49996 5.1945C4.9245 5.1945 6.08335 6.35327 6.08335 7.77784H6.58335C6.58335 6.07711 5.20063 4.6945 3.49996 4.6945V5.1945ZM0.916687 7.77784C0.916687 6.35326 2.07554 5.1945 3.49996 5.1945V4.6945C1.79941 4.6945 0.416687 6.07712 0.416687 7.77784H0.916687ZM3.49996 5.75702C2.38507 5.75702 1.47919 6.6637 1.47919 7.77784H1.97919C1.97919 6.93971 2.66134 6.25702 3.49996 6.25702V5.75702ZM5.52087 7.77784C5.52087 6.66369 4.61498 5.75702 3.49996 5.75702V6.25702C4.33873 6.25702 5.02087 6.93973 5.02087 7.77784H5.52087ZM3.49996 9.79866C4.61498 9.79866 5.52087 8.892 5.52087 7.77784H5.02087C5.02087 8.61596 4.33873 9.29866 3.49996 9.29866V9.79866ZM1.47919 7.77784C1.47919 8.89198 2.38507 9.79866 3.49996 9.79866V9.29866C2.66134 9.29866 1.97919 8.61597 1.97919 7.77784H1.47919Z" fill="#836B55"/>
                                </svg>
                            </div>
                        }
                    
                        <Typography id="RaceNameContent" className="fontSize14 lineHeight20">{i18n.language=='ch'?props.raceData.raceName.chinese:props.raceData.raceName.english}</Typography>
                        <Typography id="CourseContent" className="fontSize14 lineHeight20" style={{textTransform:"capitalize"}} >{i18n.language=='ch'?`${props.raceData.className.chinese}, ${props.raceData.distance}米, ${props.raceData.track.chinese}${isRaceDataTrackCodeAWT?'': `, ${props.raceData.course.chinese}`} `:
                        `${props.raceData.className.english}, ${props.raceData.distance}M, ${props.raceData.track.english}${isRaceDataTrackCodeAWT?'': `, ${props.raceData.course.english}`}`}
                        </Typography>
                        <Typography id="LocationInfo" className="fontSize14 lineHeight20" style={{textTransform:"capitalize",color:'#84909F'}} >
                            <svg id="LocationImage" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="#84909F" style={{top:'0.2em',position:'relative',color:'#84909F'}}>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 6.53687C3.25 3.53686 5.7345 1.9021 8.03578 1.9021C10.3371 1.9021 12.75 3.53686 12.75 6.53687C12.75 8.87999 7.91683 14.098 7.91683 14.098C7.91683 14.098 3.25 8.87999 3.25 6.53687ZM8.15 4.03687C6.76917 4.03687 5.65 5.17638 5.65 6.58232C5.65 7.98826 6.76917 9.12778 8.15 9.12778C9.53083 9.12778 10.65 7.98826 10.65 6.58232C10.65 5.17638 9.53083 4.03687 8.15 4.03687Z" fill="#84909F" stroke="#84909F" stroke-linejoin="round"/>
                            </svg>
                            {i18n.language=='ch'?props.raceData.venue.chinese.toLowerCase():props.raceData.venue.english.toLowerCase()}
                        </Typography>
                        <Typography id="RaceCardShare" className={`fontSize14 lineHeight20 ${styles.RaceCardShare}`} onClick={()=>{racecardToMobile(props?.raceData?.meetingId,props?.raceData?.raceId)}} >
                            {t("Lbl_RaceCard")}
                        </Typography>
                    </>
                    : //if no starter
                    props.upcomingData?
                    <>
                        <Typography id="NoStarterTitle" className="fontSize18 lineHeight20 PlayFair" >{mapVenueName(props.upcomingData.venueCode,i18n.language)+mapMeetingType(props.upcomingData.meetingType,i18n.language)}</Typography> 
                        <Typography id="NoStarterTime" className="fontSize14 lineHeight20" >{t("Lbl_MeetingStart")} {moment(props.upcomingData?.races[0]?.postTime,"YYYY-MM-DDTHH:mm:ss").format("HH:mm")}</Typography>
                    </>    
                    :null
                    }
                </div>
                {props.raceData?
                <>
                    <Divider style={{ background: '#CED3D9', opacity: '0.3' }}  />
                    <div className={styles.BottomSection}>
                        <div className={styles.JockeySection}>
                            <div className={styles.Jockey}>
                                <TrainerAvatar className={styles.Avatar}  type={'trainer'} src={`${window.globalConfig.SITE_DOMAIN}${window.globalConfig.TRAINER_IMAGE_PATH}${props.raceData.trainer?.code}.jpg`}/>
                                {/* <Avatar className={styles.Avatar} src={`${window.globalConfig.SITE_DOMAIN}${window.globalConfig.TRAINER_IMAGE_PATH}${props.raceData.trainer?.code}.jpgs`||Jersey}></Avatar> */}
                                <div className={styles.LabelSection}>
                                    <Typography id="TrainerTitle" className="fontSize14 lineHeight20" style={{color:'#84909F'}} >{t("Lbl_Trainer")}</Typography> 
                                    <Typography id="TrainerName" className="fontSize14 lineHeight20">{i18n.language=='ch'? props.raceData.trainer?.name_ch:props.raceData.trainer?.name_en}</Typography> 
                                </div>
                            
                            </div>
                        </div>
                        
                        <div className={styles.JockeySection}>
                            <div className={styles.Jockey}>
                            <TrainerAvatar className={styles.Avatar}  src={`${window.globalConfig.SITE_DOMAIN}${window.globalConfig.JOCKEY_IMAGE_PATH}${props.raceData?.jockey?.code}.jpg`}/>
                                {/* <Avatar className={styles.Avatar} src={`${window.globalConfig.SITE_DOMAIN}${window.globalConfig.JOCKEY_IMAGE_PATH}${props.raceData?.jockey?.code}.jpgs`}></Avatar> */}
                                <div className={styles.LabelSection}>
                                    <Typography id="Jockeytitle" className="fontSize14 lineHeight20" style={{color:'#84909F'}}> {t("Lbl_Jockey")}</Typography> 
                                    <Typography id="JockeyName" className="fontSize14 lineHeight20">{props.raceData.jockey?i18n.language=='ch'?props.raceData.jockey?.name_ch:props.raceData.jockey?.name_en?props.raceData.jockey?.name_en:'--':'--'}</Typography> 
                                </div>
                            
                            </div>
                        </div>
                        
                    </div>
                </>
                :null}
                
            </div>
        </div>    

    </div>
    :null}
    </>

    )
}
export default RaceTicket;