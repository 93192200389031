import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import HorseStat from "../HorseProfile/Component/HorseStat";
import { Avatar, Typography } from "@mui/material";
import HorsePlaceholder from '../img/ProfileHorsePlaceholder.svg'
import styles from './Css/FormsRecordsDetails.module.css'
import SeasonalTable from './FormTable'
import ExplainDrawer from '../TrackWork/Component/ExplainDrawer'
import ExplainPlaDrawer from './ExplainPlaDrawer'
import { getFormDetail } from '../request/requestList'
import Header from '../Component/Header';
import dayjs from 'dayjs';
import HorseContext from '../utils/horseContext';
import LoadingOverlay from '../Component/loading/loading-overlay';

const SeasonalTableDisclaimer = () => {
    return window.globalConfig.isLangUS ? <div className={styles.tableExtra}>
    <p className={"Label3-Regular"} ><span className={"Label3-Bold"} >ST -</span> Sha Tin <span className={"Label3-Bold"} >| HV -</span> Happy Valley <span className={"Label3-Bold"} >| CH -</span> Conghua<tr /><span className={"Label3-Bold"}>LBW -</span> Length Behind Winner</p>
    </div> : null
}   

function FormRecordsDetail(props) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { id, ownerType, lang } = useParams();
    const [requestData, setRequestData] = useState(null);
    const [isImgLoading, setImgIsLoading] = useState(true);
    const [isImgError, setImgIsError] = useState(false);
    const [open, setOpen] = useState(false)
    const [videoUrl, setVideoUrl] = useState('');
    const [plaOpen, setPlaOpen] = useState(false)
    const [horseData, setHorseData] = useState();
    const { businessDate, setbusinessDate } = useContext(HorseContext);
    const [Load,setLoad] = useState(true);
    useEffect(() => {
        if (requestData !== null) {
            getFormDetail(requestData, t).then((res) => {
                const date = res?.timeOffset?.ts;
                const formatDate = dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD");
                date && setbusinessDate(formatDate);

                setHorseData(res)
                setLoad(false)
            })
        }
    }, [requestData])
    useEffect(() => {
        console.log('data', id, ownerType)
        if (lang === 'en') {
            i18n.changeLanguage('en')

        }
        if (lang === 'ch') {
            i18n.changeLanguage('ch')

        }
        if (id != null && ownerType != null) {
            setRequestData({
                horses: [{
                    "brandNumber": id,
                    "ownerType": ownerType,
                }]
            })
        }
        console.log('id', id)
    }, [])
    const handleImageLoad = () => {
        setImgIsLoading(false);
    };
    const handleImageError = () => {
        setImgIsError(true);
        setImgIsLoading(false);
    };
    const clickGear = () => {
        setOpen(true)
    }
    const clickPla = () => {
        setPlaOpen(true)
    }
    return (
        <>
        {Load&&<LoadingOverlay/>}
            <Header title='Lbl_FormRecord' />

            <div className={styles.Base} style= {{ height: window.innerHeight - 60 }}>
                {horseData !== null && horseData?.horse?.length > 0 ?
                    <>
                        <div className={styles.horseWrapper}>
                            <Avatar
                                className={styles.HorseAvatar}
                                id="FR_detail_avatar"
                                src={isImgLoading ? HorsePlaceholder : isImgError ? HorsePlaceholder : `${window.globalConfig.SITE_DOMAIN}${window.globalConfig.HORSE_IMAGE_PATH}${id}_l.jpg`}
                                onLoad={handleImageLoad}
                                onError={handleImageError}
                            />
                            <Typography id='FR_detail_name' className={`${styles.NameText} Title/Title1-Bold PlayFair` }> {lang === 'ch' ? horseData.horse[0].name_ch === "" ? "未命名" : horseData.horse[0].name_ch : horseData.horse?.[0].name_en === horseData.horse[0].brandNumber ? "UNNAMED" : horseData.horse[0].name_en}</Typography>
                        </div>
                        <div className={styles['table-wrapper']} style= {{ height: window.innerHeight - 60 - 80}}>
                            <SeasonalTable onClickPla={clickPla} onClickGear={clickGear} tableData={horseData?.horse[0]} disclaimerFooter={SeasonalTableDisclaimer}/>
                            {/* <SeasonalTableDisclaimer/> */}
                        </div>
                    </>
                    : null
                }
                <ExplainDrawer open={open} setOpen={setOpen} />
                <ExplainPlaDrawer open={plaOpen} setOpen={setPlaOpen} />
            </div >
        </>
    )
}
export default FormRecordsDetail