
import styles from './css/IncidentReport.module.css'
import { useTranslation, Trans } from "react-i18next";
import { useEffect } from 'react';
function IncidentReport({data,name}){
    const { t, i18n } = useTranslation();
    useEffect(()=>{
        console.log(data,'daa')
    },[])
    return(
        <>
            {data&&data?.incidentReport&&(i18n.language==='ch'&&data.incidentReport?.chinese!==''||i18n.language==='en'&&data.incidentReport?.english!=='')?
            <div className={styles.Base}>
                <div className={`${styles.title} PlayFair`} id="RD_RIR_Tilte">
                    {t("Lbl_RacingIncidentReport")}
                </div>
                <div className={styles.CardBase}>
                    <div className={styles.CardTitle} id="RD_RIR_Name">
                        {name}
                    </div>
                    <div className={styles.CardContent} id="RD_RIR_Content">
                        {i18n.language === 'ch'?data.incidentReport?.chinese: data.incidentReport?.english}
                        {/* Jumped only fairly. Approaching the 1000 Metres was bumped on the hindquarters and became unbalances. Sent for sampling post-race. */}
                    </div>
                </div>
                {/* <div className={styles.CardBase}>
                    <div className={styles.CardTitle}>
                        {t("Lbl_NoReport")}
                    </div>
                </div> */}
            </div>:null}
        </>
    )
}

export default IncidentReport;