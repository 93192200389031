import fetchRequest from './index';
import { 
    GET_HORSE_RACE_FORM_RECORDS,
    GET_HORSE_FORM_RECORDS,
    GET_MYHORSE,
    GET_HORSE,
    GET_POST_RACE_WCAS_CONFIG,
    GET_MEETINGS,
    GET_HORSE_NAME,
    GET_TIMEOFFSET
} from './graghQLQuery';


const errorCode = {
    getHorse: 100,
    getMyHorse: 101,
    getFormDetail: 102,
    getHorseRaceFormDetail: 103,
    getPostRaceConfig: 104,
    getMeeting:105,
    getHorseName:106,
    getTimeOffset:107
}

export const getHorseRaceFormDetail = async (variables, ttx) => {
    return await fetchRequest(variables, GET_HORSE_RACE_FORM_RECORDS, ttx, errorCode['getHorseRaceFormDetail'])
}

export const getFormDetail = async (variables, ttx) => {
    return await fetchRequest(variables, GET_HORSE_FORM_RECORDS, ttx, errorCode['getFormDetail'])
}
export const getHorse = async (variables, ttx) => {
    return await fetchRequest(variables, GET_HORSE, ttx, errorCode['getHorse'])
}
export const getMeeting = async (variables, ttx) => {
    return await fetchRequest(variables, GET_MEETINGS, ttx, errorCode['getMeeting'])
}
export const getMyHorse = async (variables, ttx) => {
    return await fetchRequest(variables, GET_MYHORSE, ttx, errorCode['getMyHorse'])
}
export const getHorseName = async (variables, ttx) => {
    return await fetchRequest(variables, GET_HORSE_NAME, ttx, errorCode['getHorseName'])
}
export const getPostRaceConfig = async (variables, ttx) => {
    return await fetchRequest(variables, GET_POST_RACE_WCAS_CONFIG, ttx, errorCode['getPostRaceConfig'])
}

export const getTimeOffset = async (variables, ttx) => {
    return await fetchRequest(variables, GET_TIMEOFFSET, ttx, errorCode['getTimeOffset'])
}
