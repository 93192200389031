import { useEffect, useState } from "react";
import Header from "../Component/Header";
import "./demo.css"
function Demo(props) {
    const [selectedButton, setSelectedButton] = useState(0);
    const handleButtonClick = (index) => {
        if (selectedButton === index) return
        const floatingDiv = document.getElementById('floatingDiv');
        if (floatingDiv.style.opacity === '0' || !floatingDiv.style.opacity) return;
        setSelectedButton(index);
        const refIndex = index + 1
        const contentDom = document.getElementById(`content${refIndex}`)
        if (refIndex === 1) {
            window.scrollTo(0, 0);
        } else {
            contentDom.scrollIntoView()
        }
    };

    const buttonStyles = (i) => {
        return {
            backgroundColor: selectedButton === i ? "#3D9BC4" : "white",
            color: selectedButton === i ? "white" : "black",
        }
    };
    useEffect(() => {
        window.onscroll = function () {
            const floatingDiv = document.getElementById('floatingDiv');
            if (window.pageYOffset > 200) { // 假设页面向上滑动距离大于 200px 时显示悬浮 div  
                floatingDiv.style.opacity = '1';
            } else { // 否则隐藏悬浮 div  
                floatingDiv.style.opacity = '0';
            }
        };
    }, [])
    return (
        <>
        <Header title='Demo'/>
            <div className="demo-container">
                <div id="floatingDiv" className="tabs-menu-wrapper">
                    <div className="taps-menu-item"
                        style={buttonStyles(0)}
                        onClick={() => handleButtonClick(0)}>
                        Part 1
                    </div>
                    <div className="taps-menu-item"
                        style={buttonStyles(1)}
                        onClick={() => handleButtonClick(1)}>
                        Part 2
                    </div>
                    <div className="taps-menu-item"
                        style={buttonStyles(2)}
                        onClick={() => handleButtonClick(2)}>
                        Part 3
                    </div>
                </div>
                <div id="content1" className="content">
                    content1
                </div>
                <div id="content2" className="content">
                    content2
                </div>
                <div id="content3" className="content">
                    content3
                </div>
            </div>
        </>
    )
}

export default Demo;