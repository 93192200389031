import styles from '../HorseProfile/Css/VeterinaryRecord.module.css';
import { Divider, Grid, Paper, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useTranslation, Trans } from "react-i18next";
import { useLocation } from 'react-router-dom';
import Header from '../Component/Header';
import VeterinaryRecordItem from '../HorseProfile/Component/VeterinaryRecordItem';
function VeterinaryRecords(props) {
    const location = useLocation();
    const { data, lang } = location.state
    const { t, i18n } = useTranslation();
    const [langCode, setLangCode] = useState('en');
    useEffect(() => {
        //sortDate();
    }, [data])
    function sortDate() {
        if (data !== null) {
            data.VeterinaryRecords = data.VeterinaryRecords.sort((a, b) => {
                return moment(b.Date, "DD/MM/YYYY").format("YYYYMMDD") - moment(a.Date, "DD/MM/YYYY").format("YYYYMMDD")
            })
            console.log(data.VeterinaryRecords)
        }

    }
    useEffect(() => {
        if (lang == 'en') {
            i18n.changeLanguage('en')
            setLangCode('en')
        }
        if (lang == 'ch') {
            i18n.changeLanguage('ch')
            setLangCode('ch')
        }
    }, [])
    return (
        <>
            <Header title='Lbl_VeterinaryRecord' />
            <div style={{ zIndex: 1 , paddingTop: 16, background: "#F2F3F5", height: window.innerHeight - 76, overflowY: "scroll", color: '#0A2240' }} className='TabletBaseContainer' >
                {data?.map((item, index) =>
                    <>  
                    <VeterinaryRecordItem item={item} index={index} lang={lang}/>
                        {/* <Grid container mb={2} px={2}  >
                            <Grid item container className={styles.TipRow} id={"VeterinaryRow" + index}>
                                <Grid item xs={9} mr={1}>
                                    <Typography className={styles.Date} id={"VeterinaryDate" + index}>{moment(item.incidentDate, "YYYY-MM-DD").format('DD/MM/YYYY')}</Typography>
                                    <Typography className={styles.SubTitle} id={"VeterinaryDetail" + index}>{lang == 'ch' ? item.vetDetail_ch : item.vetDetail_en}</Typography>
                                    
                                </Grid>
                                {item.passedDate == null ? null :
                                    <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
                                        <div className={styles.PassedBox} id={"VeterinaryPassedDate" + index} >
                                            <Typography mx={0.5} className={styles.DateText}>{moment(item.passedDate, "YYYY-MM-DD").format('DD/MM/')}</Typography>
                                            <Typography mx={0.5} className={styles.DateText}>{moment(item.passedDate, "YYYY-MM-DD").format('YYYY')}</Typography>
                                            <Typography mx={0.5} className={styles.DateText}>{t("Lbl_Passed")}</Typography>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </Grid> */}
                    </>)}
            </div>
        </>
    )
}
export default VeterinaryRecords;