import Dialog from '@mui/material/Dialog';
import React, { Component, useEffect, useState, useRef,} from 'react'
import ReactPlayer from 'react-player';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import '../Css/VideoDialog.css'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const VideoDialog = (props) => {
    const { open, onClose, videoUrl} = props;
    const [url, setUrl] = useState(videoUrl);
    const [pip, setpip] = useState(false);
    const [playing, setplaying] = useState(false);
    const [controls, setcontrols] = useState(true);
    const [light, setlight] = useState(false);
    const [volume, setvolume] = useState(0.8);
    const [seeking, setseeking] = useState(false);
    const [played, setplayed] = useState(0);
    const [loaded, setloaded] = useState(0);
    const [duration, setduration] = useState(0);
    const [playbackRate, setplaybackRate] = useState(1.0);

    const [fade, setfade] = useState(1);
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { t, i18n } = useTranslation();

    const player = useRef();

    const handleOnPlaybackRateChange = (speed) => {
        setplaybackRate(parseFloat(speed))
    }
    
    const handlePlay = (e) => {
        if (loading) return;
        // console.log('onPlay')
        setplaying(true)
    }

    const handleEnablePIP = () => {
        // console.log('onEnablePIP')
        setpip(true)
    }

    const handleDisablePIP = () => {
        console.log('onDisablePIP')
        setpip(false)
    }

    const handlePause = () => {
        if (loading) return;
        console.log('onPause')
        setplaying(false)
    }

    const handleProgress = state => {
        if (loading) return;
        console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!seeking) {
            setplayed(state.played);
            setloaded(state.loaded);
            // setduration(state.duration)
        }
    }

    const handleEnded = () => {
        console.log('onEnded')
    }

    const handleDuration = (duration) => {
        // console.log('onDuration', duration)
        setduration(duration)
    }
    

    useEffect(() => {
        videoUrl && setUrl(videoUrl)
    },[videoUrl])

    useEffect(() => {
        if (videoUrl && !playing)  setplaying(true);
        setIsError(false);
        setLoading(true);
    },[open])

    const onArrowBackIosNewIcon = (event) => {
        event.stopPropagation()
        // console.log('setOpen=====', setOpen, open)
        setplaying(false)
        onClose && onClose()
    }

    const handleSeekMouseDown = e => {
        if (loading) return;
        setseeking(true);
    }
    
    const handleSeekChange = e => {
        if (loading) return;

        setplayed(parseFloat(e.target.value));
    }
    
    const handleSeekMouseUp = e => {
        if (loading) return;

        setseeking(false);
        player.current.seekTo(parseFloat(e.target.value))
    }

    const handleScreenClick = (e) => {
        setfade(fade === 1 ? 0 : 1)
    }
    return (
        <Dialog
            fullScreen
            open={open}
            // onClose={handleClose}
            >
            {isError &&<Dialog
                open={isError}
                // onClose={handleClose}
                >
                <DialogTitle>{t("Lbl_NetWork_Fail")}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => onClose()}>{t("Lbl_OK")}</Button>
                </DialogActions>
            </Dialog>}

            {loading && <div className='VideoLoading'>
                <CircularProgress />
            </div>}


             <div className='VideoDialog' onClick={(e) => {handleScreenClick(e)}}>
                <div className='VideoDialog-header' style = {{ opacity: fade }}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                    <ArrowBackIosNewIcon onClick={onArrowBackIosNewIcon}/>
                </div>
                <ReactPlayer
                    ref={player} 
                    className='react-player'
                    width='100%'
                    height='100%'
                    url={url}
                    pip={pip}
                    playing={playing}
                    // controls={false}
                    light={light}
                    playbackRate={playbackRate}
                    volume={volume}
                    muted={false}
                    onReady={() => {
                        console.log('onReady');
                        setIsError(false);
                        setLoading(false);
                    }}
                    onStart={() => console.log('onStart')}
                    onPlay={handlePlay}
                    onEnablePIP={handleEnablePIP}
                    onDisablePIP={handleDisablePIP}
                    onPause={handlePause}
                    onBuffer={() => console.log('onBuffer')}
                    onPlaybackRateChange={handleOnPlaybackRateChange}
                    onSeek={e => console.log('onSeek', e)}
                    onEnded={handleEnded}
                    onError={e => {
                        setIsError(true);
                        setLoading(false);
                    }}
                    onProgress={handleProgress}
                    progressInterval={40}
                    onDuration={handleDuration}
                    onPlaybackQualityChange={e => console.log('onPlaybackQualityChange', e)}
                    config={{
                         file:{ 
                            attributes:{ 
                                controlsList: 'nodownload nofullscreen noplaybackrate', 
                                disablePictureInPicture: true
                            }
                        },
                    }}
                    playsinline 
                />
                <div className='videoCustomBar-container' style = {{ opacity: fade }}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                    <div className='videoCustomBar-item' style={{ alignItems: 'end'}}>
                       
                        
                        <div style={{display: "flex"}}>
                            {!playing ? <PlayArrowIcon onClick={handlePlay}/> : <PauseIcon onClick={handlePause}/>}
                            &nbsp;&nbsp;<Duration seconds={duration * played} />&nbsp;/&nbsp;<Duration seconds={duration} />
                        </div>

                        {/* <VolumeUpIcon/> */}
                    </div>
                    
                    <div className='videoCustomBar-item'>
                        <input
                            type='range' min={0} max={0.999999} step='any'
                            value={played}
                            onMouseDown={handleSeekMouseDown}
                            onChange={handleSeekChange}
                            onMouseUp={handleSeekMouseUp}

                            onTouchStart={handleSeekMouseDown}
                            onTouchMove={handleSeekChange}
                            onTouchEnd={handleSeekMouseUp}
                        />
                    </div>
                </div>
                
                <div className='videoCustomBar-marsk' style = {{ opacity: fade }}>
                </div>
            </div>
        </Dialog>
    )
}
 

const Duration = ({ className, seconds }) =>{
    return (
      <time dateTime={`P${Math.round(seconds)}S`} className={className}>
        {format(seconds)}
      </time>
    )
  }
  
function format (seconds) {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
        return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
}

function pad (string) {
    return ('0' + string).slice(-2)
}
export default VideoDialog;