
import styles from '../Css/VeterinaryRecord.module.css'
import { Divider, Grid, Paper,Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import VeterinaryRecordItem from './VeterinaryRecordItem';
function VeterinaryRecord({data,lang}){

    const navigate = useNavigate();
    const{t,i18n} = useTranslation();
    useEffect(()=>{
        sortDate();
    },[data])
    const [sortData,setSortData] = useState(null);
    function sortDate(){
        if(data!==null){
            data = data.slice().sort((a,b)=>{
                return  moment(b.incidentDate,"YYYY-MM-DD").format("YYYYMMDD") - moment(a.incidentDate,"YYYY-MM-DD").format("YYYYMMDD") 
            })
         
        }
        setSortData(data)
    }
    return(
        <div className={styles.Base}>
        {sortData==null?<></>:
            <>            
                <div className={styles.Header}>
                    <div id="VeterinaryRecordTitle" className={`${styles.Title} PlayFair`}>
                    {t("Lbl_VeterinaryRecord")}
                    </div>
                    <div style={{flex:1}}/>
                    {sortData.length>3?
                    <div  className={`Label2-Medium ${styles.More}`} id={"See_All_V"} onClick={()=>{  navigate(`VeterinaryRecords?cuno=${window.cuno}`,{state:{data:sortData,lang:lang}})}}>
                        {t("Lbl_SeeAll")}
                    </div>:null}

                </div>
                {sortData&&sortData.length<=0?
                    <div className={styles.Tip}>
                        <Typography className={`Label1-SemiBold ${styles.SubTitle} fontSize14`}  id="NoVeterinary">{t("Lbl_NoVeterinary")}</Typography>
                        <div className={styles.PassBox}></div>
                    </div>
                    :
                    sortData.map((item,index)=>
                        index<3?
                        <VeterinaryRecordItem item={item} index={index} lang={lang} key={"VeterinaryRow"+index}/>
                        // <Grid container mb={2} px={2} key={"VeterinaryRow"+index} >
                        //    <Grid item container className={styles.TipRow} id = {"VeterinaryRow"+index}>
                        //         <Grid item>
                        //             <Typography class="Label3-Medium"    className={styles.DateText} id = {"VeterinaryDate"+index}>{moment(item.incidentDate,"YYYY-MM-DD").format('DD/MM/YYYY')}</Typography>
                        //             <Typography class="Title/Title2-Medium" id = {"VeterinaryDetail"+index}>{lang=='ch'?item.vetDetail_ch:item.vetDetail_en}</Typography>
                        //             {item.passedDate==null?null:
                        //                 <div id = {"VeterinaryPassedDate"+index} > 
                        //                    <Typography class="Title/Title2-Medium" >{t("Lbl_PassedDate")}: {moment(item.passedDate,"YYYY-MM-DD").format('DD/MM/YYYY')}</Typography>         
                        //                 </div>
                        //             }
                        //         </Grid>
                        //         {/* {item.passedDate==null?null:
                        //             <Grid item xs style={{display:'flex',justifyContent:'flex-end',alignItems:'end'}} >
                        //                 <div className={styles.PassedBox} id = {"VeterinaryPassedDate"+index} > 
                        //                     <Typography mx={0.5} className={styles.DateText}>{moment(item.passedDate,"YYYY-MM-DD").format('DD/MM/')}</Typography>
                        //                     <Typography mx={0.5} className={styles.DateText}>{moment(item.passedDate,"YYYY-MM-DD").format('YYYY')}</Typography>
                        //                     <Typography mx={0.5} className={styles.DateText}>{t("Lbl_Passed")}</Typography>
                        //                 </div>
                        //             </Grid>
                        //         } */}
                        //    </Grid>


                        //     {/* <div>
                        //         {item.PassedDate==null?null:
                        //             <div className={styles.PassedBox}> 
                        //                 <div mx={2} className={styles.DateText}>{item.PassedDate+"Passed"}</div>
                        //             </div>
                        //         }
                        //     </div> */}
                        // </Grid>
                        :null
                    )
                
                }
            </>
        }
        </div>
     )
}
export default VeterinaryRecord;