import { useEffect, useState, useContext } from "react";
import "./Css/HorseProfile.css"
import { Avatar, Grid} from "@mui/material";
import { StyledEngineProvider } from '@mui/material/styles';
import NextRaceTip from './Component/NextRaceTip'
import ProfileSlip from "./Component/ProfileSlip";
import HorseStat from "./Component/HorseStat";
import SoleOwner from '../img/Owner.svg'
import Partnership from '../img/partnership.svg'
import Syndicate from '../img/syndicate.svg'
import location_while from '../img/location_while.svg';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import DummyData from '../Config/DummyData/DummyData.json';
import HorsePlaceholder from '../img/ProfileHorsePlaceholder.svg'
import MovementRecord from "./Component/MovementRecord";
import VeterinaryRecord from "./Component/VeterinaryRecord";
import TrackWork from './Component/TrackWork';
import WinningMoments from './Component/WinningMoments';
import MyRecords from '../Component/MyRecord/MyRecord';

import { getMyHorse } from '../request/requestList'
import { useLocation } from 'react-router-dom';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import MenuTabs from './Component/MenuTabs'
import Header from "../Component/Header";
import HorseContext from '../utils/horseContext';
import dayjs from 'dayjs';
import LoadingOverlay from "../Component/loading/loading-overlay";

function HorseProfile(props) {
    const { t, i18n } = useTranslation();
    const { id, ownerType, lang } = useParams();
    const [langCode, setLangCode] = useState('en');
    const [dummyData, setDummyData] = useState(null);

    const [isImgLoading, setImgIsLoading] = useState(true);
    const [isImgError, setImgIsError] = useState(false);
    const [data, setData] = useState(null);
    const [hasWinMoments, sethasWinMoments] = useState(false);
    const { businessDate, setbusinessDate,cuno } = useContext(HorseContext);
    const location = useLocation();
    const [Load,setLoad] = useState(true);
    useEffect(() => {
        if (lang == 'en') {
            i18n.changeLanguage('en')
            setLangCode('en')
        }
        if (lang == 'ch') {
            i18n.changeLanguage('ch')
            setLangCode('ch')
        }
        if (id != null && ownerType != null) {
            const requestData = [{
                "brandNumber": id,
                "ownerType": ownerType,
            }]
            getMyHorse({ horses: requestData }, t).then((res) => {
                const date = res?.timeOffset?.ts;
                const formatDate = dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD");
                date && setbusinessDate(formatDate);

                setData(res)
                const hasWin = res?.horse?.[0]?.horseFormRecord?.filter(i => i.winningHorse && !i.overseas)?.length > 0;
                sethasWinMoments(hasWin);
                setLoad(false)
            });

            setDummyData(DummyData[0])
        }

    }, [])

    useEffect(() => {
        console.log('data', data)
        const timer = setTimeout(() => {
            const targetId = location.hash.substring(1); // Remove the '#' symbol from the hash
            scroller.scrollTo(targetId, {
                smooth: true,
            });
        }, 0)
        return () => clearTimeout(timer)
    }, [data])

    const handleImageLoad = () => {
        setImgIsLoading(false);
    };
    const handleImageError = () => {
        setImgIsError(true);
        setImgIsLoading(false);
    };
    const getTabContentId = () => {
        let ids = []
        if (hasWinMoments) {
            ids = ['ProfileTitle', 'WinningMomentsTitle', 'FormRecordTitle', 'TrackworkRecordsTitle', 'MovementRecordTitle', 'VeterinaryRecordTitle']
        } else {
            ids = ['ProfileTitle', 'FormRecordTitle', 'TrackworkRecordsTitle', 'MovementRecordTitle', 'VeterinaryRecordTitle']
        }
        return ids;
    }

    return (
        <>
            {Load&&<LoadingOverlay/>}
            <Header title='Lbl_MyHorse' />
            <div className="Base" id="HorseProfile">
                <MenuTabs horsePropData={{ horseData: data }} tabContentId={getTabContentId()} />
                {/* <News/> */}

                {data !== null && data?.horse?.length > 0 ?
                    <>
                        
                        <NextRaceTip data={data?.horse[0]} lang={lang} futureRaceCard={data.horse[0].futureRaceCard} futureEntry={data.horse[0].futureEntry} />
                        <div className="TabletBaseContainer">
                        <div className="avatarContainer">
                            <StyledEngineProvider injectFirst>
                                <div style={{position:'relative'}}>

        
                                <Avatar
                                    id="Profile_HorseProfileImage"
                                    className="avatar"
                                    src={isImgLoading ? HorsePlaceholder : isImgError ? HorsePlaceholder : `${window.globalConfig.SITE_DOMAIN}${window.globalConfig.HORSE_IMAGE_PATH}${id}_l.jpg`}
                                    onLoad={handleImageLoad}
                                    onError={handleImageError}
                                />
                                <img id="Profile_OwnerType" src={ownerType == 'O' ? SoleOwner : (ownerType == 'P' || ownerType == 'D') ? Partnership : Syndicate} style={{ marginLeft: '6px' }} />
                                </div>
                            </StyledEngineProvider>
                        </div>
                        <div className="avatarContainer" style={{ marginBottom: 0, marginTop: 0 }}>
                            <div className="Headline/H1-Bold NameText PlayFair fontSize22" id="Profile_HorseName">
                                {lang == 'ch' ? data.horse[0].name_ch == "" ? "未命名" : data.horse[0].name_ch : data.horse[0].name_en == data.horse[0].brandNumber ? "UNNAMED" : data.horse[0].name_en}
                                {/* <Trans i18nKey="Lbl_Age">trans</Trans> */}
                                {/* <img id="Profile_OwnerType" src={ownerType == 'O' ? SoleOwner : (ownerType == 'P' || ownerType == 'D') ? Partnership : Syndicate} style={{ marginLeft: '6px' }} /> */}
                            </div>
                            <div className="Label1-Medium SubText" id="Profile_HorseBrandNo">
                                {data.horse[0].brandNumber} &nbsp;&nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="6" viewBox="0 0 3 3" fill="none">
                                    <circle cx="1.5" cy="1.5" r="1" fill="white" />
                                </svg>&nbsp;&nbsp;

                                <img src={location_while} />
                                {lang == 'ch' ? data.horse[0].stableLocation.chinese : data.horse[0].stableLocation.english}
                            </div>
                        </div>
                     
                        <HorseStat data={data?.horse[0]} lang={lang} />
                        </div>
                        <div className="Slip">
                        <ProfileSlip data={data?.horse[0]} ownerType={ownerType} lang={lang} />
                        <div className="divider-wrapper">
                            <div className="divider" />
                        </div>
                        {
                            hasWinMoments && <><WinningMoments data={data?.horse[0]?.horseFormRecord} hasWinMoments={hasWinMoments} />
                                <div className="divider-wrapper">
                                    <div className="divider" style={{ background: "none" }}/>
                                </div></>
                        }
                        <MyRecords data={data?.horse[0]?.horseFormRecord} isTitle type='horseProfile' />
                        <div className="divider-wrapper">
                            <div className="divider" style={{ background: "none" }}/>
                        </div>
                        <TrackWork data={data?.horse[0].horseTraining} />
                        <div className="divider-wrapper">
                            <div className="divider" style={{ background: "none" }}/>
                        </div>
                        <MovementRecord data={data?.horse[0].stableLocationHistories} lang={lang} />
                        <div className="divider-wrapper">
                            <div className="divider" style={{ background: "none" }}/>
                        </div>
                        <section id="vet">
                            <VeterinaryRecord data={data?.horse[0].vetRecords} lang={lang} />
                        </section>
                        </div>
                    </>
                    : <></>

                    }
            </div>
        </>
    )
}

export default HorseProfile;