import styles from '../Css/VeterinaryRecord.module.css'
import { Grid,Typography } from '@mui/material';
import moment from 'moment/moment';
import { useTranslation } from "react-i18next";

export default function VeterinaryRecordItem({item,index,lang}){
    const{t,i18n} = useTranslation();

    return(
        <Grid container mb={2} px={2} key={"VeterinaryRow"+index} >
            <Grid item container className={styles.TipRow} id = {"VeterinaryRow"+index}>
            <Grid item container>
            <   Typography class={`Label3-Medium ${styles.DateText} fontSize14`}  id = {"VeterinaryDate"+index}>{moment(item.incidentDate,"YYYY-MM-DD").format('DD/MM/YYYY')}</Typography>
            </Grid>
                   
            <Grid item py={1}>
                <Typography class="Title/Title2-Medium fontSize14" id = {"VeterinaryDetail"+index}>{lang=='ch'?item.vetDetail_ch:item.vetDetail_en}</Typography>
                {item.passedDate==null?null:
                    <div id = {"VeterinaryPassedDate"+index} > 
                        <Typography  pt={0.1} class="Title/Title2-Medium fontSize14" >{t("Lbl_PassedDate")}: {moment(item.passedDate,"YYYY-MM-DD").format('DD/MM/YYYY')}</Typography>         
                    </div>
                }
            </Grid>
            </Grid>
        </Grid>
    )
}