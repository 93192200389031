
import styles from '../Css/TrackWork.module.css'
import { Divider, Grid, Paper,Typography, Box } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import TrackCard from '../../TrackWork/Component/TrackCard';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getTCDataContent } from '../../utils'

import HorseContext from '../../utils/horseContext';
function TrackWork({data = []}){
    const { t, i18n } = useTranslation();
    const [dateContent, setDateContent] = useState([]);
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [showMore, setShowMore] = useState(true);
    const [inSevenWeekNoneData, setInSevenWeekNoneData] = useState(false);
    const navigate  = useNavigate();
    const { businessDate } = useContext(HorseContext); 
    useEffect(() => {
        const tarData = getTCDataContent(data, businessDate, true);

        if (!data || data?.length === 0) {
            setShowMore(false)
            setInSevenWeekNoneData(true)
        } else {
            setCurrentDate(businessDate)
            // console.log('[tarData[0].day==]', tarData[0].day)
            setDateContent(tarData);
        }
    }, [data])
    return(
        <div id='TW_container' className={styles.Base}>
            <div className={styles.Header}>
                <div id='TrackworkRecordsTitle'className={`${styles.Title} Title/Title1-Bold PlayFair `}>
                {t("Lbl_TrackWork")}
                </div>
                <div style={{flex:1}}/>

                {showMore && <div className={`${styles.More}`} id={"See_All_T"}  
                    onClick={()=>{
                        navigate(`/${i18n.language}/trackWork?cuno=${window.cuno}`,{state: {data:data}});
                    }}
                >
                    {t("Lbl_SeeAll")}
                </div>}
            </div>
            <Grid px={2} >
                <Grid className={styles.BoxShadow}>
                    <TrackCard
                        dateContent={dateContent}
                        inSevenWeekNoneData={inSevenWeekNoneData}
                        currentDate={currentDate}
                        sectionDisplay={true}/>
                </Grid>        
            </Grid>
        </div>
     )
}
export default TrackWork;