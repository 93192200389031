import dayjs from 'dayjs';
import moment from 'moment';
function getWinMomentSeason(data, t) {
    if (!data?.length) return {};
    const isUS = window.globalConfig.isLangUS;
    const Season = t('Lbl_WinMoment_Season');
    const dateList = data?.map(d => dayjs(d.date, 'YYYY/MM/DD'));

    const temp = data?.map(d => `${d?.season} ${Season}`);
    const seasonList = temp?.filter((item, index) => temp.indexOf(item) === index);

    const maxDate = dayjs.max(dateList).format('DD/MM/YYYY');
    const latest = data?.find(d => dayjs(d.date, 'YYYY/MM/DD').format('DD/MM/YYYY') === maxDate);

    return {
        latestSeason: `${latest?.season} ${Season}`,
        seasonList: seasonList
    }
}

function getLatestWinMoment(data, t) {
    const tempData = data.filter(i => i.winningHorse && !i.overseas);
    console.log('【----tempData】', tempData)
    const hasWinMoments = tempData.length > 0;
    if (!hasWinMoments) return [];
    
    const dateList = tempData.map(d => dayjs(d.date, 'YYYY/MM/DD'));
    const maxDate = dayjs.max(dateList).format('DD/MM/YYYY');
    const latest = tempData.find(d => dayjs(d.date, 'YYYY/MM/DD').format('DD/MM/YYYY') === maxDate);

    const tarItemData = getWMCardItemData(latest, t);

    return [tarItemData]
}

function getWinMomentCardData(data, t) {
    if (!data?.length) return [];
    let newData = [];

    newData = data?.filter(i => !i.overseas)?.map(item => {
        return getWMCardItemData(item, t);
    })

    return newData;
}

function getWMCardItemData(item, t) {
    const isUS = window.globalConfig.isLangUS;
    const Season = t('Lbl_WinMoment_Season');

    const highLightsVideoUrl = isUS ? item.videoWinningURL1200mp4?.english: item?.videoWinningURL1200mp4?.chinese;
    const highLightsVideoUrl_Mandarin = item.videoWinningURL1200mp4?.mandarin;
    const videoReplayUrl = isUS ? item?.videoReplayURL1200mp4?.english: item?.videoReplayURL1200mp4?.chinese;
    return {
        season: `${item?.season} ${Season}`,
        racetitle: isUS ? isNull(item?.raceName?.english): isNull(item?.raceName?.chinese),
        position: isUS ? isNull(item?.venue?.english): isNull(item?.venue?.chinese),
        date: dayjs(item.date, 'YYYY/MM/DD').format('DD/MM/YYYY'),

        raceClass: isUS ? isNull(item?.className?.english): isNull(item?.className?.chinese),
        distance:  item?.distance ? item?.distance + `${isUS ? 'M': '米'}` : isNull(item?.distance),
        finishTime: isNull(item?.finalTimeOfRace),
        trainer: isUS ? isNull(item?.trainer?.name_en): isNull(item?.trainer?.name_ch),
        Jockey: isUS ? isNull(item?.jockey?.name_en): isNull(item?.jockey?.name_ch),
        
        raceNo: isNull(item?.raceNo),
        course: item?.course?.code ? `"${item?.course?.code}"` : '--',
        track: isUS ? isNull(item?.track?.english) : isNull(item?.track?.chinese),
        going: isUS ? isNull(item?.going?.english) : isNull(item?.going?.chinese),
        
        highLightsVideoUrl: highLightsVideoUrl,
        highLightsVideoUrl_Mandarin: highLightsVideoUrl_Mandarin,
        videoReplayUrl: videoReplayUrl,
    }
}



function checkIfNeedAnimate(date, brandNum, businessDate) {
    const isWithin7Days = checkDateIfWithin7Days(date, businessDate);
    // 日期不在7天内，不需要烟花
    if (!isWithin7Days) return false;

    // console.log('【isWithin7Days----】', isWithin7Days)

    // 以下逻辑为日期满足7天内
    let cacheList = JSON.parse(localStorage?.getItem?.('FireWorks') || '[]');
    const tarHorse = cacheList.filter(i => i.brandNum === brandNum);
    const hasHorse = tarHorse.length > 0;
    // 有存过localstorage, 匹配到马匹
    if (cacheList.length && hasHorse) {
        const comingDate = new Date(dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD')).getTime();
        const tarDate = new Date(dayjs(tarHorse[0].date, 'DD/MM/YYYY').format('YYYY-MM-DD')).getTime();
        // coming date > tarDate，需要烟花
        if (comingDate > tarDate) {
            return true
        }
        return false;
    }
    
    // 未存过localstorage， 未匹配到马匹（新马）, 需要烟花
    return true;
}
function checkDateIfWithin7Days(date, businessDate) {
    const tarDate = dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const Within7Days = dayjs(businessDate).subtract(6, 'day').format('YYYY-MM-DD');
    // console.log('====checkDateIfWithin7Days', Within7Days, date)
    const isWithin7Days = moment(tarDate, 'YYYY-MM-DD').isSameOrAfter(Within7Days);

    return isWithin7Days;
}
function updateFireWorks(date, brandNum) {
    let cacheList = JSON.parse(localStorage?.getItem?.('FireWorks') || '[]');

    let temp = [];
    if (cacheList.length) {
        const hasHorse = cacheList.filter(i => i.brandNum === brandNum).length > 0;
        // 更新马匹时间
        if (hasHorse) {
            temp = cacheList.map(i => {
                if (i.brandNum === brandNum) {
                    // 更新时间
                    i.date = date;
                    return i
                }
    
                return i
            })
        } else {
            // 新增马匹数据
            temp = cacheList.concat([{
                date: date,
                brandNum: brandNum,
            }])
        }
        
        localStorage.setItem("FireWorks", JSON.stringify(temp));
        return ;
    }

    // 最初新增
    const newcacheList = cacheList.concat([{
        date: date,
        brandNum: brandNum,
    }])

    localStorage.setItem("FireWorks", JSON.stringify(newcacheList));
}
function clearFireWorks() {

}

function isNull(str) {
    if ([null, undefined, '', -1].includes(str)) return '--'
    return str
}

function isEmpty(str) {
    return [null, undefined, '', -1].includes(str)
}


export {
    getLatestWinMoment,
    getWinMomentCardData,
    getWinMomentSeason,
    isNull,
    isEmpty,
    checkIfNeedAnimate,
    updateFireWorks,
}