import "../Css/OwnerProfile.css"
import SoleOwner from '../../img/Owner.svg'
import Partnership from '../../img/partnership.svg'
import Syndicate from '../../img/syndicate.svg'
import HorsePlaceholder from '../../img/ProfileHorsePlaceholder.svg'
import { Avatar } from "@mui/material";
import { useEffect, useState } from "react"
function HorseAvatar({item,index,lang,OwnerType}){
    const[isImgLoading,setImgIsLoading] = useState(true);
    const[isImgError,setImgIsError] = useState(false);
    useEffect(()=>{
    
    },[])

    const handleImageLoad = () => {
        setImgIsLoading(false);
    };
    const handleImageError = () => {
        setImgIsError(true);
        setImgIsLoading(false);
    };
    return( 
        <div className="my-horse-item">
        <div className="my-horse-avatar">
            <Avatar className="item-avatar"  id = {"HorseAvatar"+item.brandNumber}
                src={isImgLoading?HorsePlaceholder:isImgError?HorsePlaceholder: `${window.globalConfig.SITE_DOMAIN}${window.globalConfig.HORSE_IMAGE_PATH}${item.brandNumber}_l.jpg`} 
                onLoad = {handleImageLoad}
                onError={handleImageError}
            />
            <img id = {"HorseAvatar_OwnerType"+item.brandNumber} src = {item.ownerType=='O'?SoleOwner:(item.ownerType=='P'||item.ownerType=='D')?Partnership:Syndicate}/>
        </div>
       
        <div className="item-bottom-content">
            <p  className="fontSize14 lineHeight20" id={"HorseAvatar_Name"+item.brandNumber}>  {lang=='ch'?item.name_ch==""?"未命名":item.name_ch:item.name_en==item.brandNumber?"UNNAMED":item.name_en}
                {/* <img id = {"HorseAvatar_OwnerType"+item.brandNumber} src = {item.ownerType=='O'?SoleOwner:(item.ownerType=='P'||item.ownerType=='D')?Partnership:Syndicate} style={{top:'0.125rem',marginLeft:'4px',position:'relative'}}/> */}
            </p>
           
        </div>
    </div>
)

}
export default HorseAvatar