import { useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import { getSplitTrackIcon } from '../../utils/matchMCLContent';
import HVPoint from './HV.json';
import STPoint from './ST.json';

const color_Pink = 'rgb(215, 77, 218)';
const color_blue = 'rgb(10, 190, 249)';
const color_while = 'rgb(255, 255, 255)';

const midScreenWidth = 480;
const largeScreenWidth = 744;

const isMiddleScreen = window.innerWidth >= midScreenWidth && window.innerWidth < largeScreenWidth
const isLargeScreen = window.innerWidth >= largeScreenWidth;

const TrackingData = {
    0: [{
      horseName: "Joyful Hunter",
      rail: 30.50,     // m
    },
    {
      horseName: "Modest Gentleman",
      rail: 21.16,
    }
    ],
    200: [{
      horseName: "Joyful Hunter",
      rail: 20.50,
    },
    {
      horseName: "Modest Gentleman",
      rail: 12.29,
    }
    ]
}

const SplitRace = (props) => {
  const { 
    brandNumber,
    distance,
    trackType,
    railData,
    currentDis } = props;

  return (
    <div style={{
      // overflow: 'hidden'
    }}>
        <SplitCanvas
          brandNumber={brandNumber}
          railData={railData}
          distance={distance}
          trackType={trackType}
          currentDis={currentDis} />
    </div>
  );
}

function getClostestPoint(endPos, whilePos, td) {
  const diffX = endPos[0] - whilePos[0];
  const diffY = endPos[1] - whilePos[1];
  const xLen = Math.abs(diffX);
  const yLen =  Math.abs(diffY);
  const a = Math.atan(xLen/yLen); // 角度

  let winPosX, winPosY;
  
  const { railScale } = getBasicInfo()

  const len = railScale * td[0].rail;
  winPosX = diffX > 0 ? endPos[0] - Math.sin(a)*len : endPos[0] + Math.sin(a)*len;
  winPosY = diffY > 0 ? endPos[1] - Math.cos(a)*len : endPos[1] + Math.cos(a)*len;
  // debugger
  return [winPosX, winPosY]
}

const renderPointLine = (canvasRef, currentDis, railData, trackType, brandNumber) => {
  const cnv = canvasRef?.current;
  const ctx = cnv?.getContext('2d');
  const coordinatesConfig =  getCoordinates(currentDis, trackType, cnv);
  const trackData = railData[currentDis].sort((a,b) => a.rail - b.rail);

  const { edgePoint, whilePoint, mode } = coordinatesConfig;

  const winPoint = getClostestPoint(edgePoint, whilePoint, trackData);

  const winPos = {
    x: winPoint?.[0],
    y: winPoint?.[1],
  }

  const endPos = {
    x: edgePoint?.[0],
    y: edgePoint?.[1],
  }
  // 渲染分段线段
  renderSplitPointLine(ctx, winPos, endPos, trackData, mode, brandNumber);
}
function getDiffRail(td, r) {
  return Math.abs(td.reduce((a, b) => a.rail - b.rail)*r)
}
const renderSplitPointLine = (ctx, winPos, endPos, trackData, mode, brandNumber) => {
  const diffX = endPos.x - winPos.x;
  const diffY = endPos.y - winPos.y;
  const xLen = Math.abs(diffX);
  const yLen =  Math.abs(diffY);
  const a = Math.atan(xLen/yLen); // 角度
  
  const {
    lineToPointLen, 
    roundRadiu,
    railScale,
    lineWidth,
    rectWidth,
    rectHeight,
    rect_offsetX,
    rect_offsetY,
  } = getBasicInfo(mode)

  const pointToPointDis = getDiffRail(trackData, railScale);
  
  const tempWins = Object.assign({}, winPos);
  console.log('[trackData------]', trackData, brandNumber)
  trackData.forEach((data, idx) => {
    const isOwnerHorse = data.horseName === brandNumber;
    
    const topRightToBottomLeft = (diffX > 0 && diffY < 0) || (diffX < 0 && diffY > 0);
    let endOffsetPos,
        winOffsetPos,
        newEndPosX,
        newEndPosY,
        newWinPosX,
        newWinPosY;

    switch (isOwnerHorse) {
      case true:
        newEndPosY = topRightToBottomLeft ? endPos.y - lineToPointLen*Math.sin(a) : endPos.y + lineToPointLen*Math.sin(a);
        newWinPosY = topRightToBottomLeft ? tempWins.y - lineToPointLen*Math.sin(a) : tempWins.y + lineToPointLen*Math.sin(a);
        
        endOffsetPos = [
          Math.floor( endPos.x - lineToPointLen*Math.cos(a)),
          Math.floor( newEndPosY )
        ];
        winOffsetPos = [
          Math.floor( tempWins.x - lineToPointLen*Math.cos(a)),
          Math.floor( newWinPosY )
        ];

        break;
      case false:
        newEndPosY = topRightToBottomLeft ? endPos.y + lineToPointLen*Math.sin(a) : endPos.y - lineToPointLen*Math.sin(a);
        newWinPosY = topRightToBottomLeft ? tempWins.y + lineToPointLen*Math.sin(a) : tempWins.y - lineToPointLen*Math.sin(a);

        endOffsetPos = [
          Math.floor( endPos.x + lineToPointLen*Math.cos(a)),
          Math.floor( newEndPosY)
        ];
        winOffsetPos = [
          Math.floor( tempWins.x + lineToPointLen*Math.cos(a)),
          Math.floor( newWinPosY)
        ];
        break;
      default:
        break;
    }
    /**画线 */
    ctx.beginPath();
    ctx.moveTo(tempWins.x, tempWins.y);
    ctx.lineTo(winOffsetPos[0], winOffsetPos[1]);
    ctx.lineTo(endOffsetPos[0], endOffsetPos[1]);
    
    const edgePos = getNewEndPosPoint(endPos, endOffsetPos, a);
    console.log('[edgePos--]', edgePos, endOffsetPos)
    ctx.lineTo(edgePos.x, edgePos.y);

    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = !isOwnerHorse ? color_Pink: color_blue;
    ctx.stroke();
    setShadow(ctx);
    ctx.closePath();

     /**画点 */
    ctx.beginPath();
    ctx.arc(tempWins.x, tempWins.y, roundRadiu, 0, Math.PI * 2);
    ctx.fillStyle = !isOwnerHorse ? color_Pink: color_blue;
    ctx.fill();
    setShadow(ctx);
    ctx.closePath();

     /** 画框*/
    const newRectX = !isOwnerHorse ? tempWins.x + rect_offsetX : tempWins.x - rectWidth - rect_offsetX ;
    const newRectY = !isOwnerHorse ? tempWins.y - rect_offsetY - rectHeight : tempWins.y + rect_offsetY;
    
    ctx.beginPath();
    // fix SQ0476-156 
    if (ctx.roundRect) {
      ctx.roundRect(newRectX, newRectY, rectWidth, rectHeight, [4])
    } else {
      ctx.fillRect(newRectX, newRectY, rectWidth, rectHeight)
    }

    setShadow(ctx);
    ctx.fillStyle = !isOwnerHorse ? color_Pink : color_blue;
    ctx.fill();
    ctx.closePath();

    /** 写rail数据*/ 
    ctx.beginPath();
    ctx.fillStyle = color_while;
    ctx.textAlign = 'center'; 
    ctx.textBaseline = 'middle'; 

    let fontSize = '11px Roboto';
    if (isMiddleScreen) fontSize = '16px Roboto';
    if (isLargeScreen) fontSize = '22px Roboto';
    
    ctx.font = fontSize;
    clearShadow(ctx);
    const newRailX = newRectX + rectWidth/2;
    const newRailY = newRectY + rectHeight/2;

    const unit = window.globalConfig.isLangUS ? 'M' : '米';
    ctx.fillText(data.rail + unit, newRailX, newRailY);
    ctx.closePath();

    // 点位置更新
    tempWins.x = diffX > 0 ? tempWins.x - pointToPointDis*Math.sin(a) : tempWins.x + pointToPointDis*Math.sin(a);
    tempWins.y = diffY > 0 ? tempWins.y - pointToPointDis*Math.cos(a) : tempWins.y + pointToPointDis*Math.cos(a);
  });
}

/**
 * @param currentDis 0, 200, 400, 600...2200
 * @param trackType 'ST_TURF' || 'ST_AWT' || 'HV'
 * @param railData { 
 *  "200": [{
 *    horseName: "Joyful Hunter",
      rail: 1.80 
    },{
      horseName: "Joyful Hunter2",
      rail: 1.90 
      }
 * @returns canvas
 */
const SplitCanvas = (props) => {
  const { 
    brandNumber,
    distance,
    currentDis, 
    trackType = 'HV',
    railData} = props;

  const canvasRef = useRef(null);
  const canvasWidth = window.innerWidth - 64;
  const [canvasHeight, setcanvasHeight] = useState(window.innerHeight)
  useEffect(() => {
    // 319/218
    // 图像宽高比
    const aspectRatio = 319/218;
    setcanvasHeight(canvasWidth/aspectRatio);
  }, [])


  useEffect(() => {
    const cnv = canvasRef?.current;
    const ctx = cnv?.getContext('2d')
    // reset HW to clear 
    cnv.height = canvasHeight
    cnv.width = canvasWidth

    console.log('【railData----】', railData, distance)
    const renderAfter = () => {
      renderPointLine(canvasRef, currentDis, railData, trackType, brandNumber)
    }
    renderBGTrack(canvasRef, trackType, currentDis, renderAfter, distance)
  }, [currentDis, canvasHeight, railData]) 

  return <React.Fragment>
      <canvas
        ref={canvasRef}
        id="moclLoyCanvas"
        width={canvasWidth}
        height={canvasHeight}
        // style={{ border: "1px solid #ccc" }}
    ></canvas>
  </React.Fragment>
}

const renderBGTrack = (canvasRef, trackType, currentDis, cb, distance) => {
  const cnv = canvasRef?.current;
  const ctx = cnv?.getContext('2d')

  const bg_Image = new Image()
  bg_Image.src = getSplitTrackIcon(currentDis, trackType, distance) ;
  bg_Image.onload = function(){

    const aspectRatio = this.width / this.height;
    const newWidth = cnv.width;
    const newHeight = newWidth/aspectRatio

    ctx.drawImage(bg_Image, 0, 0, newWidth, newHeight);

    cb && cb();
  }
}

function getNewEndPosPoint(endPos , endOffsetPos, angle) {
  const { lineToPointLen } = getBasicInfo();
  const len = lineToPointLen*2/3;

  let newX, newY;
  newX = endPos.x > endOffsetPos[0] ?
    endOffsetPos[0] + len*Math.cos(angle):
    endOffsetPos[0] - len*Math.cos(angle)

  newY = endPos.y > endOffsetPos[1] ?
    endOffsetPos[1] + len*Math.sin(angle):
    endOffsetPos[1] - len*Math.sin(angle)
  
  return {
    x: newX,
    y: newY,
  }
}
// 屏幕宽度放大比例
function calcResponseRailScale(railScale){
  // 319/218
  const canvasDom = document.querySelector('#moclLoyCanvas');
  return canvasDom.width*railScale/319;
}
function getBasicInfo(mode) {
  let basicInfo = {
    lineToPointLen: 10,  // 线点距离
    roundRadiu: 7,       // 圆点半径
    railScale: 3.8,       // rail 放大比例
    lineWidth: 3,        // 线粗宽

    rectWidth: 55,       // 框宽
    rectHeight: 21,      // 框高

    rect_offsetX: 15,    // 框X 偏移
    rect_offsetY: 15,    // 框Y 偏移
  }

  if (isMiddleScreen) {
    Object.assign(basicInfo, {
      lineToPointLen: 15,  
      roundRadiu: 8,   

      lineWidth: 4,   

      rectWidth: 80,      
      rectHeight: 35,

      rect_offsetX: 20,
      rect_offsetY: 25,        
    })
  } else if (isLargeScreen) {
    Object.assign(basicInfo, {
      lineToPointLen: 20,  
      roundRadiu: 10,   

      lineWidth: 6,     

      rectWidth: 100,      
      rectHeight: 45,

      rect_offsetX: 30, 
      rect_offsetY: 30,       
    })
  }


  switch (mode) {
    case 'horizontal':
      Object.assign(basicInfo,{
        rect_offsetX: -10,
        rect_offsetY: 25,   
      })

      if (isMiddleScreen) {
        Object.assign(basicInfo, {
          rect_offsetX: -20,
          rect_offsetY: 35,        
        })
      } else if (isLargeScreen) {
        Object.assign(basicInfo, {   
          rect_offsetX: -20,
          rect_offsetY: 40,     
        })
      }
    
      break;
    case 'vertical':
      Object.assign(basicInfo,{
        rect_offsetX: 30,
        rect_offsetY: -15,   
      })

      if (isMiddleScreen) {
        Object.assign(basicInfo, {
          rect_offsetX: 30,
          rect_offsetY: -25,        
        })
      } else if (isLargeScreen) {
        Object.assign(basicInfo, {   
          rect_offsetX: 50,
          rect_offsetY: -30,     
        })
      }
      break;
    case 'reverse':
      Object.assign(basicInfo,{
        rect_offsetX: -60,
        rect_offsetY: 25,   
      })

      if (isMiddleScreen) {
        Object.assign(basicInfo, {
          rect_offsetX: -80,
          rect_offsetY: 35,        
        })
      } else if (isLargeScreen) {
        Object.assign(basicInfo, {   
          rect_offsetX: -100,
          rect_offsetY: 40,     
        })
      }
      
      break;
    default:
      break;
  }
  return Object.assign(basicInfo, {
    railScale : calcResponseRailScale(basicInfo.railScale)}
  )

}
/**
 * 配置不同场地，不是分段距离时的各个坐标
 * edgePoint（边缘点 坐标)
 * whilePoint（白线上任意坐标 用于计算角度)
 * @param currentDis 0, 200, 400, 600...2200
 * @param trackType 'ST_TURF' || 'ST_AWT' || 'HV'
 * @param cnv 
 * @returns 
 */
function getCoordinates(currentDis, trackType, cnv) {
  // 计算图片拉伸后的偏移坐标 x, y
  const calcResponseWH = (config = {}) => {
    const {edgePoint = [], whilePoint = [], mode = ""} = config;
    return Object.assign({}, {
      edgePoint : [(edgePoint?.[0]/319) * cnv.width, (edgePoint?.[1]/218) * cnv.height], 
      whilePoint : [(whilePoint?.[0]/319) * cnv.width, (whilePoint?.[1]/218) * cnv.height], 
      mode,
    }) 
  }
  console.log('-------- trackType', trackType)
  switch (trackType) {
    case 'HV':
      return calcResponseWH(HVPoint[currentDis]) 
    case 'ST_AWT':
      return calcResponseWH(STPoint['AWT'][currentDis])
    case 'ST_TURF':
      return calcResponseWH(STPoint['TURF'][currentDis])
    default:
      return {
        edgePoint: [0,0],
        whilePoint: [0,0],
        mode: ''
      }
  }
  
}

function clearShadow(ctx) {
  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 0;
  ctx.shadowBlur = 0;
}

function setShadow(ctx) {
  ctx.shadowColor = "#595959"
  ctx.shadowOffsetX = 2;
  ctx.shadowOffsetY = 2;
  ctx.shadowBlur = 5;
}

export default SplitRace