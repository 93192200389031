
import { 
    Grid, 
} from '@mui/material';
import { useState, useEffect } from 'react';
import '../Css/RDCompare.css'
import { formatNum } from '../utils'
import { useTranslation } from "react-i18next";
import { isValidVenueCode } from '../utils';

const RDCompare = (props) => {
    const { data, mCLoydButtom} = props;
    const { t, i18n } = useTranslation();

    console.log('【runnerResults-----】', data)

    const ownerHorseRD = data?.runnerResults?.find(i => i?.horseName?.code === data?.brandNumber)?.runningDistance || 0;

    const vCode = data?.venue?.code || data?.venueCode;

     const hasResults = data?.runnerResults?.length > 0 && isValidVenueCode(vCode);
    return (
        <>
            <Grid container className='RDCompare-root' style={{ height : hasResults ? "unset" : "100%"}}>
                {
                    hasResults ? <>
                        <RDColunm />
                        <RDTable 
                            runnerResults={data?.runnerResults} 
                            brandNumber={data?.brandNumber}
                            ownerHorseRD={ownerHorseRD}/>

                        <div className='CB-square-container' id="RDmyhorse">
                            <div className='CB-square' id="squareColor_myhorse" />
                            <div style={{ textAlign: 'left', width: 200}}>{t('Lbl_RD_MyHorse')}</div>

                        </div>
                        
                    </> : <div className="RDCompare-noData"> 
                        {t('Lbl_RunningDistanceNoData')}
                    </div>
                }
               
            </Grid>
            {hasResults && <div className='CB-square-Reminder' id="RDReminder">{t('Lbl_RD_Reminder')}</div>}
            {hasResults && mCLoydButtom}
        </>
    )
}

const RDColunm = (props) => {
    const { t, i18n } = useTranslation();
    const column = [
    { 
        title: t('Lbl_RD_Pla'),
        xs: 1,
        style: {
            textAlign: 'left',
        }
    }, 
    { 
        title: t('Lbl_RD_Draw'),
        xs: 1,
        style: {
            textAlign: 'left',
        }
    }, 
    { 
        title: t('Lbl_RD_Horse'),
        xs: 3,
        style: {
            textAlign: 'left',
            paddingLeft: 9,
        }
    }, 
    { 
        title: t('Lbl_RD_RunningDistance'),
        xs: 7,
        style: {
            textAlign: 'center',
        }
    }
    ]


    return (
        <Grid container RDColunm className='RDColunm'>
            {
                column.map(i => <Grid item xs={i.xs} style={i.style}>
                    {i.title}
                </Grid>)
            }
        </Grid>
    )
}


const RDTable = (props) => {
    const { runnerResults = [], brandNumber, ownerHorseRD } = props;
    const [ data, setData] = useState([])
    const dataSource = [{
        pla: 3,
        draw: 9,
        horseName: 'GOLDEN SAMURAI',
        RD: 0,
        isOwnerHorse: true,
        isNonRD: true,
    }]
    const highLightStyle = (item, needBoldFont) => ({
        color: item?.isOwnerHorse ? "#0ABEF9":"",
        maxHeight: 30,
        fontWeight: item?.isOwnerHorse || needBoldFont? 400 : 400
    })

    useEffect(() => {
        const isUs = window.globalConfig.isLangUS;
        const temp = runnerResults?.filter(i => i?.placeNo > 0)?.map(i => {
            const isOwnerHorse = brandNumber === i?.horseName?.code;
            return {
                pla: i?.placeNo == -1 ? '--': parseInt(i?.placeNo),
                draw: i?.barrierDrNo == -1 ? '--': i?.barrierDrNo,
                horseName: isUs ? i?.horseName?.english: i?.horseName?.chinese,
                RD: i?.runningDistance - ownerHorseRD,
                isOwnerHorse: isOwnerHorse,
                isNonRD : !isOwnerHorse && [0, -1, '-1'].includes(i?.runningDistance),
            }
        })

        const ownerHorse = temp?.find(i => i.isOwnerHorse);
        const sortlist = temp.filter(i => !i.isOwnerHorse).sort((a, b) => a.pla - b.pla);
        if (ownerHorse) {
            sortlist.unshift(ownerHorse);
        }

        setData(sortlist);
    }, [runnerResults])
    return  data?.map((i, idx) => {
            return (
                
                <Grid container className='RDTableRow' id={`RDTableRow_${idx}`}>
                    <Grid item xs={1} textAlign="left" paddingLeft="5px" style={highLightStyle(i)}>
                        {i?.pla}
                    </Grid>
                    <Grid item xs={1} textAlign="left" paddingLeft="5px" style={highLightStyle(i)}>
                        {i?.draw}
                    </Grid>
                    <Grid item xs={3} textAlign="left" paddingLeft="9px" style={highLightStyle(i, true)} className='RDTableRow-HorseName'>
                        {i?.horseName}
                    </Grid>
                    <Grid item xs={7}>
                        <ColorBar data={i}/>
                    </Grid>
                </Grid>
                )
            })
}

const ColorBar = (props) => {
    const { data: { RD, isNonRD} } = props;

    const renderRight = RD > 0;
    const renderLeft = RD < 0;


    const bGIsDeadStyle = (d) => ({ background: d ? 'rgba(224, 224, 224, 1)': ''})
    const dataBarStyle = (rd) => ({ width: `calc(100% * ${Math.abs(rd)}/10)`})

    const numCalcStyle = (rd) => {
        if (rd === 0 || !rd) return {}

        if (rd > 4.80 && rd < 10) {
            return {
                left: `calc(100% * ${RD}/10 + 2px - 40px)`,
                color: '#fff'
            }
        } else if (rd > 0 && rd <= 4.80) {
            return {
                left: `calc(100% * ${RD}/10 + 2px )`,
               
            }
        } else if (rd < 0 && rd >= -4.80) {
            return {
                right: `calc(100% * ${Math.abs(RD)}/10 + 5px)`,
            }
        } else if (rd < - 4.80 && rd > -10) { 
            return {
                right: `calc(100% * ${Math.abs(RD)}/10 - 35px)`,
                color: '#fff'
            }
        } else if (rd <= -10){ 
            return {
                right: `calc(100% - 40px)`,
                color: '#fff'
            }
        } else if (rd >= 10){ 
            return {
                left: `calc(100% - 40px)`,
                color: '#fff'
            }
        }
    }
    const formatRD = RD == 0 ? '0': formatNum(RD);

    return <div className='CB-mask'>
        <div className='CB-item CB-item-left'>
            <div className='CB-lineblue CB-lineblue-left' 
             style={bGIsDeadStyle(isNonRD)}
            ></div>

            { renderLeft && !isNonRD && <><div className='CB-dataBar-left'
                style={dataBarStyle(RD < -10 ? -10 : RD)}/>
                    <div className='CB-dataBar-leftNumber'
                    style={numCalcStyle(RD)}>{formatRD}</div>
                </>}
        </div>

        <div className='CB-item'>
            <div className='CB-lineblue CB-lineblue-right'
             style={bGIsDeadStyle(isNonRD)}
            ></div>

            
            {renderRight && !isNonRD && <><div className='CB-dataBar-right'
                    style={dataBarStyle(RD > 10 ? 10 : RD)}/>
                    <div className='CB-dataBar-rightNumber'
                    style={numCalcStyle(RD)}>+{formatRD}</div>
            </>}
        </div>
    </div>
}

export default RDCompare;