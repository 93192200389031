import { Divider, Grid, Paper,Typography } from '@mui/material';
import { useState } from 'react';
import styles from '../Css/ProfileSlip.module.css'
import card from '../../img/Card.svg'
import { useTranslation, Trans } from "react-i18next";
import TrainerProfile from '../../TrainerProfile/TrainerProfile.js'
import NoSilk from '../../img/EmptySilk.png'
import NoCard from '../../img/EmptyCard.png'
import Overseas from '../../img/overseas.svg'
import ReplayIcon from '../../img/Trackwork/play.svg';
import { DownloadForm, openDeviceRotation } from '../../utils'
import moment from 'moment';
import VideoDialog from '../../Component/VideoDialog';

function ProfileSlip({data,lang,ownerType}){
    const{t,i18n} = useTranslation();
    const [showDetail,setShowDetail] = useState(false)
    const [showTrainer,setShowTrainer] = useState(false);
    const [silkErr,setIsSilkErr] = useState (false)
    const [open, setOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const isPP = data?.importType?.code === 'PP';
    const handleOverSeaVideo = (item) => {
        openDeviceRotation(true);
        setVideoUrl(item.videoPreImportURL1200mp4);
        setOpen(true);
    }
    const handleOverSeaPDF = (item) => {
        const isUS = window.globalConfig.isLangUS;

        const pdfUrl = isUS ? item?.pdfOverseasRecordsUrl?.english
        : item?.pdfOverseasRecordsUrl?.chinese
        DownloadForm(pdfUrl);
    }
    return(
       
        <div className={styles.Base}>
            {data!==null?
           <div className={styles.Content}>
               <div id='ProfileTitle' className={`Title/Title1-Bold ${styles.Header} PlayFair`}>{t('Lbl_Profile')}</div>
               {/* {showTrainer && <TrainerProfile setShowTrainer = {setShowTrainer} data={data} showTrainer={showTrainer}/>} */}

                <Grid container my={2}>
                    <Grid item xs={3}  className={styles.AvatarContainer}  mb={2} >
                        {data.brandNumber!=""?
                        <div className = {styles.Card} style={{backgroundImage:`url(${!silkErr?card:NoCard})`}}>
                            <img src={!silkErr?`${window.globalConfig.SITE_DOMAIN}/content/racing/content/Images/RaceColor/${data.brandNumber}.gif`:NoSilk} id="Profile_SilkColor" className={styles.Avatar}  onError={()=>{setIsSilkErr(true)}}/> 
                        </div>         
                        :
                        <div className = {styles.Card} style={{backgroundImage:`url(${NoCard})`}}>
                            <img src={NoSilk} id="Profile_SilkColor" className={styles.Avatar} /> 
                        </div> 
                        }
                    </Grid>
                    <Grid item xs={9} container spacing={0}  >
                        <Grid item xs={6} mb={2} pr={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t("Lbl_Age")}</Typography>
                            <Typography className={`Label1-Bold ${styles.GridValue}`} id="Profile_HorseAge">{data.age}</Typography>
                        </Grid>
                        <Grid item xs={6} mb={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t("Lbl_Trainer")}</Typography>
                            <Typography className={`Label1-Bold ${styles.GridValue}`} id="Profile_Trainer">{lang=='ch'?data?.trainer?.name_ch:data?.trainer?.name_en}
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none" className={styles.InfoIcon}>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99999 1.66669C4.78349 1.66669 2.16666 4.28349 2.16666 7.49994C2.16666 10.7166 4.78349 13.3334 7.99999 13.3334C11.2165 13.3334 13.8333 10.7166 13.8333 7.49994C13.8333 4.28349 11.2165 1.66669 7.99999 1.66669ZM7.99991 14.8334C3.95639 14.8334 0.666656 11.5436 0.666656 7.49994C0.666656 3.45642 3.95639 0.166687 7.99991 0.166687C12.0434 0.166687 15.3332 3.45642 15.3332 7.49994C15.3332 11.5436 12.0434 14.8334 7.99991 14.8334Z" fill="#8B8B8B"/>
                                <rect x="7.24799" y="5.7608" width="1.31626" height="6.25226" rx="0.658132" fill="#8B8B8B"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.90611 4.90201C8.45152 4.90201 8.89331 4.46004 8.89331 3.91493C8.89331 3.36958 8.45152 2.92761 7.90611 2.92761C7.3607 2.92761 6.91891 3.36958 6.91891 3.91493C6.91891 4.46004 7.3607 4.90201 7.90611 4.90201Z" fill="#8B8B8B"/>
                            </svg> */}
                            </Typography>
                        </Grid>
                        {ownerType!="O"?
                        <Grid item xs={12} mb={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t("Lbl_Owner")}</Typography>
                            <Typography className={`Label1-Bold ${styles.GridValue}`}id="Profile_Owner">{lang=='ch'?data.ownerName.chinese:data.ownerName.english}</Typography>
                        </Grid>
                        :null}
                        <Grid item xs={6} mb={2} pr={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}> {t("Lbl_CountryOfOrigin")}</Typography>
                            <Typography className={`Label1-Bold ${styles.GridValue}`} id="Profile_Country">{lang=='ch'?data.countryOfOrigin.chinese:data.countryOfOrigin.code}</Typography>
                        </Grid>
                        <Grid item xs={6} mb={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t("Lbl_Colour")} / {t("Lbl_Sex")}</Typography>
                            <Typography className={`Label1-Bold ${styles.GridValue}`} id="Profile_HorseColor">{lang=='ch'?data.colour.chinese :data.colour.english} / {lang=='ch'?data.gender.chinese:data.gender.english} </Typography>
                        </Grid>
                        <Grid item xs={6} mb={2} pr={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t("Lbl_ImportType")}</Typography>
                            <Typography className={`Label1-Bold ${styles.GridValue}`} id="Profile_ImportType" >{lang=='ch'? (data.importType.chinese || '--'): (data.importType.english || data.importType.code || '--')}</Typography>
                        </Grid>
                        {/* <Grid item xs={6} mb={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>No. of 1-2-3-Starts*</Typography>
                            <div style={{display:'flex',flex:1}}>
                                <Typography  className={`Label1-Bold`} style={{textAlign:'center'}}>25 
                                    <div className={styles.Gold}/>
                                </Typography>
                                <Typography className={`Label1-Bold`}> &nbsp;/&nbsp;
                                </Typography>
                                <Typography className={`Label1-Bold`} style={{textAlign:'center'}}>2 
                                    <div className={styles.Sliver}/>
                                </Typography>
                                <Typography className={`Label1-Bold`}>&nbsp;/&nbsp;</Typography>
                                <Typography className={`Label1-Bold`} style={{textAlign:'center'}}>1 
                                    <div className={styles.Bronze}/>
                                </Typography>
                                <Typography className={`Label1-Bold`}> &nbsp;/&nbsp;</Typography>
                                <Typography className={`Label1-Bold`} style={{textAlign:'center'}}>29 
                                    <div className={styles.Blue}/>
                                </Typography>
                            </div>
      
               
                        </Grid> */}
                        <Grid item xs={6} mb={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_ImportDate')}</Typography>
                            <Typography className={`Label1-Bold ${styles.GridValue}`}id="Profile_HorseImportDate" >{moment(data.horseImportDate, 'YYYY-MM-DD').format("DD/MM/YYYY")}</Typography>
                        </Grid>

                        {isPP && <>
                        <Grid textAlign='left' item xs={6} mb={2} pr={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_OverseasFormrecords')}</Typography>
                            <Typography className={`Label1-Bold`}>
                            {data.pdfOverseasRecordsUrl&&((lang=='ch'&&data.pdfOverseasRecordsUrl.chinese!="")||(lang=='en'&&data.pdfOverseasRecordsUrl.english!=""))?
                            <img id='Profile_OverseasPDF'  alt=''
                                src={Overseas}
                                onClick={() => handleOverSeaPDF(data)}/> :'--'}</Typography>
                        </Grid>
                        </>
                        }
                        {isPP&&<>
        
                        <Grid textAlign='left' item xs={6} mb={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_PreImportRacesFootage')}</Typography>
                            <Typography className={`Label1-Bold`} id="Profile_OverSea" >
                                {data.videoPreImportURL1200mp4?
                                <img
                                    id='Profile_PreImportReplay'
                                    width={22}
                                    height={22} alt=''
                                    onClick={() => handleOverSeaVideo(data)} src={ReplayIcon}/>:'--'}</Typography>
                        </Grid></> }
                        
                        {/* <Grid textAlign='left' item xs={6} mb={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_PreImportRacesFootage')}</Typography>
                            <Typography id='Profile_NoPreImportReplay' className={`Label1-Bold`}>-</Typography>
                        </Grid> */}
                       <Grid item xs={6} mb={2} pr={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`} >{t('Lbl_Sire')} </Typography>
                            <Typography className={`Label1-Bold ${styles.GridValue}`} id="Profile_Sire">{data.sire}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('Lbl_Dam')}</Typography>
                            <Typography className={`Label1-Bold ${styles.GridValue}`} id="Profile_Dam" >{data.dam}</Typography>
                        </Grid>
                        <Grid item xs={6} pr={2}>
                            <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t('LbL_DamSire')}</Typography>
                            <Typography className={`Label1-Bold ${styles.GridValue}`} id="Profile_HorseDamSire">{data.sireOfDam}</Typography>
                        </Grid>
                    </Grid>
                    
                </Grid>
                {/* <div className={styles.ShowButton} id="MoreButton" onClick={()=>{setShowDetail(!showDetail)}}> 
                    {!showDetail&&<>
                        <Typography className={styles.ShowTitle}>{t('Lbl_More')}</Typography>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1925 11.6925C17.6025 11.2824 17.6025 10.6176 17.1925 10.2075C16.7824 9.79749 16.1176 9.79749 15.7075 10.2075L12.45 13.4651L9.19246 10.2075C8.78241 9.79749 8.11759 9.79749 7.70754 10.2075C7.29749 10.6176 7.29749 11.2824 7.70754 11.6925L11.7075 15.6925C12.1176 16.1025 12.7824 16.1025 13.1925 15.6925L17.1925 11.6925Z" fill="black"/>
                        </svg>
                    </>}


                   { showDetail&&<>
                        <Typography className={styles.ShowTitle}>{t('Lbl_Less')}</Typography>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1925 13.3075C17.6025 13.7176 17.6025 14.3824 17.1925 14.7925C16.7824 15.2025 16.1176 15.2025 15.7075 14.7925L12.45 11.5349L9.19246 14.7925C8.78241 15.2025 8.11759 15.2025 7.70754 14.7925C7.29749 14.3824 7.29749 13.7176 7.70754 13.3075L11.7075 9.30754C12.1176 8.89749 12.7824 8.89749 13.1925 9.30754L17.1925 13.3075Z" fill="black"/>
                        </svg>
                    </>}
                </div> */}
          
           </div>

            :<></>} 

            <VideoDialog open={open} onClose={() => {
                openDeviceRotation(false);
                setOpen(false);
            }} videoUrl={videoUrl}/>
        </div>

    )
    
}

export default ProfileSlip;