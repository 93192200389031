import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useLayoutEffect,useRef } from "react";
import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Grid } from '@mui/material';
import styles from './SectionalSpeedChart.module.css'
import { useState } from "react";
import i18n from "../i18n";
import { isEmpty } from '../utils';

am4core.useTheme(am4themes_animated);    
am4core.options.commercialLicense = true;

const findMaxKph = (list) => {
    let max = 0;

    for (const item of list) {
        for (const key in item) {
            if(![Infinity, NaN].includes(Number(item[key])) && max < Number(item[key])) {
                max = Number(item[key])
            }
        }
    }
    return max
}

//3.6 x distance (m) / time (s)
function SectionalSpeedChart(props){
    const { mCLoydButtom } = props;
    const { t, i18n } = useTranslation();
    const [data,setData] = useState(false)  
    console.log('props.data',props.data)
    const horseColor = "#0ABEF9";
    const horseColor2 = "#0A3496";
    const winningHorseColor = "#D74DDA"
    const winningHorseColor2 = "#B600C5"
    const winningHorseColor3 = "#7407FE"
    const secHorseColor = "#0FBD53"
    const secHorseColor2 = "#058035"
    const secHorseColor3 = "#086231"
    let horseCount = 0;
    const [filterData,setFilterData] = useState([]) 
    const [secFilterData,setSecFilterData] = useState([])
    const calKph = (distance,time)=>{
        return (3.6*distance/time).toFixed(2)
    }

    const meterText = window.globalConfig.isLangUS ? 'M' : '米';

    useEffect(()=>{
        if(
            props.data.sectionDistanceSplit?.length &&
            props.data.sectionTimeSplits?.length && 
            props.data.runnerResults?.length
        ){
            const distanceSplit = props.data?.sectionDistanceSplit?.split(",");
            let chartData=[];
            //case 1 self place 01 and no other place 01
            let filter = props.data.runnerResults.filter(item=> !isEmpty(item.placeNo) && item.placeNo=="01"&&item.id!=props.data.id)
            console.log(props.data.id,'props.data.id')
            //if filter empty = only my horse in first place
            console.log(filter,'filter')
            setFilterData(filter)
            // filter all second places 
            let secFilter = props.data.runnerResults.filter(item=> !isEmpty(item.placeNo) && item.placeNo=="02"&&item.id!=props.data.id)
            setSecFilterData(secFilter)
            console.log(secFilter,'secFilter')
            for(let i=0;i<distanceSplit?.length;i++){
                let obj = {}
                if(i==distanceSplit?.length-1){
                    //my horse sectional time splits
                    obj.kph = calKph(distanceSplit[i],props.data.sectionTimeSplits[i]?.sectionTime)
                    if(filter.length<=0){
                        secFilter.map((item,index)=>{
                            obj[`kph${index+2}`] = calKph(distanceSplit[i],item.sectionTimeSplits[i]?.sectionTime||null)
                  
                        })
                        //obj.kph2 = calKph(distanceSplit[i],props.data.runnerResults[1].sectionTimeSplits[i]?.sectionTime||null)
                    }else{
                        filter.map((item,index)=>{
                            obj[`kph${index+2}`] = calKph(distanceSplit[i],item.sectionTimeSplits[i]?.sectionTime||null)
                  
                        })
                    }
                    obj.section = distanceSplit[i]+ meterText +" - \n"+"0" + meterText;
                    chartData.push(obj);
                }else{
                    //my horse sectional time splits
                    obj.kph = calKph(distanceSplit[i]-distanceSplit[i+1],props.data.sectionTimeSplits[i]?.sectionTime||null)
                    if(filter.length<=0){
                        secFilter.map((item,index)=>{
                            obj[`kph${index+2}`] = calKph(distanceSplit[i]-distanceSplit[i+1],item.sectionTimeSplits[i]?.sectionTime||null)
                        
                        })
                       // obj.kph2 = calKph(distanceSplit[i]-distanceSplit[i+1],props.data.runnerResults[1].sectionTimeSplits[i]?.sectionTime||null)
                    }else{
                        filter.map((item,index)=>{
                            obj[`kph${index+2}`] = calKph(distanceSplit[i]-distanceSplit[i+1],item.sectionTimeSplits[i]?.sectionTime||null)
                        
                        })
                    }

                    obj.section = distanceSplit[i]+ meterText +" - \n"+distanceSplit[i+1] + meterText;

                    chartData.push(obj)
                }
            };
            horseCount = filter.length<=0?2:1+filter.length;
            console.log('props.data',distanceSplit)
            console.log('props.data',chartData)
            setData(chartData)
        }

    },[props.data])

    const chartRef = useRef(null);
    const CHART_ID = props.id;
    let dummyData = [
        
            {
                "kph": "24.83",
                "kph2": "25.32",
                "section": "1650M - \n1450M"
            },
            {
                "kph": "29.67",
                "kph2": "28.95",
                "section": "1450M - \n1250M"
            },
            {
                "kph": "65.40",
                "kph2": "65.40",
                "section": "1250M - \n1050M"
            },
            {
                "kph": "50.99",
                "kph2": "50.99",
                "section": "1050M - \n850M"
            },
            {
                "kph": "110.09",
                "kph2": "110.09",
                "section": "850M - \n450M"
            },
            {
                "kph": "147.54",
                "kph2": "153.55",
                "section": "450M - \n0M"
            }
        
    ]
   
    useEffect(() => {
    
    chartRef.current = am4core.create(CHART_ID, am4charts.XYChart);
        
    chartRef.current.data = data;

    chartRef.current.paddingTop = 40;
    var xAxis =  chartRef.current.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "section"
    var axisLabels = xAxis.renderer.labels.template;
    axisLabels.fontWeight = "bold";
    axisLabels.fontSize = "11px";
    axisLabels.truncate = true;
    //dateAxis.renderer.grid.template.location = 0;
    xAxis.renderer.minGridDistance = 40;
    xAxis.renderer.grid.template.disabled = true;
    //dateAxis.renderer.fullWidthTooltip = true;
    
    var yAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());

    yAxis.title.text = "kph";
    yAxis.layout = "absolute";
    yAxis.title.rotation = 0;
    yAxis.title.align = "center";
    yAxis.title.valign = "top";
    yAxis.title.dy = -40;
    yAxis.title.fontWeight = 600;
    yAxis.renderer.grid.template.disabled = false;

    yAxis.min=30
    yAxis.max=80

    // durationAxis.renderer.opposite = true;

    
    // durationAxis.durationFormatter.durationFormat = "hh'h' mm'min'";

    // var distanceSeries = chartRef.current.series.push(new am4charts.ColumnSeries());
    // distanceSeries.dataFields.valueY = "distance";
    // distanceSeries.dataFields.dateX = "date";
    // distanceSeries.yAxis = distanceAxis;
    // distanceSeries.tooltipText = "{valueY} miles";
    // distanceSeries.name = "Distance";
    // distanceSeries.columns.template.fillOpacity = 0.7;
    // distanceSeries.columns.template.propertyFields.strokeDasharray = "dashLength";
    // distanceSeries.columns.template.propertyFields.fillOpacity = "alpha";
    // distanceSeries.showOnInit = true;


    // // Add X Axis
    // let xAxis = chartRef.current.xAxes.push(new am4charts.CategoryAxis());
    // xAxis.dataFields.category = "year";
    // xAxis.renderer.cellStartLocation = 0.1;
    // xAxis.renderer.cellEndLocation = 0.9;
    // xAxis.renderer.grid.template.strokeOpacity = 0;
    // xAxis.renderer.labels.template.fill = am4core.color('#ced1e0');
    // xAxis.renderer.labels.template.fontSize = 12;

    // // Add Y Axis
    // let yAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
    // yAxis.renderer.grid.template.stroke = am4core.color('#f0f2fa');
    // yAxis.renderer.grid.template.strokeOpacity = 1;
    // yAxis.renderer.labels.template.fill = am4core.color('#ced1e0');
    // yAxis.renderer.labels.template.fontSize = 12;
    
    // Create series
    

    console.log(filterData,"filter")
    if(filterData.length<=0){
        secFilterData.map((item,index)=>{
            let series2 = chartRef.current.series.push(new am4charts.LineSeries());
            series2.tooltip.getFillFromObject = false;
            series2.dataFields.valueY = "kph"+(index+2);
            series2.dataFields.categoryX = "section";
            series2.yAxis = yAxis;
            series2.name = "JOYFUL HUNTER (Winning Horse)";
            series2.stroke  = index == 0? am4core.color(secHorseColor):index == 1? am4core.color(secHorseColor2):am4core.color(secHorseColor3);
            series2.strokeWidth = 3;
            series2.tooltipText = "{valueY}kph";
            series2.showOnInit = false;
            series2.tooltip.background.fill = index == 0? am4core.color(secHorseColor):index == 1? am4core.color(secHorseColor2):am4core.color(secHorseColor3);
            series2.tooltip.label.fill = am4core.color("#FFF")
            series2.tooltip.background.strokeWidth =0;
        })
        // let series2 = chartRef.current.series.push(new am4charts.LineSeries());
        // series2.tooltip.getFillFromObject = false;
        // series2.dataFields.valueY = "kph2";
        // series2.dataFields.categoryX = "section";
        // series2.yAxis = yAxis;
        // series2.name = "JOYFUL HUNTER (Winning Horse)";
        // series2.stroke  = am4core.color(secHorseColor);
        // series2.strokeWidth = 3;
        // series2.tooltipText = "{valueY}kph";
        // series2.showOnInit = false;
        // series2.tooltip.background.fill = am4core.color(secHorseColor);
        // series2.tooltip.label.fill = am4core.color("#FFF")
        // series2.tooltip.background.strokeWidth =0;
    }else{
        filterData.map((item,index)=>{
            let series2 = chartRef.current.series.push(new am4charts.LineSeries());
            series2.tooltip.getFillFromObject = false;
            series2.dataFields.valueY = "kph"+(index+2);
            series2.dataFields.categoryX = "section";
            series2.yAxis = yAxis;
            series2.name = "JOYFUL HUNTER (Winning Horse)";
            series2.stroke  = index == 0? am4core.color(winningHorseColor):index == 1? am4core.color(winningHorseColor2):am4core.color(winningHorseColor3);
            series2.strokeWidth = 3;
            series2.tooltipText = "{valueY}kph";
            series2.showOnInit = false;
            series2.tooltip.background.fill = index == 0? am4core.color(winningHorseColor):index == 1? am4core.color(winningHorseColor2):am4core.color(winningHorseColor3);
            series2.tooltip.label.fill = am4core.color("#FFF")
            series2.tooltip.background.strokeWidth =0;
        })
    }
   
    
    
   
    let series = chartRef.current.series.push(new am4charts.LineSeries());
    series.tooltip.getFillFromObject = false;
    series.dataFields.valueY = "kph";
    series.dataFields.categoryX = "section";
    series.yAxis = yAxis;
    series.name = "ISLAND SURPRISE";
    series.strokeWidth = 3;
    series.tooltipText = "{valueY}kph";
    series.showOnInit = false;
    series.stroke = am4core.color(horseColor);
    series.tooltip.background.fill = am4core.color(horseColor);
    // series.tooltip.label.fill = am4core.color("#FFF")
    series.tooltip.background.strokeWidth =0;
    // Series tooltip
    // series.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    // series.tooltip.pointerOrientation = 'down';
    // series.tooltip.dy = -5;
    // series.tooltip.background.filters.clear()
    // series.tooltip.getFillFromObject = false;
    

    // var latitudeAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
    // latitudeAxis.renderer.grid.template.disabled = true;
    // latitudeAxis.renderer.labels.template.disabled = true;
    
    // var latitudeSeries =  chartRef.current.series.push(new am4charts.LineSeries());
    // latitudeSeries.dataFields.valueY = "latitude";
    // latitudeSeries.dataFields.dateX = "date";
    // latitudeSeries.yAxis = latitudeAxis;
    // latitudeSeries.name = "Duration";
    // latitudeSeries.strokeWidth = 2;
    // latitudeSeries.tooltipText = "Latitude: {valueY} ({townName})";
    // latitudeSeries.showOnInit = true;

    // Add cursor
    chartRef.current.cursor = new am4charts.XYCursor();

    // Disable axis lines
    chartRef.current.cursor.xAxis = xAxis;
    chartRef.current.cursor.lineY.disabled = true;

    // Disable axis tooltips
    xAxis.cursorTooltipEnabled = false;
    yAxis.cursorTooltipEnabled = false;

    // Disable zoom

    // chartRef.current.legend = new am4charts.Legend();
    // chartRef.current.legend.strokeWidth = 4
    // chartRef.current.legend.fontSize=11
    // chartRef.current.legend.maxWidth = 1000;
    chartRef.current.cursor.behavior = 'none';
    return () => {
        chartRef.current.dispose();
    };
    }, [data]);
    
    const CustomerLegend = (props) => {
        const { CHART_ID } = props;
        const dataMock = [{
            color: '#0ABEF9',
            name: i18n.language=='ch'?'馬上出撃\n(勝出頭馬)':'HKJC THREE \n(Winning Horse)'
        },
        {
            color: '#D74DDA',
            name: i18n.language=='ch'?'馬上七號\n(第二名)':'HKJC SEVEN\n(Second Place)'
        }]
        
        return props.data?.runnerResults&&<Grid container>
            <Grid id={`${CHART_ID}_l_0`} item xs={6} textAlign='left' className={styles.legendItem}>
                    <div className={styles.square} id={`squareColor_0`}style={{background: horseColor}}/>
                    <div>{i18n.language=='en'? props.data?.runnerResults?.filter(item=>item.id==props.data.id)[0]?.horseName.english +`${props.data.placeNo=="01"?"\n(Winning Horse)":""}`:props.data?.runnerResults?.filter(item=>item.id==props.data.id)[0]?.horseName.chinese +`${props.data.placeNo=="01"?"\n(勝出頭馬)":""}`}</div>
            </Grid>
            {
                filterData.length<=0?
                secFilterData?.map((i, idx) => {
                    return (
                        <Grid id={`${CHART_ID}_l_${idx}`} item xs={6} textAlign='left' className={styles.legendItem}>
                            <div className={styles.square} id={`squareColor_${idx}`}style={{background: idx==0?secHorseColor:idx==1?secHorseColor2:secHorseColor3}}/>
                            <div>{i18n.language=='en'?i.horseName.english+"\n(Second Place)":i.horseName.chinese+"\n(第二名)"}</div>
                        </Grid>
                    )
                })
                // <Grid id={`${CHART_ID}_l_0`} item xs={6} textAlign='left' className={styles.legendItem}>
                //         <div className={styles.square} id={`squareColor_0`}style={{background: secHorseColor}}/>
                //         <div>{i18n.language=='en'?props.data?.runnerResults[1]?.horseName.english +"\n(Second Place)":props.data?.runnerResults[1]?.horseName.chinese+"\n(第二名)"}</div>
                // </Grid>
                
                :filterData?.map((i, idx) => {
                    return (
                        <Grid id={`${CHART_ID}_l_${idx}`} item xs={6} textAlign='left' className={styles.legendItem}>
                            <div className={styles.square} id={`squareColor_${idx}`}style={{background: idx==0?winningHorseColor:idx==1?winningHorseColor2:winningHorseColor3}}/>
                            <div>{i18n.language=='en'?i.horseName.english+"\n(Winning Horse)":i.horseName.chinese+"\n(勝出頭馬)"}</div>
                        </Grid>
                    )
                })
            }
        </Grid>
    }

    // When the paddingRight prop changes it will update the chart
    useEffect(() => {
        chartRef.current.paddingRight = props.paddingRight;
        chartRef.current.maxZoomLevel = 1;
        chartRef.current.cursor.behavior = 'none'
    }, [props.paddingRight]);
    return (
        <>
        {data?
        <>
            <div style={{ marginLeft: -16}}>
            
                <div id={CHART_ID} style={{ width: "100%", height: "300px"}}></div>
                
                <CustomerLegend CHART_ID={CHART_ID} data={props.data}/>
                <div id={CHART_ID+"_ST"}>
                    <div className={styles.subTitle}> {i18n.language=='ch'? '備註':'Remarks'}</div>
                    <div className={styles.content}>
                        {t("Lbl_SS_footerTips")}
                    </div>
                </div>
            
            </div>
            {mCLoydButtom}
        </>
        :
        <div className={styles.noData}> 
            {t('Lbl_SectionalSpeedNoData')}
        </div>
        }
        </>
    )

    
}




export default SectionalSpeedChart