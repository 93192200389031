import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import React ,{useState} from 'react';
import barrier from '../../img/Trackwork/barrier.svg';
import gallop from '../../img/Trackwork/gallop.svg';
import race from '../../img/Trackwork/race.svg';
import trotting from '../../img/Trackwork/trotting.svg';
import swimming from '../../img/Trackwork/swimming.svg';
import equineTreadmill from '../../img/Trackwork/equineTreadmill.svg';
import aquaWalker from '../../img/Trackwork/aquaWalker.svg';

import './TrackDrawer.css';
import PlayVideoIcon from '../../Component/PlayVideoIcon';

const TrackCard = (props) => {
    const {dateContent = [], currentDate = dayjs(), inSevenWeekNoneData = false, sectionDisplay} = props;
    // const hasTrackRecord = dateContent.find(i => i.day == dayjs(currentDate).format('DD'));
    console.log('【dateContent-----】', dateContent)
    // console.log('【currentDate-----】', currentDate)
    const { t, i18n } = useTranslation();
    const textMap = [{
      title: t('Lbl_TrackWork_Racing'),
      type:'R',
      image: race,
      color: '#D62D2D',
    },{
      title: t('Lbl_TrackWork_BarrierTrial'),
      type:'B',
      image: barrier,
      color: '#883A95',
    },{
      title: t('Lbl_TrackWork_Gallop'),
      type:'G',
      image: gallop,
      color: '#E99A00',
    },{
      title: t('Lbl_TrackWork_Trotting'),
      type:'T',
      image: trotting,
      color: '#4D9B1D',
    },{
      title: t('Lbl_TrackWork_Swimming'),
      type:'S',
      image: swimming,
      color: '#1E7DA6',
    },{
      title: t('Lbl_TrackWork_EquineTreadmill'),
      type:'ET',
      image: equineTreadmill,
      color: '#828D56',
    },{
      title: t('Lbl_TrackWork_AquaWalker'),
      type:'AW',
      image: aquaWalker,
      color: '#10B8C3',
    }]

    let dayTest = '';
    if (window.globalConfig.isLangUS) {
      dayTest = dayjs(currentDate).format('ddd').toUpperCase();
    } else {
      dayTest = dayjs(currentDate).locale('zh-hk').format('dddd');
    }
    const noDataStyle = () => {
      if (sectionDisplay) {
        return { textAlign: 'left' }
      } else {

        return { 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }
      }
    }
    return <div className='drawer-container' style={{ alignItems: dateContent.length ? '': 'center' }} >
        {
          inSevenWeekNoneData ?
            <div id='TW_inSevenWeekNoneData' className='Title/Title2-Medium drawer-content-noData'style={{ textAlign: 'left'}}>
              {t('Lbl_Within7WeekNoData')}
            </div>
          :
          dateContent.length && dateContent[0]?.tw_list?.length ? 
            <React.Fragment>
              <div id='TW_date' className='drawer-container-left'>
                  <div className='Label2-Bold PlayFair'>{dayjs(currentDate).format('DD/MM')}</div>
                  <div className="Label3-Medium">({dayTest})</div>
              </div>
              <div className='drawer-container-right'>
              {
                  dateContent[0]?.tw_list?.map((i, idx) => {
                  const spL = i.type.split('_');
                  const hasVideo = spL[1] == 1;
                  const tarMap = textMap.find(d => spL[0] === d.type) || {}
                  return (
                      <div className='drawer-right-item' key={`TW_Item_${idx}`}>
                      <div className='drawer-item-line' id={`TW_line_${idx}`} style={{ background: tarMap.color }}></div>
              
                      <div className='drawer-item-content'>
                          <div className='drawer-content-header'>
                          <div id={`TW_headerTitle_${i.type}`} className='drawer-header-left'>
                              <img src= {tarMap.image}/>
                              <span className='Title/Title1-Bold PlayFair'>{tarMap.title}</span> 
                          </div>
                          
                          {hasVideo && <PlayVideoIcon id={`TW_playButton_${idx}`} videoLink={i.videoUrl} />}
                          </div>
              
                          {i.venue && <p id={`TW_content_${spL[0]}_venue`} className="Label2-Medium" >{i.venue}&nbsp;{i.track}</p>}
                          {i.workout && <p id={`TW_content_${spL[0]}_workout`}  className="Label2-Medium">{i.workout}</p>}
                          {i.gear && <p id={`TW_content_${spL[0]}_gear`}  className="Label2-Medium">{i.gear}</p>}
                      </div>
                      </div>
                  )
                  })
              }
              </div>
            </React.Fragment> : <div id='TW_noData' className='drawer-content-noData Label1-SemiBold' style={noDataStyle()}>{t('Lbl_NoData')}</div>
        }
    </div>
}

export default TrackCard