import React, { useState } from 'react';
import { Drawer, Grid } from '@mui/material';
import { useTranslation } from "react-i18next";
import close from '../../img/Trackwork/close.svg';
import './ExplainDrawer.css';

const ExplainDrawer = ({open, setOpen, showExtra}) => {
    const { t, i18n } = useTranslation();
    const gear = t("Lbl_Gear_Title");
    const rt = t("Lbl_RT_Title");
    const rider = t("Lbl_Rider_Title");
    
    console.log('[gear]-----', i18n.language)
    let dataMap = {
      [gear]: {
        "B": t('Lbl_Gear_B'),
        "BO": t('Lbl_Gear_BO'),
        "CC": t('Lbl_Gear_CC'),
        "CP": t('Lbl_Gear_CP'),
        "CO": t('Lbl_Gear_CO'),
        "E": t('Lbl_Gear_E'),
        "H": t('Lbl_Gear_H'),
        "P": t('Lbl_Gear_P'),
        "PC": t('Lbl_Gear_PC'),
        "PS": t('Lbl_Gear_PS'),
        "SB": t('Lbl_Gear_SB'),
        "SR": t('Lbl_Gear_SR'),
        "TT": t('Lbl_Gear_TT'),
        "V": t('Lbl_Gear_V'),
        "VO": t('Lbl_Gear_VO'),
        "XB": t('Lbl_Gear_XB'),
        '"1"': t('Lbl_Gear_1'),
        '"2"': t('Lbl_Gear_2'),
        '"-"': t('Lbl_Gear_-'),
      }
    }
  
    if (i18n.language === 'en' && showExtra) {
        const extraForCH = {
            [rt]: {
                "AWT": t('Lbl_RT_AWT'),
                "TurG": t('Lbl_RT_TurG'),
                "Rgal": t('Lbl_RT_Rgal'),
                "RG": t('Lbl_RT_RG'),
                "SmT": t('Lbl_RT_SmT'),
                "TroR": t('Lbl_RT_TroR'),
                "BaSt":t('Lbl_RT_BaSt'),
                "AWTG": t('Lbl_RT_AWTG'),
                "RC": t('Lbl_RT_RC'),
            },
            [rider]: {
                "R.B.": t('Lbl_Rider_R.B.'),
                "A.T.": t('Lbl_Rider_A.T.'),
            }
        }

        dataMap = { ...dataMap , ...extraForCH}
    }
  
    const IndexList = () => {
  
      const listItem = [];
      for (const [title, content] of Object.entries(dataMap)) {
          listItem.push(
            <div className="indexItem-title" style={{ border: listItem.length ? '' : 'none'}} id={`TW_RowTitle_${title}`}>
              <p>{title}</p>
            </div>
          )
  
        for (const [k, v] of Object.entries(content)) {
          listItem.push( 
            <Grid xs={12} container className="indexItem-content" id={`TW_RowText_${k.replace(/\"/g, '')}`}>
              <Grid xs={4} className="indexItem-content-left">{k}</Grid>
              <Grid xs={8} className="indexItem-content-right">{v}</Grid>
            </Grid>
          )
        }
      }
      console.log('listItem---', listItem)
      return listItem.map(i => i)
    }
    return <Drawer
            className='indexDrawer'
            anchor={"bottom"}
            open={open}
            onClose={() => setOpen(false)}
          > 
          <div className='indexExplain-drawer'>
            <div className='indexExplain-header PlayFair'  id="TW_IndexTitle">
              {t('Lbl_Index')}
              <img src={close} onClick={() => setOpen(false)} id="TW_Index_Close"/>
            </div>
            <IndexList />
          </div>
        
        </Drawer>  
}

export default ExplainDrawer;