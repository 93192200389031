import * as React from 'react';

import "./css/CommentsOnRunning.css"
import { 
    Grid, 
} from '@mui/material';

import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import QmarkIconDialog from '../../Component/QmarkIconDialog';
const qmarkcss = {
    "display": "inline-block",
    "vertical-align": "sub",
    "padding-left": "10px",
}
export default function CommentsOnRunning({data}) {
  const [expanded, setExpanded] = React.useState(false);
  const { t, i18n } = useTranslation();
  useEffect(()=>{
    console.log('comments data',data)
  },[data])
  const contentHeader = "Pace Good; (3W4W) ";
  const contentText = "Jumped awkwardly, caught wide no cover chasing leaders, struggled to hold his position passing 550M, soon beaten and finished tailed out. (Unacceptable Performance / Jockey reported his horse lay out on the turn.)";
  return (
    <Grid container px={2} style={{ position: 'relative'}} >
        <Grid className='CommentsOnRunning-root' >
            <div className='CommentsOnRunning-Header'>
                <div id='CommentsOnRunningTitle' className='CommentsOnRunning-Title PlayFair'>
                    {t('Lbl_CommentsOnRunning')}
                    <div style={qmarkcss}>
                    <QmarkIconDialog id={"Qmark_COR"} type="COR"/>
                    </div>
                </div>
            
                <div style={{ flex: 1 }} />
            </div>

            <Grid container py={2} className="CommentsOnRunning-TopContainer">
                <div className="ctent-header fontSize16">
                    {i18n.language === 'ch'? data?.chiHeader : data?.engHeader}
                </div>
                <div className="ctent-text fontSize14">
                    {i18n.language === 'ch'? data?.chiOfficialComments : data?.engOfficalComments}
                </div>
                <div className="ctent-disclaimer fontSize11">
                    {t('Lbl_CommentsOnRunning_Disclaimer')}
                </div>
            </Grid>
        </Grid>
    </Grid>
  );
}         