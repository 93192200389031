import React, { useState, useEffect, useRef, useContext } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-hk';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useTranslation } from "react-i18next";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import TrackDrawer from './Component/TrackDrawer';
import "./index.css";
import { useLocation } from 'react-router-dom';
import { groupBy } from 'lodash';
import moment from 'moment'
import { getTCDataContent } from '../utils';
import Header from '../Component/Header';
import HorseContext from '../utils/horseContext';
import { Grid } from '@mui/material';

const testDate = '2015-8-31'

const EtraTagList = ({ isSelected, selectedDay, timeStamp, setDateContent, day, disabled, outsideCurrentMonth }) => {
  const dayItem = useRef(null);
  const calendarRow = useRef(0);
  const [tagList, setTagList] = useState([])
  const { t, i18n } = useTranslation();
  const disabledBG = disabled || outsideCurrentMonth;

  const triggerDayClick = () => {
    dayItem.current.previousSibling.firstChild.click()
  }

  const onMaskClick = React.useCallback(() => {
    // 之前的，日历外日期点击
    if (!disabled && outsideCurrentMonth)  dayItem.current.previousSibling.firstChild.click()
  }, [disabled, outsideCurrentMonth])

  const MaskRender = () => disabledBG ? <div className={disabledBG ? 'disabledBG-mask' : 'displayNone'} onClick={onMaskClick}></div>: null

  useEffect(() => {
    calendarRow.current = document.querySelector('.MuiDayCalendar-monthContainer')?.childNodes?.length || 6
    // console.log('selectedDayselectedDay111', calendarRow.current)
    const dateContent = getTCDataContent(selectedDay, day);
    setTagList(dateContent)
  }, [selectedDay]);

  if (tagList[0]?.tw_list?.length > 0) {
    return <React.Fragment>
        <div className="pickersDayRoot-taglist" id={`TW_TagListContainer_${timeStamp}`} onClick={triggerDayClick} ref={dayItem}>
        {tagList && tagList[0]?.tw_list?.map((i, idx) => {
          // const maxRow = calendarRow.current == 6 ? 1 : 2;
          if (idx <= 2 ) {
            return <div id={`TW_TagListContainerItem_${timeStamp}_${idx}`} key={`TW_TagListContainerItem_${timeStamp}_${idx}`}>{getSvgByTyte(i.type, t)}</div>
          } else if (idx === 3) {
            return <div style={{ height: 10,
              lineHeight: "0" }}>...</div>
          }
          
          return null
        })}
      </div>
      <MaskRender/>
    </React.Fragment>
  }
  return <React.Fragment>
      <div className="pickersDayRoot-taglist" onClick={triggerDayClick} ref={dayItem} id={`TW_TagListContainer_${timeStamp}`}></div>
      <MaskRender/>
    </React.Fragment>
}



const ServerDay = (props) => {
  const { highlightedDays = [], day, selectedDate, dateContent, setDateContent, outsideCurrentMonth, ...other } = props;
  const selectedDay = highlightedDays[dayjs(props.day, 'YYYY-MM-DD').format('YYYY-MM-DD')]
 
  const isSelected =
  !props.outsideCurrentMonth && selectedDay;

  const seletedCircle = props.day.format('DD/MM/YYYY') === selectedDate.format('DD/MM/YYYY')
  const timeStamp = dayjs(props.day).format('MMDD');

  const disabledBG = props.disabled || props.outsideCurrentMonth;

  return (
    <div
      key={props.day.toString()}
      id={`TW_TagRoot_${timeStamp}`}
      className={`pickersDayRoot ${seletedCircle? "selecedCircleColrt" :"" }${disabledBG ? "disabledDay" : ""}`}
    >
      <div className="dayContainer">
        <PickersDay {...other} day={day} id={`TW_PickersDay_${timeStamp}`} />
      </div>

      <EtraTagList
        disabled={props.disabled}
        outsideCurrentMonth={props.outsideCurrentMonth}
        isSelected={isSelected}
        selectedDay={selectedDay}
        timeStamp={timeStamp}
        setDateContent={setDateContent}
        day={day.format('YYYY-MM-DD')}
      />
    </div>
  );
}
const TrackWork = (props) => {
  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [selectedDate, setSelectedDate] = React.useState(dayjs())
  const { state } = useLocation();
  const [minDate, setMinDate] = React.useState(dayjs(testDate));

  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const [dateContent, setDateContent] = React.useState([]);
  const { businessDate } = useContext(HorseContext); 
  const [ dateValue, setDateValue] = React.useState(dayjs());

  React.useEffect(() => {
    setDateValue(dayjs(businessDate))
  },[businessDate])

  const timer = useRef(null);
  React.useEffect(() => {
    const calendarHeadTitle = document.getElementsByClassName('MuiPickersCalendarHeader-root')[0];
    const calendarHeadBtns = document.getElementsByClassName('MuiPickersArrowSwitcher-root')[0].childNodes


    calendarHeadTitle.setAttribute('id', 'TW_CalendarHeader')
    calendarHeadBtns[0].setAttribute('id', 'TW_CalendarLeftIcon')
    calendarHeadBtns[2].setAttribute('id', 'TW_CalendarLRightIcon')
    
    if (window.globalConfig.isLangUS) {
      const TW_CalendarHeader = document.getElementById("TW_CalendarHeader");
      TW_CalendarHeader.childNodes[0].childNodes[0].classList.add("PlayFair");
    }

    const temp = state?.data?.map(i => {
      i.date = dayjs(i.date, 'YYYY-MM-DD').format('YYYY-MM-DD')
      return i;
    })
    const calendarData = groupBy(temp, 'date');
    if (Object.keys(calendarData).length) {
      const dateList = Object.keys(calendarData).map(d => dayjs(d));
      const mdate = dayjs.min(dateList).format('YYYY-MM-DD')
      // console.log('【--------mdate】', mdate)
      setMinDate(dayjs(mdate))
    } 
   
    setHighlightedDays(calendarData);

    const day = dayjs(businessDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const currentDate = calendarData[day];
    const dateContent = getTCDataContent(currentDate, day);

    setSelectedDate(dayjs(day))
    setDateContent(dateContent);


    document.body.style["height"] = "-webkit-fill-available";
    // abort request on unmount
    return () => {
      requestAbortController.current?.abort();
      document.body.style["height"] = "100%";
    }

  }, []);

  const handleMonthChange = (date) => {
    // const tarDom = document.querySelector(`.selecedCircleColrt`);
    // console.log('month', tarDom)
    setTimeout(() => {
      const tarDom = document.querySelector(`.selecedCircleColrt`);
      // console.log('[aewtase----]', tarDom)
      tarDom?.scrollIntoView();
    })
   
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }
  };

  return (
    <>
      <Header title='Lbl_TrackWork' />
      <div className='trackwork' style = {{
        height: window.innerHeight - 60
      }}>
        <div className='trackwork-calender'>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={window.globalConfig.isLangUS ? 'es-us' : 'zh-hk'}
            dateFormats={window.globalConfig.isLangUS ? null : { monthAndYear: 'YYYY年 M月' }}>
            <DateCalendar
              value={dateValue}
              // defaultValue={initialValue}
              onMonthChange={handleMonthChange}
              slots={{
                day: ServerDay,
              }}
              slotProps={{
                day: {
                  highlightedDays,
                  selectedDate,
                  setSelectedDate,
                  dateContent,
                  setDateContent,
                },
              }}
              views={['day']}
              onChange={(value, selectionState) => {
                const day = dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD');
                const selectedDay = highlightedDays[day];

                const dateContent = getTCDataContent(selectedDay, day);
                setDateContent(dateContent);

                setDateValue(dayjs(value));
                setSelectedDate(value)
              }}
              
              minDate={minDate}
              maxDate={dayjs(businessDate)}
              disableFuture
              showDaysOutsideCurrentMonth
            />
          </LocalizationProvider>
        </div>
        <Tagscontainer />

        <TrackDrawer dateContent={dateContent} currentDate={selectedDate} />
      </div>
    </>
  );
}


const Tagscontainer = () => {
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const isEn = i18n.language === 'en';

  return <div className='tagscontainer'>
    <Grid container className='tagscontainer-tag'>
      {
        isEn && <>
          <Grid xs={3.5}>
            <span id="tagsctB">(B)</span>	&nbsp;{t('Lbl_TrackWork_BarrierTrial')}
          </Grid>
          <Grid xs={4.5}>
            <span id="tagsctG">(G)</span>	&nbsp;{t('Lbl_TrackWork_Gallop')}
          </Grid>
          <Grid xs={4}>
            <span id="tagsctT">(T)</span>	&nbsp;{t('Lbl_TrackWork_Trotting')}
          </Grid>
          <Grid xs={3.5}>
            <span id="tagsctS">(S)</span>	&nbsp;{t('Lbl_TrackWork_Swimming')}
          </Grid>
        </>
      }
      <Grid xs={isEn ? 4.5: ''} pr={!isEn ? 2 : 0}>
        <span id="tagsctET">({t('Lbl_TrackWork_ET')})</span>	&nbsp;{t('Lbl_TrackWork_EquineTreadmill')}
      </Grid>
      <Grid xs={isEn ? 4: ''}>
        <span id="tagsctAW">({t('Lbl_TrackWork_AW')})</span>	&nbsp;{t('Lbl_TrackWork_AquaWalker')}
      </Grid>
    </Grid>

    {/* <div><img src={sign} onClick={() => setOpen(true)}/></div> */}
  </div>
}

const getSvgByTyte = (type, t) => {
  const spL = type.split('_');
  const newType = spL[0];
  let text = '';

  if (!window.globalConfig.isLangUS) {
    const tagMap = {
      B: t('Lbl_TrackWork_BarrierTrial'),
      G: t('Lbl_TrackWork_Gallop'),
      R: t('Lbl_TrackWork_Racing'),
      S: t('Lbl_TrackWork_Swimming'),
      T: t('Lbl_TrackWork_Trotting'),
      ET: t('Lbl_TrackWork_ET'),
      AW: t('Lbl_TrackWork_AW'),
    }
    text = tagMap[newType]
  } else {
    text = newType
  }

  if ((type === 'R_1' || type === 'R_0') && window.globalConfig.isLangUS) {
    text = 'Race'
  }

  switch (type) {
    case 'B_0':
    case 'G_0': 
    case 'R_0': 
    case 'S_0':  
    case 'T_0': 
    case 'ET_0':
    case 'AW_0':
      return <div className='pickersDayRoot-taglistItem'><div className={type}><span>{text}</span></div></div>;
    case 'B_1':
    case 'G_1':
    case 'R_1':
    case 'S_1':
    case 'T_1':
    case 'ET_1':
    case 'AW_1':
      return <div className='pickersDayRoot-taglistItem'><div className={`taglistItem-HasReplay ${type} ${text === 'Race' ? "fontSize11 lineHeight17": ''}`}><span>{text}</span><PlayCircleOutlineIcon /></div></div>;
    default:
      return null;
  }
}
export default TrackWork;