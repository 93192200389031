import moment from 'moment';
import { isNull, getOwnerType } from './index';

function ifShowMCLloyd(data, businessDate){
    // return true;
    // console.log('【businessDate---】', businessDate)
    if (!businessDate || data?.horse?.length == 0) return false;

    const { horse } = data;
    // race date + 1 >= business date

    // horse -> horseFormRecord -> date 
    if (horse.length > 0 && businessDate) {
        const temph = horse.find(i => {
          
            if (i?.horseFormRecord?.length) {
                const ifShow = isWithinRaceDayOneDay(i, businessDate);
                if(i.horseFormRecord[0].raceResult=='FullFieldPlacing')
                    return ifShow
                else return false
            }
            return false
        })
        // console.log('temph----', temph)
        return  Object.keys(temph || {} ).length > 0
    }

    return false;
}

function isWithinRaceDayOneDay(horseItem, businessDate) {
    const validDateItem = horseItem?.horseFormRecord?.find(d =>{
        // remove new Date() TODO
        const tarDate = new Date(moment(d.date, "YYYY-MM-DD").format("YYYY-MM-DD")).getTime();
        
        const condictionDate = new Date(businessDate).getTime() - 24*60*60*1000
        return  tarDate >= condictionDate
    })
    if (Object.keys(validDateItem || {}).length > 0) {
        return checkIfPlaceNoValid(validDateItem)
    }
    return false
}

function checkIfPlaceNoValid(horseItem = {}) {
    const isDateValid = moment(moment(horseItem.date, 'YYYY-MM-DD').format('YYYY-MM-DD')).isSameOrAfter(window.globalConfig.mclloyd_Data_validDate)
    return horseItem?.placeNo > 0 && isDateValid
}

function getMCLloydCardList(horse = [], clientData, businessDate){
    const isUS = window.globalConfig.isLangUS;
    let dataTemp = [];

    if (horse.length) {
        for (let i = 0; i< horse.length; i++) {
            const h = horse[i]
            const { horseFormRecord = [] } = h;
            let obj = {}
            const ifShow = isWithinRaceDayOneDay(h, businessDate);
            if (!ifShow) continue

            if (horseFormRecord.length && ifShow) {
                const sortData = horseFormRecord.filter(i => !i.overseas).slice().sort((a, b) => {
                    return moment(b.time, "YYYY-MM-DD").format("YYYYMMDD") - moment(a.time, "YYYY-MM-DD").format("YYYYMMDD")
                })
    
                obj = sortData[0];
                console.log('【tarItem-----------】',obj)
                dataTemp.push({
                    season: obj?.season,
                    horseName: isUS ? h?.name_en : h?.name_ch,
                    RaceNum: obj?.raceNo,
                    placeNo: obj?.placeNo ? parseInt(obj?.placeNo) : '-',
                    Title: isUS ? isNull(obj?.raceName?.english) : isNull(obj?.raceName?.chinese),
                    // workout: 'Class 4, 1200M, Turf, "B" Course', 
                    local: isUS ? obj?.venue?.english : obj?.venue?.chinese,
                    time: moment(obj?.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
                    raceClass: isUS ? obj?.className?.english : obj?.className?.chinese,
                    distance: obj?.distance ? obj?.distance + `${isUS ? "M": "米"}` : isNull(obj?.distance),
                    track: isUS ? obj?.track?.english : obj?.track?.chinese,
                    course: isUS ? obj?.course?.english : obj?.course?.chinese,
                    going: isUS ? obj?.going?.english : obj?.going?.chinese,
                    trainer:  isUS ? obj?.trainer?.name_en : obj?.trainer?.name_ch,
                    jockey:  isUS ? isNull(obj?.jockey?.name_en) : isNull(obj?.jockey?.name_ch),
                    finishTime: isNull(obj?.finalTimeOfRace),
                    videoLink: isUS ? obj?.videoReplayURL1200mp4?.english : obj?.videoReplayURL1200mp4?.chinese,
                    id:obj?.id,
                    sectionTimeSplits: obj?.sectionTimeSplits,
                    sectionDistanceSplit : obj?.sectionDistanceSplit,
                    sectionInfos : obj?.sectionInfos,
                    cvideoLink : isUS ? obj?.videoWinningURL1200mp4?.english : obj?.videoWinningURL1200mp4?.chinese,
                    brandNumber: h?.brandNumber,
                    ownerType: getOwnerType(h?.brandNumber, clientData),

                    runnerResults: obj?.runnerResults,
                    winningHorse: obj?.winningHorse,
                    trackCode: obj?.track?.code,
                    venueCode: obj?.venue?.code,
                    _distance: obj?.distance,

                    horseFormRecord_ForMyRecord: sortData,
                })
            }
        }
    }
    return dataTemp;
}

const req = require.context("../img/RPCompare", false, /\.svg$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);

function getSplitTrackIcon(splitDistance, trackType, distance) {
    console.log('[distance----]',distance)
    if (distance === 1800 && splitDistance === 1600 && trackType === 'ST_TURF') {
      return requireAll(req)
      .find(path => path.indexOf(`${trackType}_${splitDistance}_For1800`) > 0);
    }
  
    const svgString = requireAll(req)
    .find(path => path.indexOf(`${trackType}_${splitDistance}`) > 0);
    return svgString
}
  


export {
    ifShowMCLloyd,
    getMCLloydCardList,
    checkIfPlaceNoValid,
    getSplitTrackIcon,
}