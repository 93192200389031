
import React, { useEffect, useState, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material/'
import formTablePlaInfo from '../../img/formTablePlaInfo.svg'
import './css/ResultTable.css'
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
const ResultTable = (props) => {
    const { lang } = useParams();
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false)
    const [fullResultsData, setFullResultsData] = useState([])

    const CustomTableCell = (props) => {
        return (<TableCell id='RD_FR_form_blank' style={props.customStyle} className={`race-season-cell race-no-border-right ${props.customClass}`}>{props.children}</TableCell>)
    }
    const CustomLabelCell = (props) => {
        return (<TableCell id='RD_FR_form_label' onClick={() => props.onHandleClick?.()} style={props.customStyle} width={100} className={'race-label-cell'}>{props.children}</TableCell>)
    }
    const CustomValCell = (props) => {
        return (<TableCell id={`RD_FR_form_cell_${props.index}`} className={'race-content-cell'}>{props.children}</TableCell>)
    }
    const onClickPla = () => {
        props.onClickPla()
    }
    const getRunningPos = (infoData) => {
        let result = '--';
        if (infoData.length) {
            let posArr = []
            posArr = infoData?.map((item) => {
                if (item.ruPos === -1 || item.ruPos === 0) {
                    return ''
                } else {
                    return item.ruPos
                }
            })
            result = posArr.join(' ')
        }
        if (result.trim()) {
            return result;
        } else {
            return '--'
        }
    }
    const jockeyTrainerName = (data, type) => {
        let result = '';
        if (!data[type]?.['code']) return '--'
        if (lang === 'en') {
            result = data[type]?.['name_en']
        } else {
            result = data[type]?.['name_ch']
        }
        if (result?.trim?.() === '') {
            result = data[type]?.['name_en']
        }
        return result || '--';
    }
    const getHorseName = (data) => {
        let result = ''
        if (data?.horseName?.code) {
            if (lang === 'en') {
                if (data?.horseName?.english) {
                    result = data?.horseName?.english
                } else {
                    result = 'UNNAME'
                }
            } else {
                if (data?.horseName?.chinese) {
                    result = data?.horseName?.chinese
                } else {
                    result = '未命名'
                }
            }
        } else {
            result = '--'
        }
        return result;
    }
    const getLBWValue = (marginOrLBW = {}, placeNo) => {
        const { code = "",  english, chinese} = marginOrLBW;
        if (Number(placeNo) === 1) return '--';

        if (Number(placeNo) > 1 && code.trim() =="DH") return lang==='en' ? english : chinese;

        if (Number(placeNo) > 1 && code.trim() !=="DH" && code.trim()) return lang === 'en' ? code : chinese.trim() ? chinese : code 

        return '--';

        // if (code.trim() =="DH" && Number(placeNo) !== 1) return lang==='en' ? english : chinese;

        // if (code.trim() =="DH" && Number(placeNo) === 1) return '--';

        // if (code.trim() !=="DH" && code.trim()) return lang === 'en' ? code : chinese.trim() ? chinese : code 
                    
        // return '--';
    }
    const getResultData = (propData) => {
        const newResult = propData?.map((item) => {
            return {
                horseNo: item.horseNo < 0 ? '' : item.horseNo + '.',
                brandNo: item.horseName?.code,
                horseName: getHorseName(item),
                placeNo: !item.placeNo.trim() || item.placeNo === '-1' ? '--' : item.placeNo === "0" ? item.resultIndex.trim() : item.resultIndex.trim().includes("DH")?lang==='en'?Number(item.placeNo) +" DH":Number(item.placeNo) + " "+t("Lbl_DH"):Number(item.placeNo),
                jockey: jockeyTrainerName(item, 'jockey'),
                trainer: jockeyTrainerName(item, 'trainer'),
                actW: item.actualWeight,
                horseW: item.horseWeight < 0 ? '--' : item.horseWeight,
                DR: item.barrierDrNo === -1 ? '--' : item.barrierDrNo,
                // DH: 平头马
                LBW: getLBWValue(item.marginOrLBW, item.placeNo),
                runningPos: getRunningPos(item.sectionInfos),
                finishTime: item.finalTimeOfRace ? item.finalTimeOfRace : '--',
                winOdds: item.winOdds ? item.winOdds : '--',
            }
        })
        setFullResultsData(newResult)
    }
    useEffect(() => {
        if (props.data) {
            getResultData(props.data)
        }
    }, [props.data])
    return (
        <>
            <div className={`race-Header PlayFair`}>
                <div id='FullResultsTitle' className={`race-Title`}>
                    {t("Lbl_FullResults")}
                </div>
                <div style={{ flex: 1 }} />
            </div>
            <TableContainer id='RD_FR_detail_tableContainer' className='race-table-container' component={Paper}>
                <Table>
                    <TableHead id='RD_FR_form_labelHead'>
                        <TableRow id='RD_FR_form_labelRow'>
                            <CustomTableCell customClass={"race-label-season-cell"} customStyle={{ backgroundColor: "#6C7280" }}>{ }</CustomTableCell>
                            <CustomLabelCell customStyle={{ minWidth: "74px", position: 'relative' }} onHandleClick={onClickPla}> 
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <p>
                                        {t('Lbl_FormRecord_Pla')}
                                    </p>

                                    <img id='RD_FR_detail_PlaInfo' style={{
                                        paddingLeft: 2
                                    }} alt='' src={formTablePlaInfo} />
                                </div>
                                
                            </CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "74px" }}>{t('Lbl_FormRecord_Jockey')}</CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "74px" }}>{t('Lbl_FormRecord_Trainer')}</CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "90px" }}>{t('Lbl_FormRecord_ActWt')}</CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "90px" }}>{t('Lbl_FullResults_DHW')}</CustomLabelCell>
                            <CustomLabelCell>{t('Lbl_FormRecord_Dr')}</CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "74px" }}>{t('Lbl_FormRecord_LBW')}</CustomLabelCell>
                            <CustomLabelCell customStyle={{ minWidth: "74px" }}>{t('Lbl_FormRecord_RunningPosition')}</CustomLabelCell>
                            <CustomLabelCell>{t('Lbl_FormRecord_FinishTime')}</CustomLabelCell>
                            {/* <CustomLabelCell>{t('Lbl_FormRecord_WinOdds')}</CustomLabelCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fullResultsData?.map((result, index) => (
                            <TableRow id={'RD_FR_form_bodyRow' + index} key={index} style={{ borderBottom: "1px solid #CED3D9"}}>
                                <CustomTableCell index={index} customClass={"race-season-body-cell"}>
                                    <Grid container >
                                        <Grid xs={3}>
                                            <p className='race-season-body-p'>{result.horseNo}</p>
                                        </Grid>
                                        <Grid xs={9}>
                                            <p>{result.horseName}{result.brandNo ? ` (${result.brandNo})` : ''}</p>
                                        </Grid>
                                    </Grid>
                                </CustomTableCell>
                                <CustomValCell index={index}>{result.placeNo}</CustomValCell>
                                <CustomValCell index={index}>{result.jockey}</CustomValCell>
                                <CustomValCell index={index}>{result.trainer}</CustomValCell>
                                <CustomValCell index={index}>{result.actW}</CustomValCell>
                                <CustomValCell index={index}>{result.horseW}</CustomValCell>
                                <CustomValCell index={index}>{result.DR}</CustomValCell>
                                <CustomValCell index={index}>{result.LBW}</CustomValCell>
                                <CustomValCell index={index}>{result.runningPos}</CustomValCell>
                                <CustomValCell index={index}>{result.finishTime}</CustomValCell>
                                {/* <CustomValCell index={index}>{result.winOdds}</CustomValCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >
        </>
    )
}
export default ResultTable;