import React, { useState, useEffect, useContext, useRef } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import { Container, Box, Grid, Typography, Drawer } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WMCard from '../HorseProfile/Component/WMCard';
import "./index.css";
import External_link_2 from '../img/WinningMoment/External_link_2.svg';
import close from '../img/Trackwork/close.svg';
import { DownFileOrOpenURL, getWinMomentCardData, getWinMomentSeason, scrollIfContain } from '../utils'
import Picker from '../Component/Picker/PickerComponent'
import Header from '../Component/Header';
import { useLocation } from 'react-router-dom';

const MockDataList = []
let currentScrollTop = 0;
const mockSeasonList = []

const WMInfo = (props) => {
  const { t, i18n } = useTranslation();
  const [currentSeason, setCurrentSeason] = useState('23/24 Season');
  const [seasonlist, setSeasonlist] = useState(mockSeasonList);
  const [open, setOpen] = useState(false);
  const [disableScroll, setDisableScroll] = useState(false);

  const [value, setValue] = useState(["0"]);
  const [dataSource, setDataSource] = useState(MockDataList);
  const { state } = useLocation();
  const originalData = useRef([]);
    
  useEffect(() => {
    const dateContent = getWinMomentCardData(state?.data, t);
    const {latestSeason, seasonList = []} = getWinMomentSeason(state?.data, t);

    const all = t('Lbl_All');
    let tempSeasonList = []
    if (seasonList.length === 1) {
      tempSeasonList = [...seasonList]
      setCurrentSeason(latestSeason);
    } else if (seasonList.length > 1) {
      tempSeasonList = [all, ...seasonList]
      setCurrentSeason(all);
    }
  
    setSeasonlist(tempSeasonList);
    setDataSource(dateContent);

    // 存一份原始数据
    originalData.current = dateContent;
  }, [state]);

  const onViewPhotoClick = () => {
    const {
      SMARTVISION_kEY
    } = window.globalConfig;
    DownFileOrOpenURL(SMARTVISION_kEY)
  }

  const calcScroll = (e) => {
    if (disableScroll) return;
    // console.log( 'eeee', e.target.childNodes[0])
    const scrollDom = e.target.childNodes[0].childNodes;
    const tempList = []
    // scroll down
    if (e.target.scrollTop > currentScrollTop) {

      scrollDom.forEach(child => {
        tempList.push({
          isContain: scrollIfContain(child, e.target, 'down'),
          title: child.getElementsByTagName('p')[0].innerText,
        })
      })

      const tarIndx = tempList.findLastIndex(i => i.isContain === true);
      setCurrentSeason(tempList[tarIndx].title);
    } else {
      // scroll up
      scrollDom.forEach(child => {
        tempList.push({
          isContain: scrollIfContain(child, e.target, 'up'),
          title: child.getElementsByTagName('p')[0].innerText,
        })
        // console.log(scrollIfContain(child, scrollWMCard))
        // console.log( 'up', scrollIfContain(child, e.target, 'up'), child.getElementsByTagName('p')[0].innerText)
      })

      const tempItem = tempList.find(i => i.isContain === false);
      tempItem && setCurrentSeason(tempItem.title);
    }

    // console.log('执行了', disableScroll)
    currentScrollTop = e.target.scrollTop
  }

  const onValueChange = (value) => {
    setValue(value);
  };

  const onScrollChange = (value) => {
    setValue(value);
  };

  const onDrawerBtn = () => {
    const selectedSeason = seasonlist[value]
    setCurrentSeason(seasonlist[value])

    let seasonData = []
    if (selectedSeason === t('Lbl_All')) {
      seasonData = originalData.current;
    } else {
      seasonData = originalData.current.filter(i => i.season === selectedSeason)
    }
    setDataSource(seasonData);

    setOpen(false)
    const tarDom = document.getElementById('WMCardList')
    tarDom.scrollTo(0, 0);
  }
  return (
    <>
      <Header title='Lbl_WinningMoments' fixedTop={true}/>
      <div id='WMInfo' className='WMInfo'>
        <Container fixed className="SeasonHeader" onClick={() => seasonlist.length > 1 ? setOpen(true) : null}>
          <Typography id='WM_Header'>{currentSeason}</Typography> 
          {seasonlist.length > 1 && <ExpandMoreIcon id='WM_HeaderICon' />}
        </Container>
        <div className='WMCardList' id="WMCardList" 
        // onScroll={calcScroll}
        >
          <WMCard
            isInfoPage={true}
            dataSource={dataSource}
            currentSeason={currentSeason} />
        </div>
        <Container className="SeasonFooter">
          <div id="WM_view_photo" className="SeasonFooter-btn" onClick={onViewPhotoClick}>
            <div className="half-3px-border">
              <div><img src={External_link_2} /></div>
              <Typography >
                {t('Lbl_WinMoment_ViewPhotoBtn')}
              </Typography>
            </div>
          </div>
        </Container>

        <Drawer
          className='WMInfoDrawer'
          anchor={"bottom"}
          open={open}
          onClose={() => {setOpen(false);console.log(value)}}
        >
          <div className='WMInfo-drawer'>
            <div className='WMInfo-header PlayFair' id="WM_IndexTitle">
              {t('Lbl_WinMoment_Season')}
              <img src={close} onClick={() => setOpen(false)} id="WM_Index_Close" />
            </div>

            <Picker
              idPrefix="WM_PickerItem"
              selectedValue={value}
              onValueChange={onValueChange}
              onScrollChange={onScrollChange}
              PList={seasonlist}
            />

            <div className='WMInfo-btn' onClick={onDrawerBtn} id="WM_IndexBtn">
              {t('Lbl_Done')}
            </div>

          </div>
        </Drawer>


      </div>
    </>)
}

export default WMInfo;