
import React, { useEffect, useState } from 'react';
import VideoDialog from './VideoDialog';
import play from '../img/Trackwork/play.svg';
import { DownloadForm, openDeviceRotation, OnGoBackBtnClick } from '../utils';
import { useNavigate } from "react-router-dom";

const PlayVideoIcon = (props) => {
    const { id, content, videoLink, iconSize, isComplimentaryVideoReady } = props;
    const [open, setOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const navigate = useNavigate();

    const onClose = () => {
        openDeviceRotation(false);
        setOpen(false);

        window.goback = () => {
            OnGoBackBtnClick(navigate)
        }
    }
    const onVideoPlay = () => {
        if (isComplimentaryVideoReady) {
            DownloadForm(videoLink);
        } else {
            openDeviceRotation(true);
            setOpen(true);
            window.goback = onClose
        }
    }
    console.log('[videoLink---]', videoLink)
    useEffect(() => {
        setVideoUrl(videoLink)
    }, [videoLink])
    
    const imageStyle = {
        width: iconSize ? iconSize : 22,
        height: iconSize ? iconSize : 22,
    }
    return (
        <>
            <img id={id} src={play} onClick={onVideoPlay} style={imageStyle}/>
            {content && <div onClick={onVideoPlay}>{content}</div>}
            <VideoDialog 
                open={open}
                onClose={onClose}
                videoUrl={videoUrl}
            />
        </>
    )
}

export default PlayVideoIcon;