import * as React from 'react';

import "./css/PostRaceAnalysis.css"
import { 
    Grid, 

} from '@mui/material';

import { useTranslation } from "react-i18next";

import { useEffect } from 'react';

import MCLloydSlider from '../../OwnerProfile/Component/MCLloydSlider';
const MockData = [{
    RaceNum: 8,
    Title: 'WONG NAI CHUNG GAP HANDICAP',
    workout: 'Class 4, 1200M, Turf, "B" Course',
    local: 'Sha Tin',
    time: '30/04/2023',
}]

export default function PostRaceAnalysis({data}) {
  const [expanded, setExpanded] = React.useState(false);
  const { t, i18n } = useTranslation();

  useEffect(()=>{
    console.log('data',data)
  },[data])
  return (
    <Grid container px={2} style={{ position: 'relative',backgroundColor:'white'}} id={'McLloydCard0'}>
        <Grid className='PostRaceAnalysis-root' >
            <div className='PostRaceAnalysis-Header'>
                <div id='PostRaceAnalysisTitle' className='PostRaceAnalysis-Title PlayFair'>
                    {t('Lbl_MacL_Analysis')}
                </div>
                <div style={{ flex: 1 }} />
            </div>
            <Grid container className='PostRaceAnalysis-Card'>
                 <MCLloydSlider data={data} idx={0}/>
            </Grid>
        </Grid>
    </Grid>
  );
}         