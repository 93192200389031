import { useState, useEffect } from 'react';


const FullRace = (props) => {
    const { trackType} = props;
    console.log('【props----】', props)
    let renderFullRace = null;
    switch (trackType) {
        case "HV":
            renderFullRace = () => <HVFullRace {...props}/>
        break;

        case "ST_AWT":
            renderFullRace = () => <STAWTFUllRace {...props}/>
        break;

        case "ST_TURF":
            renderFullRace = () => <STTURFFullRace {...props}/>
        break;

        default:
            break
    }

    return <div style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 18
    }}>
        {renderFullRace && renderFullRace()}
    </div>
}

const HVFullRace = (props) => {
    const { trackType, currentDis, distance} = props;
    const renderStartArrow = (dis) => {
        switch (dis) {
            case 2200:
                return <>
                 <path d="M108.497 13.6611L108.534 36.6211" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M119.084 23.074L108.889 23.0796" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M123.143 23.0718L116.32 19.125L116.313 27.0289L123.143 23.0718Z" fill="white"/>
                </>;

            case 1800:
                return <>
                <path d="M189.252 89.1392L181.454 67.5439" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M176.087 83.8121L185.738 80.522" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M172.246 85.1222L179.977 86.6602L177.437 79.1756L172.246 85.1222Z" fill="white"/>
                </>;

            case 1650:
                return <>
                 <path d="M165.295 98.0854L157.388 76.53" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M152.104 92.8251L161.737 89.4861" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M148.269 94.1546L156.008 95.6533L153.43 88.1818L148.269 94.1546Z" fill="white"/>
                </>;

            case 1200:
                return <>
                  <path d="M7.3999 89.8499L23.3681 95.5777" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M19.8945 83.099L16.2805 92.6326" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M21.3324 79.3039L15.2204 84.282L22.6072 87.0939L21.3324 79.3039Z" fill="white"/>
                </>;

            case 1000:
                return <>
                  <path d="M40.6938 18.5676L40.7241 35.5321" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M51.2836 28.03L41.0893 27.8653" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M55.3414 28.0949L48.5847 24.0346L48.4461 31.9373L55.3414 28.0949Z" fill="white"/>
                </>;
            default:
                return null;
        }
    }

    const renderCurrentLine = (curDis) => {
        switch (curDis) {
            case 2000:
                return <>
                   <path d="M170.337 14.7803L167.751 31.5825" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M170.891 12.3725L167.124 2.68683L177.396 4.26761L170.891 12.3725Z" fill="#0EE23C"/>
                </>;
            case 1800:
                return <>
                 <path d="M201.267 75.4047L211.657 75.6413L206.257 84.5207L201.267 75.4047Z" fill="#0EE23C"/>
                <path d="M199.346 74.3457L184.869 65.6654" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                </>;
            case 1600:
            case 200:
                return <>
                 <path d="M144 86.52L149.493 102.484" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M150.265 104.623L158.137 111.408L148.325 114.833L150.265 104.623Z" fill="#0EE23C"/>
                </>;
            case 1400:
            case 0:
                return <>
                 <path d="M86.9998 106L91.9998 122" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M92.8646 124.523L100.736 131.308L90.9245 134.732L92.8646 124.523Z" fill="#0EE23C"/>
                </>;
            case 1200:
                return <>
                <path d="M23.8148 105.511L37.6846 95.8853" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M21.6876 106.829L17.2564 116.229L11.3311 107.692L21.6876 106.829Z" fill="#0EE23C"/>
                </>;
            case 1000:
                return <>
                 <path d="M31.4253 33.1768L45.6653 42.2463" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M29.4448 31.7004L19.0623 31.2482L24.6451 22.4828L29.4448 31.7004Z" fill="#0EE23C"/>
                </>;
            case 800:
                return <>
                <path d="M100.818 14.9644V31.8472" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M101 12.5L95.8038 3.5L106.196 3.5L101 12.5Z" fill="#0EE23C"/>
                </>;
            case 600:
                return <>
                  <path d="M159.818 14.2144V31.0972" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M160 11.75L154.804 2.75L165.196 2.75L160 11.75Z" fill="#0EE23C"/>
                </>;
            case 400:
                return <>
                <path d="M205.417 65.982L215.568 63.7558L212.421 73.66L205.417 65.982Z" fill="#0EE23C"/>
                <path d="M203.3 65.4058L187.178 60.3929" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                </>;
            default:
                return null;
        }
    }
    return (
        <svg width="220" height="137" viewBox="0 0 220 137" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5273_9546)">
                <path d="M204.215 46.1806C201.472 32.5812 191.75 21.5762 178.218 16.7548L177.974 16.6706L177.73 16.5941C170.926 14.4054 164.877 14.2217 159.734 14.2217C158.972 14.2217 158.202 14.2293 157.417 14.2293L155.992 14.237H155.909H155.825C142.064 14.4283 124.334 14.6962 107.61 14.9411C93.9405 15.1477 80.9419 15.339 71.3187 15.4768L70.6406 15.4844C68.1491 15.515 65.6957 15.5456 63.2727 15.6757V15.6604C61.246 15.6604 43.1805 15.8747 32.1325 22.5634L35.4012 28.0124C33.123 30.6067 31.0734 33.6144 29.2371 37.0735C27.9799 39.3312 26.7837 41.7342 25.6484 44.298L25.6255 44.2903L25.5113 44.5964C24.2769 47.4051 23.1035 50.405 21.9835 53.6576C21.5111 54.9892 21.0387 56.3667 20.5815 57.7825L5 99.4531L19.9796 105.101L21.3359 101.466C23.5531 105.598 26.5018 109.387 30.1895 112.815C39.4699 121.524 52.7885 126.315 67.7072 126.315C68.1643 126.315 68.6215 126.307 69.071 126.3L69.2844 126.292L69.4977 126.284C81.5515 125.733 89.6737 122.925 99.0836 119.672C99.9903 119.359 100.912 119.037 101.857 118.716L101.872 118.762L201.526 84.7517L198.463 75.7058C204.162 66.9432 206.288 56.4586 204.215 46.1806Z" fill="#699944"/>
                
                {renderStartArrow(distance)}
               
               {/* BG */}
                <path d="M180.062 71.6422C174.645 76.4253 166.584 78.6141 158.416 81.5222C149.41 84.614 140.625 87.637 131.428 90.7976C121.21 94.3104 110.978 97.8231 100.829 101.313C87.3882 105.752 80.7289 108.928 68.7361 109.479C59.2424 109.67 48.7277 107.206 41.6036 100.494C29.2755 89.0834 32.5671 74.0299 37.8016 59.2367C39.4778 54.3847 41.4436 49.6016 43.9504 45.1475C50.6783 32.3288 58.8233 32.4742 71.5476 32.3135C92.958 32.0073 131.047 31.4257 156.054 31.0737C162.042 31.0507 167.117 30.8594 172.611 32.6272C189.754 38.742 193.548 59.2444 180.146 71.5733L180.062 71.6422Z" fill="#D4DEAA"/>
                <path d="M82.7649 102.593C88.8497 102.593 93.7824 97.6389 93.7824 91.5272C93.7824 85.4155 88.8497 80.4609 82.7649 80.4609C76.68 80.4609 71.7473 85.4155 71.7473 91.5272C71.7473 97.6389 76.68 102.593 82.7649 102.593Z" fill="white" stroke="white" stroke-width="2.00627" stroke-miterlimit="10"/>
                <path d="M87.0002 106L92.0002 122" stroke="white" stroke-width="1.5047" stroke-miterlimit="10"/>

               {renderCurrentLine(currentDis)}

                <path d="M79.4927 96.0825L81.8999 85.9141H83.2446L83.5518 87.6074L80.9868 98H79.5425L79.4927 96.0825ZM78.2559 85.9141L80.248 96.0825L80.082 98H78.4717L75.7905 85.9141H78.2559ZM85.6685 96.041L87.6357 85.9141H90.1011L87.4282 98H85.8179L85.6685 96.041ZM84 85.9141L86.4238 96.124L86.3574 98H84.9131L82.3315 87.5991L82.6636 85.9141H84Z" fill="#836B55"/>
            </g>
            <defs>
            <clipPath id="clip0_5273_9546">
            <rect width="220" height="137" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}
const STAWTFUllRace = (props) => {
    const { trackType, currentDis, distance} = props;
    const renderStartArrow = (dis) => {
        switch (dis) {
            case 1800:
                return <>
                <path d="M114.447 109.954L114.435 92.9892" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M103.842 101.79L114.037 101.786" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M99.7835 101.793L106.606 105.74L106.614 97.8366L99.7835 101.793Z" fill="white"/>
                </>;

            case 1650:
                return <>
                 <path d="M78.8506 109.954L78.8381 92.9893" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M68.2448 101.79L78.4404 101.786" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M64.1868 101.793L71.0098 105.741L71.0176 97.8367L64.1868 101.793Z" fill="white"/>
                </>;

            case 1200:
                return <>
                  <path d="M44.6045 14.4949L44.5908 31.4594" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M55.198 22.6748L45.0024 22.6631" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M59.2563 22.6786L52.4395 18.7202L52.4195 26.6241L59.2563 22.6786Z" fill="white"/>
                </>;

            default:
                return null;
        }
    }

    const renderCurrentLine = (curDis) => {
        switch (curDis) {
            case 1400:
                return <>
                 <path d="M14.5898 63.1606L31.5543 63.1414" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M12.0919 63.2351L3.0857 68.4205L3.09816 58.0282L12.0919 63.2351Z" fill="#0EE23C"/>
                </>;
            case 1200:
                return <>
                 <path d="M59.6035 14.0002L59.5899 30.9647" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M59.4995 11.502L54.3034 2.50195L64.6957 2.50195L59.4995 11.502Z" fill="#0EE23C"/>
                </>;
            case 1000:
                return <>
                 <path d="M115.818 13.9644V30.8472" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M116 11.5002L110.804 2.50024L121.196 2.50024L116 11.5002Z" fill="#0EE23C"/>
                </>;
            case 800:
                return <>
                 <path d="M169.818 13.9644V30.8472" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M170 11.5002L164.804 2.50024L175.196 2.50024L170 11.5002Z" fill="#0EE23C"/>
                </>;
            case 600:
                return <>
                 <path d="M217.214 63.3229L226.245 58.1807L226.183 68.5728L217.214 63.3229Z" fill="#0EE23C"/>
                <path d="M215.021 63.4021L198.139 63.4065" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                </>;
            case 400:
                return <>
                <path d="M169.542 92.2004L169.542 109.083" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M169.576 111.356L174.811 120.333L164.419 120.379L169.576 111.356Z" fill="#0EE23C"/>
                </>;

            case 200:
                return <>
                <path d="M114.542 92.2004L114.542 109.083" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M114.576 111.356L119.811 120.333L109.419 120.379L114.576 111.356Z" fill="#0EE23C"/>
                </>;
            case 0:
            case 1600:
                return <>
                    <path d="M60.5 111.177L65.6962 120.177H55.3038L60.5 111.177Z" fill="#0EE23C"/>
                    <path d="M60.5 93.2986L60.5057 109.11" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                </>;
            default:
                return null;
        }
    }

    return (
        <svg width="230" height="137" viewBox="0 0 230 137" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M167.469 14.0002H62.2676C36.1769 14.1354 15 35.4047 15 61.5238C15 87.6429 36.3191 109.047 62.5236 109.047H167.732C193.816 108.912 215 87.6429 215 61.5238C215 35.4047 193.674 14.0002 167.469 14.0002ZM167.469 93.3958H62.5236C44.9449 93.3958 30.6444 79.0954 30.6444 61.5167C30.6444 43.938 44.9449 29.6376 62.5236 29.6376H167.476C185.055 29.6376 199.356 43.938 199.356 61.5167C199.356 79.0954 185.055 93.3958 167.476 93.3958H167.469Z" fill="#B58D5E"/>
            
            {renderStartArrow(distance)}
            
            {renderCurrentLine(currentDis)}

            <path d="M167.469 29.6448H62.5236C44.945 29.6448 30.6445 43.9452 30.6445 61.5239C30.6445 79.1026 44.945 93.403 62.5236 93.403H167.477C185.055 93.403 199.356 79.1026 199.356 61.5239C199.356 43.9452 185.055 29.6448 167.477 29.6448H167.469Z" fill="#E4D0B1"/>
            <path d="M60.2676 88.5936C66.3524 88.5936 71.2851 83.6391 71.2851 77.5274C71.2851 71.4157 66.3524 66.4612 60.2676 66.4612C54.1827 66.4612 49.25 71.4157 49.25 77.5274C49.25 83.6391 54.1827 88.5936 60.2676 88.5936Z" fill="white" stroke="white" stroke-width="2.00627" stroke-miterlimit="10"/>
            <path d="M56.9956 82.0828L59.4028 71.9143H60.7476L61.0547 73.6077L58.4897 84.0002H57.0454L56.9956 82.0828ZM55.7588 71.9143L57.751 82.0828L57.585 84.0002H55.9746L53.2935 71.9143H55.7588ZM63.1714 82.0413L65.1387 71.9143H67.604L64.9312 84.0002H63.3208L63.1714 82.0413ZM61.5029 71.9143L63.9268 82.1243L63.8604 84.0002H62.416L59.8345 73.5994L60.1665 71.9143H61.5029Z" fill="#836B55"/>
            <path d="M60.5 93.2986L60.5057 109.11" stroke="white" stroke-width="1.5047" stroke-miterlimit="10"/>
        </svg>

    )
}
const STTURFFullRace = (props) => {
    const { trackType, currentDis, distance} = props;

    const renderStartArrow = (dis) => {
        switch (dis) {
            case 2400:
                return <>
                <path d="M179.447 109.954L179.433 90.9936" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M168.842 101.79L179.037 101.786" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M164.783 101.792L171.605 105.74L171.613 97.8364L164.783 101.792Z" fill="white"/>
                </>;

            case 2000:
                return <>
                <path d="M98.4473 109.954L98.4348 92.9892" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M87.8415 101.79L98.0371 101.786" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M83.7825 101.792L90.6055 105.74L90.6133 97.8364L83.7825 101.792Z" fill="white"/>
                </>;
            case 1800:
                return <>
                <path d="M6.65234 13.2437L6.68514 29.6436" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M17.2679 21.3933L7.07227 21.4102" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M21.3269 21.3855L14.499 17.4463L14.5012 25.3502L21.3269 21.3855Z" fill="white"/>
                </>;

            case 1600:
                return <>
                <path d="M24.6523 13.2437L24.6851 29.6436" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M35.2679 21.3933L25.0723 21.4102" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M39.3269 21.3855L32.499 17.4463L32.5012 25.3502L39.3269 21.3855Z" fill="white"/>
                </>;

            case 1400:
                return <>
                <path d="M67.8262 13.2437L67.8601 30.2081" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M78.4417 21.3938L68.2461 21.4106" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M82.5008 21.3863L75.6729 17.447L75.675 25.3509L82.5008 21.3863Z" fill="white"/>
                </>;

            case 1200:
                return <>
                <path d="M115.826 13.2438L115.86 30.2082" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M126.442 21.3939L116.246 21.4108" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M130.501 21.3863L123.673 17.447L123.675 25.3509L130.501 21.3863Z" fill="white"/>
                </>;

            case 1000:
                return <>
                <path d="M226.447 110.3L226.435 93.3359" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M215.842 101.79L226.037 101.786" stroke="white" stroke-width="1.00313" stroke-miterlimit="10"/>
                <path d="M211.783 101.793L218.605 105.74L218.613 97.8366L211.783 101.793Z" fill="white"/>
                </>;

            default:
                return null;
        }
    }

    const renderCurrentLine = (curDis, dis) => {
        switch (curDis) {
            case 2200:
            case 400:
                return <>
                <path d="M139.543 92.2002L139.543 109.083" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M139.577 111.356L144.813 120.333L134.421 120.379L139.577 111.356Z" fill="#0EE23C"/>
                </>;

            case 2000:
            case 200:
                return <>
                <path d="M98.543 92.2002L98.5431 109.083" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M98.5771 111.356L103.813 120.333L93.4206 120.379L98.5771 111.356Z" fill="#0EE23C"/>
                </>;
            case 1800:
            case 0:
                return <>
                <path d="M59.5 111.176L64.6962 120.176H54.3038L59.5 111.176Z" fill="#0EE23C"/>
                <path d="M59.5 93.2983L59.5057 109.11" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                </>;
            case 1600:
                return dis === 1800 ? <>
                    <path d="M24.6045 14L24.5919 29.7" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                    <path d="M24.5 11.502L19.3038 2.50195L29.6962 2.50195L24.5 11.502Z" fill="#0EE23C"/>
                    </> : <>
                    <path d="M12.4741 64L3.47619 69.1998L3.472 58.8075L12.4741 64Z" fill="#0EE23C"/>
                    <path d="M14.4004 64L31.2833 64" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                    </>

            case 1400:
                return <>
                <path d="M67.8184 13.9644V30.8472" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M68 11.5L62.8038 2.5L73.1962 2.5L68 11.5Z" fill="#0EE23C"/>
                </>;
            case 1200:
                return <>
                <path d="M115.818 13.9644V30.8472" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M116 11.5L110.804 2.5L121.196 2.5L116 11.5Z" fill="#0EE23C"/>
                </>;
            case 1000:
                return <>
                <path d="M165.818 13.9644V30.8472" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M166 11.5L160.804 2.5L171.196 2.5L166 11.5Z" fill="#0EE23C"/>
                </> 
            case 800:
                return dis === 1000 ? <>
                <path d="M205.543 93.35L205.543 109" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M205.577 111.356L210.813 120.333L200.421 120.379L205.577 111.356Z" fill="#0EE23C"/>
                </> : <>
                <path d="M217.813 57.9391L226.844 52.7969L226.782 63.189L217.813 57.9391Z" fill="#0EE23C"/>
                <path d="M215.62 58.0186L198.737 58.0229" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                </>;
            case 600:
                return <>
                <path d="M179.543 90.2002L179.543 109.08" stroke="white" stroke-width="3.5" stroke-miterlimit="10"/>
                <path d="M179.577 111.356L184.813 120.333L174.421 120.379L179.577 111.356Z" fill="#0EE23C"/>
                </>;
            default:
                return null;
        }
    }
    return (
        <svg width="230" height="137" viewBox="0 0 230 137" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5273_15608)">
        <path d="M215.635 61.5C215.635 35.3085 194.275 14 168.021 14H-30V29.6367H26.6905C19.0315 38.0663 14.3578 49.2465 14.3578 61.5C14.3578 87.6915 35.7174 109 61.9715 109H260V93.3633H203.31C210.968 84.9337 215.642 73.7535 215.642 61.5H215.635ZM168.028 93.3562H61.9715C44.3595 93.3562 30.0319 79.0628 30.0319 61.4929C30.0319 43.9229 44.3595 29.6296 61.9715 29.6296H168.028C185.64 29.6296 199.968 43.9229 199.968 61.4929C199.968 79.0628 185.64 93.3562 168.028 93.3562Z" fill="#699944"/>
        
        {renderStartArrow(distance)}
        
        {renderCurrentLine(currentDis, distance)}

        <path d="M168.048 29.5H61.9516C44.333 29.5 30 43.8547 30 61.5C30 79.1453 44.333 93.5 61.9516 93.5H168.048C185.667 93.5 200 79.1453 200 61.5C200 43.8547 185.667 29.5 168.048 29.5Z" fill="#D4DEAA"/>
        <path d="M59.2676 88.5934C65.3524 88.5934 70.2851 83.6389 70.2851 77.5272C70.2851 71.4155 65.3524 66.4609 59.2676 66.4609C53.1827 66.4609 48.25 71.4155 48.25 77.5272C48.25 83.6389 53.1827 88.5934 59.2676 88.5934Z" fill="white" stroke="white" stroke-width="2.00627" stroke-miterlimit="10"/>
        <path d="M55.9956 82.0825L58.4028 71.9141H59.7476L60.0547 73.6074L57.4897 84H56.0454L55.9956 82.0825ZM54.7588 71.9141L56.751 82.0825L56.585 84H54.9746L52.2935 71.9141H54.7588ZM62.1714 82.041L64.1387 71.9141H66.604L63.9312 84H62.3208L62.1714 82.041ZM60.5029 71.9141L62.9268 82.124L62.8604 84H61.416L58.8345 73.5991L59.1665 71.9141H60.5029Z" fill="#836B55"/>
        <path d="M59.5 93.2983L59.5057 109.11" stroke="white" stroke-width="1.5047" stroke-miterlimit="10"/>
        </g>
        <defs>
        <clipPath id="clip0_5273_15608">
        <   rect width="230" height="137" fill="white"/>
        </clipPath>
        </defs>
    </svg>
    )
}
export default FullRace;