export const GET_HORSE_FORM_RECORDS = `
query HorseFormRecords($horses: [HorseFilter!]) {
  timeOffset {
    fb
    m6
    rc
    ts
  }
  horse(Horses: $horses) {
    id
    brandNumber
    name_en
    name_ch
    last10MeetingStart
    locationArrivalDate
    rating
    stableLocation {
      code
      chinese
      english
    }
    status
    startOfSeasonRating
    involvedSeasons
    horseFormRecord {
      id
      date
      season
      runnerStatus
      placeNo
      overseas
      racecourse {
        code
        chinese
        english
      }
      videoReplayURL1200mp4 {
        code
        chinese
        english
      }
      raceName {
        code
        chinese
        english
      }
      videoWinningURL1200mp4 {
        code
        chinese
        english
      }
      jockey {
        id
        code
        name_en
        name_ch
      }
      trainer {
        id
        code
        name_en
        name_ch
      }
      raceNo
      raceIndex
      numDeclStr
      venue {
        code
        chinese
        english
      }
      track {
        code
        chinese
        english
      }
      course {
        code
        chinese
        english
      }
      distance
      going {
        code
        chinese
        english
      }
      className {
        code
        chinese
        english
      }
      rating
      marginOrLBW {
        code
        chinese
        english
      }
      actualWeight
      horseWeight
      gear
      trainerPref
      trumpCard
      priority
      finalTimeOfRace
      winOdds
      barrierDrNo
      resultIndex
      winningHorse
      sectionInfos {
        sectionNo
        ruPos
        sectionTime
      }
      sectionTimeSplits {
        sectionNo
        sectionTime
      }
      sectionDistanceSplit
      incidentReport {
        code
        chinese
        english
      }
    }
    winningRecord {
      fifthPlace
      firstPlace
      fourthPlace
      seasonStakes
      secondPlace
      thirdPlace
      totalRun
      totalStakes
    }
  }
}
`;
export const GET_HORSE_RACE_FORM_RECORDS = `
query HorseRaceFormRecords($horses: [HorseFilter!]) {
  timeOffset {
    fb
    m6
    rc
    ts
  }
  horse(Horses: $horses) {
    id
    brandNumber
    name_en
    name_ch
    last10MeetingStart
    locationArrivalDate
    rating
    stableLocation {
      code
      chinese
      english
    }
    status
    startOfSeasonRating
    involvedSeasons
    horseFormRecord {
      id
      date
      season
      runnerStatus
      placeNo
      overseas
      sectionDistanceSplit
      videoReplayURL1200mp4 {
        code
        chinese
        english
      }
      raceName {
        code
        chinese
        english
      }
      videoWinningURL1200mp4 {
        code
        chinese
        english
      }
      jockey {
        id
        code
        name_en
        name_ch
      }
      trainer {
        id
        code
        name_en
        name_ch
      }
      raceNo
      raceIndex
      numDeclStr
      venue {
        code
        chinese
        english
      }
      track {
        code
        chinese
        english
      }
      course {
        code
        chinese
        english
      }
      sectionDistanceSplit
      distance
      going {
        code
        chinese
        english
      }
      className {
        code
        chinese
        english
      }
      rating
      marginOrLBW {
        code
        chinese
        english
      }
      actualWeight
      horseWeight
      gear
      trainerPref
      trumpCard
      priority
      finalTimeOfRace
      winOdds
      barrierDrNo
      resultIndex
      winningHorse
      raceTimeSplits {
        sectionNo
        gpSec
        sectionTime
      }
      sectionInfos {
        sectionNo
        ruPos
        sectionTime
      }
      sectionTimeSplits {
        sectionNo
        sectionTime
      }
      incidentReport {
        code
        chinese
        english
      }
      runnerResults {
        id
        horseName {
          code
          chinese
          english
        }
        winOdds
        horseNo
        placeNo
        rail
        resultIndex
        actualWeight
        horseWeight
        barrierDrNo
        marginOrLBW {
          code
          chinese
          english
        }
        sectionInfos {
          sectionNo
          ruPos
          sectionTime
        }
        sectionTimeSplits {
          sectionNo
          sectionTime
        }
        finalTimeOfRace
        runningDistance
        jockey {
          code
          name_en
          name_ch
        }
        trainer {
          code
          name_en
          name_ch
        }
      }
      racingComments {
        engOfficalComments
        chiOfficialComments
        engHeader
        chiHeader
      }
    }
    winningRecord {
      fifthPlace
      firstPlace
      fourthPlace
      seasonStakes
      secondPlace
      thirdPlace
      totalRun
      totalStakes
    }
  }
}
`;
export const GET_MEETINGS=`
query Meetings($status: [MeetingStatus!], $startDate: String) {
  meetings:commonMeetings(status: $status, startDate: $startDate) {
    id
    date
    venueCode
    startTime
    meetingType
    status
    races {
      runners {
        horse {
          code
        }
      }
      status
      postTime
    }
    entryRaces {
      id
      runners {
        id
        horse {
          id
          code
        }
      }
    }
  }
}
`
export const GET_TIMEOFFSET = `
query TimeOffset {
  timeOffset {
    ts
    fb
    rc
    m6
  }
}
`
export const GET_HORSE_NAME=`
query HorseName($code: [String]) {
  horses(code: $code) {
    id
    brandNumber:code
    name_en
    name_ch
    importDate
  }
}
`
export const GET_HORSE = `
query Horse($horses: [HorseFilter!]) {
  horse(Horses: $horses, LatestFormRecordOnly: true) {
    id
    brandNumber
    name_en
    name_ch
    horseImportDate
    trainer {
      id
      code
      name_ch
      name_en
      season
      upToRaceMeeting
      last10MeetingWins
      trainerWinRecords {
        firstPlace
        secondPlace
        thirdPlace
        fourthPlace
        fifthPlace
        totalRun
        stkWon
        season
        winPercent
      }
    }
    horseFormRecord {
      id
      date
      season
      runnerStatus
      overseas
      racecourse {
        code
        chinese
        english
      }
      jockey {
        id
        code
        name_en
        name_ch
      }
      runnerResults {
        id
        runningDistance
        barrierDrNo
        horseName {
          code
          chinese
          english
        }
        winOdds
        horseNo
        placeNo
        sectionTimeSplits {
          sectionNo
          sectionTime
        }
        rail
      }
      sectionTimeSplits {
        sectionNo
        sectionTime
      }
      sectionDistanceSplit
      sectionInfos {
        sectionNo
        ruPos
        sectionTime
      }
      trainer {
        id
        code
        name_en
        name_ch
      }
      raceNo
      raceIndex
      placeNo
      numDeclStr
      venue {
        code
        chinese
        english
      }
      track {
        code
        chinese
        english
      }
      course {
        code
        chinese
        english
      }
      distance
      going {
        code
        chinese
        english
      }
      className {
        code
        chinese
        english
      }
      rating
      marginOrLBW {
        code
        chinese
        english
      }
      actualWeight
      horseWeight
      gear
      trainerPref
      trumpCard
      priority
      finalTimeOfRace
      winOdds
      barrierDrNo
      resultIndex
      winningHorse
      videoReplayURL1200mp4 {
        code
        chinese
        english
      }
      raceName {
        code
        chinese
        english
      }
      videoWinningURL1200mp4 {
        code
        chinese
        english
      }
      racingComments {
        engOfficalComments
        chiOfficialComments
        engHeader
        chiHeader
      }
      raceResult
    }
    futureRaceCard {
      isHorseOwner
      barrierDrNo
      handicapWeight
      jockeyAllowance
      date
      jockey {
        id
        code
        name_en
        name_ch
      }
      meetingId
      raceId
      trainer {
        id
        code
        name_en
        name_ch
        season
        upToRaceMeeting
        last10MeetingWins
        trainerWinRecords {
          firstPlace
          secondPlace
          thirdPlace
          fourthPlace
          fifthPlace
          totalRun
          stkWon
          season
          winPercent
        }
      }
      track {
        code
        chinese
        english
      }
      meetingStatus
      raceNo
      venue {
        code
        chinese
        english
      }
      className {
        code
        chinese
        english
      }
      distance
      horseNo
      stbySeqNo
      startTime
      raceName {
        code
        chinese
        english
      }
      runnerStatus
      course {
        code
        chinese
        english
      }
      horseName{
        code
        chinese
        english
      }
      investment
      winOdds
      placeOdds
      hotFavouriteWin
      hotFavouritePla
      hfUpdateTime
      raceStatus
    }
    futureEntry {
      date
      reserveNo
      venue {
        code
        chinese
        english
      }
      meetingStatus
      sectionNo
      divisionNo
      className {
        code
        chinese
        english
      }
      track {
        code
        chinese
        english
      }
      distance
      raceNo
      runnerStatus
    }
  }
  timeOffset {
    fb
    m6
    rc
    ts
  }
}
`
export const GET_MYHORSE = `
query MyHorse($horses: [HorseFilter!]) {
  horse(Horses: $horses) {
    id
    brandNumber
    name_en
    name_ch
    last10MeetingStart
    locationArrivalDate
    rating
    stableLocation {
      code
      chinese
      english
    }
    pdfOverseasRecordsUrl {
      code
      chinese
      english
    }
    videoPreImportURL1200mp4
    status
    startOfSeasonRating
    horseFormRecord {
      id
      date
      season
      runnerStatus
      overseas
      jockey {
        id
        code
        name_en
        name_ch
      }
      trainer {
        id
        code
        name_en
        name_ch
      }
      raceNo
      raceIndex
      placeNo
      numDeclStr
      venue {
        code
        chinese
        english
      }
      track {
        code
        chinese
        english
      }
      course {
        code
        chinese
        english
      }
      distance
      going {
        code
        chinese
        english
      }
      className {
        code
        chinese
        english
      }
      rating
      marginOrLBW {
        code
        chinese
        english
      }
      actualWeight
      horseWeight
      gear
      trainerPref
      trumpCard
      priority
      finalTimeOfRace
      winOdds
      barrierDrNo
      resultIndex
      winningHorse
      videoReplayURL1200mp4 {
        code
        chinese
        english
      }
      raceName {
        code
        chinese
        english
      }
      videoWinningURL1200mp4 {
        code
        chinese
        english
        mandarin
      }
    }
    winningRecord {
      fifthPlace
      firstPlace
      fourthPlace
      seasonStakes
      secondPlace
      totalRun
      totalStakes
      thirdPlace
    }
    futureRaceCard {
      isHorseOwner
      barrierDrNo
      horseNo
      handicapWeight
      jockeyAllowance
      date
      startTime
      jockey {
        id
        code
        name_en
        name_ch
      }
      trainer {
        id
        code
        name_en
        name_ch
        season
        upToRaceMeeting
        last10MeetingWins
        trainerWinRecords {
          firstPlace
          secondPlace
          thirdPlace
          fourthPlace
          fifthPlace
          totalRun
          stkWon
          season
          winPercent
        }
      }
      track {
        code
        chinese
        english
      }
      meetingStatus
      raceNo
      venue {
        code
        chinese
        english
      }
      className {
        code
        chinese
        english
      }
      distance
      horseNo
      stbySeqNo
      startTime
      raceName {
        code
        chinese
        english
      }
      runnerStatus
      course {
        code
        chinese
        english
      }
      horseName{
        code
        chinese
        english
      }
      investment
      winOdds
      placeOdds
      hotFavouriteWin
      hotFavouritePla
      hfUpdateTime
    }
    futureEntry {
      date
      reserveNo
      venue {
        code
        chinese
        english
      }
      meetingStatus
      sectionNo
      divisionNo
      className {
        code
        chinese
        english
      }
      track {
        code
        chinese
        english
      }
      distance
      raceNo
      runnerStatus
    }
    age
    countryOfOrigin {
      code
      chinese
      english
    }
    colour {
      code
      chinese
      english
    }
    gender {
      code
      chinese
      english
    }
    horseImportDate
    importType {
      code
      chinese
      english
    }
    ownerName {
      code
      chinese
      english
    }
    trainer {
      id
      code
      name_en
      name_ch
      season
      upToRaceMeeting
      last10MeetingWins
      trainerWinRecords {
        firstPlace
        secondPlace
        thirdPlace
        fourthPlace
        fifthPlace
        totalRun
        stkWon
        season
        winPercent
      }
    }
    sire
    dam
    sireOfDam
    sameSire {
      id
      brandNumber
      name_en
      name_ch
    }
    stableLocationHistories {
      from
      to
      time
    }
    vetRecords {
      vetDetail_en
      vetDetail_ch
      incidentDate
      passedDate
    }
    horseTraining {
      date
      venue {
        code
        chinese
        english
      }
      trainingType
      track {
        code
        chinese
        english
      }
      rider {
        code
        chinese
        english
      }
      gear
      workout {
        code
        chinese
        english
      }
      fullWorkOut {
        code
        chinese
        english
      }
      batchNumber
      totalTime
      going {
        code
        chinese
        english
      }
      distance
      bestTime
      videoURL1200mp4 {
        code
        chinese
        english
      }
    }
    involvedSeasons
    horseSeasonStats {
      firstPlace
      secondPlace
      thirdPlace
      fourthPlace
      fifthPlace
      totalRun
      stakeWon
      season
    }
  }
  timeOffset {
    fb
    m6
    rc
    ts
  }
}
`;

export const GET_POST_RACE_WCAS_CONFIG = `
  query PostRaceAnalysis {
    postRaceAnalysis {
      id
      enable
      name_ch
      name_en
      description_en
      description_ch
    }
  }
`
