import { useEffect, useState, useRef } from 'react'
import './css/SectionalTabs.css'
import { useTranslation } from "react-i18next";

const SectionalTabs = (props) => {
    const { horseName } = props;
    const { t, i18n } = useTranslation();
    const [selectVal, setSelectVal] = useState(0)
    const innerRef = useRef(null)
    const innerText1 = useRef(null)
    const innerText2 = useRef(null)
    const handleClickSelect = (val) => {
        setSelectVal(val)
        props.clickTab(val)
    }
    useEffect(() => {
        if (selectVal === 0) {
            innerRef.current.style.left = 2 + 'px';
            innerText1.current.style.color = '#836B55'
            innerText2.current.style.color = '#84909F'
        }
        if (selectVal === 1) {
            innerRef.current.style.left = innerRef.current.clientWidth + 4 + 'px'
            innerText1.current.style.color = '#84909F'
            innerText2.current.style.color = "#836B55"
        }
    }, [selectVal])
    return (
        <div className='sectional-tab-content'>
            <div id='RD_FR_detail_recentTab' ref={innerText1} onClick={() => handleClickSelect(0)} className='sectional-tab-inner'>{horseName}</div>
            <div id='RD_FR_detail_showAllTab' ref={innerText2} onClick={() => handleClickSelect(1)} className='sectional-tab-inner'>{t('Lbl_Race')}</div>
            <div ref={innerRef} className='sectional-select-inner' />
        </div>
    )
}
export default SectionalTabs