import React, { useState } from "react";
import MultiPicker from "./MultiPicker";
import Picker from "./Picker";
import { Box } from "@mui/material";

import "./picker-style.css";

export default function PickerComponent({selectedValue, onValueChange, idPrefix, onScrollChange, PList = []}) {
  // const [value, setValue] = useState(["1", "11"]);

  // const onChange = (value) => {
  //   console.log("onChange", value);
  //   setValue(value);
  // };

  // const onScrollChange = (value) => {
  //   console.log("onScrollChange", value);
  // };

  return (
    <div style={{ margin: '0 16px'}}>
      <MultiPicker
        selectedValue={selectedValue}
        onValueChange={onValueChange}
        onScrollChange={onScrollChange}
      >
        <Picker indicatorClassName="my-picker-indicator">
          {
            PList.map((i, idx) => <Picker.Item className={`my-picker-view-item fontsizeChange_${idx}`} id={`${idPrefix}_${idx}`}
            style={{ 
              fontSize: Math.abs(idx - selectedValue) >= 1 ? 14: '',
              color: Math.abs(idx - selectedValue) >= 1 ? '#84909F': '#0A2240'
            }}
            value={String(idx)}>
            {i}
          </Picker.Item>)
          }
        </Picker>
      </MultiPicker>
    </div>
  );
}
