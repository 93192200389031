import { Avatar } from "@mui/material";
import { useEffect, useState } from "react"
import Jersey from '../../img/Jersey.svg'
import SlikPlaceHolder from '../../img/HeadtoHead/silkPlaceholder.png'
function TrainerAvatar({type,src,className}){
    const[isImgLoading,setImgIsLoading] = useState(true);
    const[isImgError,setImgIsError] = useState(false);
    const handleImageLoad = () => {
        setImgIsLoading(false);
    };
    const handleImageError = () => {
        setImgIsError(true);
        setImgIsLoading(false);
    };
    return( 
        <Avatar className={className}  
            src={isImgLoading?type=='trainer'?Jersey:SlikPlaceHolder:isImgError?type=='trainer'?Jersey:SlikPlaceHolder: src} 
            onLoad = {handleImageLoad}
            onError={handleImageError}
        />
    )

}
export default TrainerAvatar