import { Divider, Grid, Paper,Typography,Avatar, Drawer } from '@mui/material';
import { useState ,useRef,useEffect,useLayoutEffect} from 'react';
import styles from './Css/TrainerProfile.module.css'
import moment from 'moment';
import { useTranslation, Trans } from "react-i18next";
import Owner from "../img/owner.png";
import html from "../BER.txt"
import Jersey from '../img/Jersey.svg'
import {changePrice2money, isNull} from '../utils';

function TrainerProfile(props){
    const{t,i18n} = useTranslation();
    const [showDetail,setShowDetail] = useState(false)
    const typographyRef = useRef(null);
    const [showMore, setShowMore] = useState(false);
    const [htmlContent , setHtmlContent] = useState('');
    const[isImgLoading,setImgIsLoading] = useState(true);
    const[isImgError,setImgIsError] = useState(false);

    console.log('【----propsdata】',props)

    const trainer = props.data?.trainer
    const trainerName = trainer && trainer[`name_${i18n.language}`];

    const trainerWinRecords = trainer?.trainerWinRecords;
    const last10MeetingWins = isNull(trainer?.last10MeetingWins);
    const upToRaceMeeting = trainer?.upToRaceMeeting && moment(trainer?.upToRaceMeeting, "YYYY-MM-DD").format('DD/MM/YYYY');

    const firstPlace = isNull(trainerWinRecords?.firstPlace);
    const secondPlace = isNull(trainerWinRecords?.secondPlace);
    const thirdPlace = isNull(trainerWinRecords?.thirdPlace);
    const fourthPlace = isNull(trainerWinRecords?.fourthPlace);
    const fifthPlace = isNull(trainerWinRecords?.fifthPlace);

    const Season = t('Lbl_WinMoment_Season');
    const season = (trainer?.season) ?  `${trainer?.season} ${Season}`: '--';
    const stkWon = '$' + `${trainerWinRecords?.stkWon ? changePrice2money(trainerWinRecords?.stkWon || 0, 0, ".", ",") : 0}`;
    const winPercent = trainerWinRecords?.winPercent && (trainerWinRecords?.winPercent*100).toFixed(2) + '%' || '0.00%';
    const totalRun = isNull(trainerWinRecords?.totalRun);


    useEffect(() => {
        if(showDetail){
            //const typographyElement = typographyRef.current;
          
            // console.log(typographyElement)
            // const lineHeight = parseFloat(window.getComputedStyle(typographyElement).lineHeight);
            // const numLines = Math.round(typographyElement.clientHeight / lineHeight);
            // console.log(numLines)
            // setShowMore(numLines <= 5); // Adjust the number of lines based on '-webkit-line-clamp'
            // const words = typographyElement.innerText.split(' ');
            // let lastLineWords = words.slice(0, numLines);
            // setLastLineText(lastLineWords.join(' '));
        }

    }, [showDetail]);

    useEffect(() => {
        const fetchTextFile = async () => {
            try{
                let htmlData = window.globalConfig.SITE_DOMAIN+window.globalConfig.TRAINER_PROFILE_PATH.replace('{{lang}}',i18n.language=='en'?'english':'chinese')+trainer.code+".txt";
                const response = await fetch(htmlData);
                if (!response.ok) {
                    const message = `An error has occured: ${response.status}`;
                    throw new Error(message);
                }
                console.log(response)
                const text = await response.text();
  
                //   const editedText = `<div style="max-width:90vw">${text}</div>`
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(text, 'text/html');

                    // Find the table element
                    const tableElement = doc.querySelector('table');

                    // Modify the table width
                    tableElement.style.width = '90vw';
                    tableElement.style.padding = '0'

                    // Serialize the modified HTML content back to a string
                    const serializer = new XMLSerializer();
                    const modifiedHtml = serializer.serializeToString(doc);
                setHtmlContent(modifiedHtml);
            }catch(error){
                console.log("Error:",error)
            }
        };

        fetchTextFile();
    }, [trainer]);

    useEffect(()=>{
        console.log("html.",html)
    },[htmlContent])

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    const handleImageLoad = () => {
        setImgIsLoading(false);
    };
    const handleImageError = () => {
        setImgIsError(true);
        setImgIsLoading(false);
    };
    return(
        <Drawer
            className='TrainerP_Drawer'
            anchor={"bottom"}
            open={props.showTrainer}
            // onClose={() => setOpen(false)}
        > 
            <div className = {styles.Base} onClick={()=>props.setShowTrainer(false)} >
                <div className={styles.FakeBaseFull} onClick={(e)=>{e.stopPropagation()}}>
                    <div className={styles.Container}>
                        <div className={styles.Header} style={{top: 0}} id="TP_DrawerTitle">
                            <div>{t('Lbl_TF_Profile')}</div>
                        <div/>
                        <svg className={styles.CloseButton} id="TP_DrawerClose" onClick={()=>props.setShowTrainer(false)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7215 5.27842C18.3503 4.90719 17.7484 4.9072 17.3772 5.27842L12 10.6556L6.62285 5.27842C6.25163 4.9072 5.64976 4.90719 5.27854 5.27842C4.90732 5.64964 4.90732 6.25151 5.27854 6.62273L10.6557 11.9999L5.27842 17.3773C4.9072 17.7485 4.90719 18.3504 5.27842 18.7216C5.64964 19.0928 6.2515 19.0928 6.62272 18.7216L12 13.3443L17.3773 18.7216C17.7485 19.0928 18.3504 19.0928 18.7216 18.7216C19.0928 18.3504 19.0928 17.7485 18.7216 17.3773L13.3443 11.9999L18.7215 6.62273C19.0927 6.25151 19.0927 5.64964 18.7215 5.27842Z" fill="black"/>
                        </svg>
                        </div>
                        {/* <Divider/> */}
                        <div className = {`${styles.MainContent} TabletBaseContent` }>
                            <div className = {styles.TrainerTopSection}>
                                <Grid xs={12} mx={2} my={3}container>
                                    <Grid item pl={1} className={styles.AvatarContainer}>
                                    <Avatar 
                                        id="OwnerAvatar"
                                        className = {styles.Avatar}
                                        src={isImgLoading?Jersey:isImgError?Jersey: `${window.globalConfig.SITE_DOMAIN}${window.globalConfig.TRAINER_IMAGE_PATH}${trainer?.code}.jpg`} 
                                        onLoad = {handleImageLoad}
                                        onError={handleImageError}
                                    />
                                    </Grid>
                                    
                                    <Grid item className={styles.NameContainer}>
                                        <Typography id="TP_TrainerName" className = {`${styles.NameText} Title/Title1-Bold` }>{trainerName}</Typography>
                                    
                                    </Grid>
                                </Grid>
                            </div>
                            {/* <Divider/> */}
                            <div className={styles.ProfileContent}>
                        
                            <Typography className = {`${styles.NameText} Title/Title1-Bold` } id="TP_Season">{season}</Typography> 
                            <Typography className = {`${styles.SubText} Label3-Regular` } id="TP_RaceMeeting">{t('Lbl_TF_RaceMeeting').replace('{{Date}}', upToRaceMeeting)}</Typography> 
                            <Grid container direction="row" justifyContent="start" alignItems="center" my={2}>
                                <Grid item xs={5} >
                                    <Typography className={`${styles.title} Label3-Regular`} >{t('Lbl_TF_StakesWon')}</Typography>
                                    <Typography className={`${styles.content} Number/Number2-Bold`} id = "HorseStat_SeasonStakes">{stkWon}</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{background:'#CED3D9',opacity:'0.3'}}  />
                                <Grid item xs={4} ml={1}>
                                    <Typography className={`${styles.title} Label3-Regular`}>{t('Lbl_TF_TotalRunners')}</Typography>
                                    <Typography className={`${styles.content} Number/Number2-Bold`} id = "HorseStat_TotalStakes" >{totalRun}</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem style={{background:'#CED3D9',opacity:'0.3'}} />
                                <Grid item xs={2} ml={1}>
                                    <Typography className={`${styles.title} Label3-Regular`}>{t('Lbl_TF_WinRate')}</Typography>
                                    <Typography className={`${styles.content} Number/Number2-Bold`} id = "HorseStat_CurrentRating" >{winPercent}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} my ={1} className={styles.TrophiesContainer}>
                                <Grid container item xs = {12}  className={styles.Wins}> 
                                    <Typography className={`${styles.TrophyDesText} Label2-Medium`} id="TP_WinNumInPastTenDays">{t('Lbl_TF_WinNumInPastTenDays')}:</Typography>
                                    <Grid item xs/>
                                    <Typography className={`${styles.TrophyText} Number/Number2-Bold`} id="TP_WinNumInPastTenDays_value">{last10MeetingWins}</Typography>
                                </Grid>
                                <Grid container item xs={12} className={styles.Trophies}>
                                    
                                    <Typography className={`${styles.TrophyDesText} Label2-Medium`} id="TP_WinNum">{t('Lbl_TF_WinNum')}</Typography> 
                                    <Grid item xs/>
                                    <Typography  className={`${styles.TrophyText} Number/Number2-Bold`} id = "HorseStat_NoOfPlacesFirst"  style={{textAlign:'center'}}>{firstPlace} 
                                        <div className={styles.Gold}/>
                                    </Typography>
                                    <Typography className={`${styles.TrophyText} Number/Number2-Bold`}> &nbsp;/&nbsp;
                                    </Typography>
                                    <Typography className={`${styles.TrophyText} Number/Number2-Bold`} id = "HorseStat_NoOfPlacesSecond" style={{textAlign:'center'}}>{secondPlace} 
                                        <div className={styles.Sliver}/>
                                    </Typography>
                                    <Typography className={`${styles.TrophyText} Number/Number2-Bold`}>&nbsp;/&nbsp;</Typography>
                                    <Typography className={`${styles.TrophyText} Number/Number2-Bold`} id = "HorseStat_NoOfPlacesThird" style={{textAlign:'center'}}>{thirdPlace} 
                                        <div className={styles.Bronze}/>
                                    </Typography>
                                    <Typography className={`${styles.TrophyText} Number/Number2-Bold`}> &nbsp;/&nbsp;</Typography>
                                    <Typography className={`${styles.TrophyText} Number/Number2-Bold`} id = "HorseStat_NoOfStarts" style={{textAlign:'center'}}>{fourthPlace} 
                                        <div className={styles.Blue}/>
                                    </Typography>
                                
                                </Grid>
                            </Grid>

                            <Grid container  my={2} mt={3}>
                                <Divider variant="middle" style={{ background: '#CED3D9', opacity: 0.3, flex: 1, margin: 0 }} />
                            </Grid>

                            {/* {showDetail&&<>
                            <div className={styles.ShowButton} id="MoreButton" onClick={()=>{setShowDetail(!showDetail)}}>   
                                <Typography className={styles.ShowTitle}>{t('Lbl_More')}</Typography>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1925 11.6925C17.6025 11.2824 17.6025 10.6176 17.1925 10.2075C16.7824 9.79749 16.1176 9.79749 15.7075 10.2075L12.45 13.4651L9.19246 10.2075C8.78241 9.79749 8.11759 9.79749 7.70754 10.2075C7.29749 10.6176 7.29749 11.2824 7.70754 11.6925L11.7075 15.6925C12.1176 16.1025 12.7824 16.1025 13.1925 15.6925L17.1925 11.6925Z" fill="black"/>
                                </svg>
                            </div>
                            </>} */}
                            <>
                                <div id="extra_content" className={styles.ExtraContent} dangerouslySetInnerHTML={{ __html: htmlContent }}>
                                    {}
                                    {/* <Divider/> 
                                    <div className={styles.ExtraContentContainer} >
                                        <Typography className = {styles.NameText}>Background </Typography> 
                                        <Typography className =  {styles.ExtraContentText} ref={typographyRef} >Pierre Ng entered The Hong Kong Jockey Club’s system in 2007 as a work rider for his father, Peter Ng, before he was promoted to assistant trainer for Paul O’Sullivan in 2013. Under O’Sullivan, Ng travelled with Aerovelocity overseas to Japan and Singapore in 2015 for consecutive Group 1 wins in the Takamatsunomiya Kinen</Typography> 
                                        {showMore && (
                                            <Typography variant="body1" className={styles.moreLink} onClick={toggleShowMore}>
                                            ...More
                                            </Typography>
                                        )}
                                    </div>
                                
                                    <Divider/>
                                    <div className={styles.ExtraContentContainer} >
                                        <Typography className = {styles.NameText}>Hong Kong Career Wins (at start of 2023/24 season) </Typography> 
                                        <Typography className = {styles.ExtraContentText}>Up to Race Meeting of 18/10/2023 </Typography> 
                                    </div> */}

                                </div>
                            </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </Drawer> 
        
    )
}

export default TrainerProfile;
