import { useEffect, useState } from 'react'
import '../Css/NewsCard.css'
import { useTranslation } from "react-i18next";
import entryIcon from "../img/NewsIcon/entryIcon.svg"
import reserveIcon from "../img/NewsIcon/reserveIcon.svg"
import nextRaceIcon from "../img/NewsIcon/nextRaceIcon.svg"
import winningIcon from "../img/NewsIcon/winningIcon.svg"
import scratchedIcon from "../img/NewsIcon/scratchedIcon.svg"
import vetIcon from "../img/NewsIcon/VetIcon.png"
import promotionIcon from "../img/NewsIcon/promotionIcon.svg"
import showIcon from "../img/news_show_detail.svg"
import hiddenIcon from "../img/news_hidden_detail.svg"
import LinesEllipsis from 'react-lines-ellipsis';
import { shareToMobile,DownloadForm } from '../utils';
import moment from 'moment/moment';
import 'moment/locale/zh-cn'
import 'moment/locale/en-nz'
import { isEmpty } from '../utils';
function NewsCard(props) {
    const { newsContent, newsType, newsOperator, justOne, entry, data,pathCH,pathEN } = props;
    const { t, i18n } = useTranslation();
    // const [showMore, setShowMore] = useState(false)
    // const [line, setLine] = useState("2")
    const getNewsTypeIcon = () => {
        const iconObj = {
            "entry": entryIcon,
            "reserve": reserveIcon,
            "declared": nextRaceIcon,
            "winningmoment": winningIcon,
            "scratched": scratchedIcon,
            "vet": vetIcon,
            "promotion": promotionIcon,
        }
        return iconObj[newsType]
    }
    const handleOperator = (type) => {
        const date = i18n.language === 'ch' ? moment(data?.date, 'YYYY-MM-DD HH:mm').locale('zh-cn').format('ddddM月D日') : moment(data?.date, 'YYYY-MM-DD HH:mm').format('dddd (Do MMMM)');
        const lang = i18n.language === 'ch' ? 'Chinese' : 'English';
        if (type === 'entry') {
            shareToMobile(t("Lbl_Entries_Tip", {
                Name: data?.horseName,
                Date: date,
                Class: data?.className,
                Distance: data?.distance,
                Racecourse: data?.raceCourse
            }), window.globalConfig.ENTRYCARD_URL.replace('{{lang}}', lang))
        }
        if (type === 'declared') {
            shareToMobile(t("Lbl_NextRaces_Tip", {
                Name: data?.horseName,
                Date: date,
                Class: data?.className,
                Distance: data?.distance,
                Racecourse: data?.raceCourse,
                RaceNo: data?.raceNo,
                Jockey: data?.jockey,
                HorseNo: data?.horseNo
            }), window.globalConfig.RACECARD_URL.replace('{{lang}}', lang))
        }
        if (type === 'reserve') {
            shareToMobile(t("Lbl_Reserve_Tip", {
                Name: data?.horseName,
                ReserveNo: data?.reserveNo,
                Date: date,
                Class: data?.className,
                Distance: data?.distance,
                Racecourse: data?.raceCourse
            }), window.globalConfig.ENTRYCARD_URL.replace('{{lang}}', lang))
        }
        if (type === 'winningmoment') {
            const path = '/top-headlines/' + moment(data?.date, "YYYY-MM-DD").format('YYYYMMDD') + '-' + data?.raceNo;
            console.log('[path-----]', path)
        }
        if (type === 'promotion') {
            DownloadForm(i18n.language === 'ch'?pathCH:pathEN);
        }
        console.log("click-", type)
    }
    return (
        <div className={`news-card-container ${justOne ? 'card-just-one' : ""}`} id={props.id}>
            <div className={`${props.isNoti?`news-content-wrapper-noti`:`news-content-wrapper`} ${props.className}-ch-min-height ${entry === 'notification' && 'card-bottom'}`}>
                <img className='news-type-img' alt='' src={getNewsTypeIcon()} />
                <div className="news-content-wrap">
                    <div className={`${props.isNoti?`news-content-noti`:`news-content`} Body/Body1-Regular`}>
                        {/* <span id={`newsdatete_${newsType}`} className="Label3-Medium">{newsdatete}</span> */}
                        <LinesEllipsis
                            id={"NewsContent_" + newsType}
                            text={t(newsContent)}
                            maxLine={entry === 'ownerProfile' ? 3 : 999}
                        
                            trimRight
                            basedOn='letters'
                        />
                        <p ></p>

                    </div>
                    <div style={
                        (newsType==="declared" && (isEmpty(data?.jockey) || data?.jockey==="--")) || newsType === "winningmoment" 
                        ? {display:"none"} : {}} 
                        id={`NewsBtn_${newsType}`} 
                        onClick={() => handleOperator(newsType)} 
                        className={`${props.className}-news-type ${props.isNoti?`news-type-text-noti`:`news-type-text`}`}>
                        {t(newsOperator)}
                    </div>
                </div>
                {/* <img id={`news_show_more_${newsType}`}onClick={() => handleClickShowMore()} className='show-more' alt='' src={showMore ? hiddenIcon : showIcon} /> */}
            </div>
        </div >
    )
}
export default NewsCard;