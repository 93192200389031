import styles from '../HorseProfile/Css/MovementRecord.module.css';
import { Divider, Grid, Paper, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useTranslation, Trans } from "react-i18next";
import { useLocation } from 'react-router-dom';
import Header from '../Component/Header';
function MovementRecords(props) {
    const location = useLocation();
    const { data, lang } = location.state
    useEffect(() => {
        //sortDate();
        console.log(props)

    }, []
    )
    const { t, i18n } = useTranslation();
    const [langCode, setLangCode] = useState('en');
    const locationMapping = (code) => {
        switch (code) {
            case 'HK':
                if (lang == 'ch') {
                    return '香港'
                } else {
                    return 'Hong Kong'
                }

            case 'CH':
                if (lang == 'ch') {
                    return '從化'
                } else {
                    return 'Conghua'
                }
            default:
                return "--"
        }
    }
    function sortDate() {
        if (data !== null) {
            data.MovementRecords = data.MovementRecords.sort((a, b) => {
                return moment(b.ArrivalDate, "DD/MM/YYYY").format("YYYYMMDD") - moment(a.ArrivalDate, "DD/MM/YYYY").format("YYYYMMDD")
            })
            console.log(data.MovementRecords)
        }

    }
    useEffect(() => {
        // props.setHeaderTitle(t("Lbl_MovementRecord"))

        if (lang == 'en') {
            i18n.changeLanguage('en')
            setLangCode('en')
        }
        if (lang == 'ch') {
            i18n.changeLanguage('ch')
            setLangCode('ch')
        }
    }, [])
    return (
        <>
            <Header title='Lbl_MovementRecord' />
            <Grid container p={2} style={{ zIndex: 1, background: "#F2F3F5", height: window.innerHeight - 60, overflowY: "scroll"}} className="TabletBaseContainer">
                <Box xs={12} className={styles.Table} id="Movement_Table">
                    <Grid item container justifyContent="center" alignItems="center" xs={12} className={styles.TableHeader} id="Movement_Headers" py={0.5}>
                        <Grid item xs={4} className={styles.TableHeaderCell}>
                            <Typography className="Label2-Medium fontWeight700 fontSize14">{t("Lbl_ArrivalDate")}</Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem className={styles.DividerHeader} />
                        <Grid item xs={4} className={styles.TableHeaderCell}>
                            <Typography className="Label2-Medium fontWeight700 fontSize14">{t("Lbl_From")}</Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem className={styles.DividerHeader} />
                        <Grid item xs={4} className={styles.TableHeaderCell}>
                            <Typography className="Label2-Medium fontWeight700 fontSize14">{t("Lbl_To")}</Typography>
                        </Grid>
                    </Grid>
                    {data?.map((item, index) =>
                        <>
                            <Grid item xs={12} container py={0.5} id={"Movement_Row" + index}>
                                <Grid item xs={4} className={styles.TableHeaderCell}>
                                    <Typography className="Label2-Medium fontSize14"id={"Profile_ArrivalDate" + index}>{item.time ? moment(item.time, 'YYYY-MM-DD').format('DD/MM/YYYY'): ''}</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem className={styles.DividerCell} />
                                <Grid item xs={4} className={styles.TableHeaderCell}>
                                    <Typography className="Label2-Medium fontSize14" id={"Profile_From" + index}>{locationMapping(item.from)}</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem className={styles.DividerCell} />
                                <Grid item xs={4} className={styles.TableHeaderCell}>
                                    <Typography className="Label2-Medium fontSize14" id={"Profile_To" + index}>{locationMapping(item.to)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid xs={12}>
                                <Divider flexItem className={styles.DividerVer} />
                            </Grid>
                        </>
                    )}
                </Box>

            </Grid>
        </>
    )
}
export default MovementRecords;