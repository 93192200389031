import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import downloadImg from "../img/download_24px.svg"
import navigateTop from "../img/navigate_top.svg"
import navigateBottom from "../img/navigate_bottom.svg"
import "./Css/OwnerProfile.css"
import { useParams } from 'react-router-dom';
import DummyData from '../Config/DummyData/DummyData.json';
import HorseAvatar from "./Component/HorseAvatar";
import NewsCard from "../Component/whatNewsCard";
import MCLloyd from "./Component/MCLloyd";
import { Typography } from "@mui/material";
import { DownloadForm, ifShowMCLloyd, shareToMobile, isNull } from "../utils";
import RacingFixture from "./Component/RacingFixture";
import moment from "moment";
import Header from '../Component/Header'
import dayjs from 'dayjs';
// import HeadToHead from "./Component/HeadToHead";
import HorseContext from '../utils/horseContext';
import shareIcon from '../img/shareIcon.svg'
import { getHorse,getMeeting,getHorseName,getTimeOffset } from '../request/requestList'
import { groupBy } from 'lodash';
import LoadingOverlay from "../Component/loading/loading-overlay"

function LandingPage(props) {
    const { t, i18n } = useTranslation();
    const { id, lang } = useParams();
    const [langCode, setLangCode] = useState('en');
    const [showMore, setShowMore] = useState(true)
    const [horseData, setHorseData] = useState(null);
    const [newsRenderData, setNewsRenderData] = useState([]);
    const [urlData, setUrlData] = useState([]);
    const navigate = useNavigate();
    const useDummy = false;
    const { businessDate, setbusinessDate,cuno,setCuno} = useContext(HorseContext);
    const [data, setData] = useState([])
    const [meetingData, setMeetingData] = useState([])
    const [promotion,setPromotion] = useState([])
    const [Load,setLoad] = useState(true);
    function extractHorse(obj){
        if(obj==null) return;
        return Object.values(obj).reduce((horses, value) => {
            if (Array.isArray(value)) {
              return horses.concat(extractHorse(value));
            } else if (typeof value === 'object' && value !== null) {
              if (value.hasOwnProperty('horse')) {
                horses.push(value.horse.code);
              }
              return horses.concat(extractHorse(value));
            }
            return horses;
          }, []);
    }
    useEffect(() => {
        let horseWithoutMeeting=[];
        //call graphql
            console.log(urlData.length, 'data')
   
            getTimeOffset({}, t).then((res) => {
                const date = res?.timeOffset?.ts;
                const formatDate = dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD");
                date && setbusinessDate(formatDate);
                
                if (formatDate?.length) {
                      // get meeting first 
                    getMeeting(
                        { 
                            status: ["DRAFT","ENTRY","DECLARED","STARTED","DEFINED","CLOSED","ABANDON","ABANDON_PARTIAL"],
                            startDate: dayjs(formatDate).subtract(2, 'day').format('YYYY-MM-DD') 
                        }
                    ,t).then((res) => {

                        if (urlData.length) {
                            const meetings = res?.meetings?.filter(item => (item.venueCode == 'HV' || item.venueCode == 'ST' || item.venueCode == "CH" ))
                            console.log(meetings,'mee')
                            // check if the horse have entries
                            setMeetingData(meetings)
                            let HorseWithMeeting = extractHorse(meetings)
                            console.log(HorseWithMeeting)
                            const myHorseWithMeeting = urlData?.filter(item=>HorseWithMeeting?.includes(item?.brandNumber));
                            const myHorseWithoutMeeting = urlData?.filter(item=>!myHorseWithMeeting?.includes(item))
                            console.log(myHorseWithMeeting,'myhores');
                            console.log(myHorseWithoutMeeting,'myhores');
                            horseWithoutMeeting = myHorseWithoutMeeting.map(item=>{return item.brandNumber})
                            callApi(myHorseWithMeeting)
                        }else{
                            //handle abondon meeting when no horse 
                            const meetings = res?.meetings?.filter(item => (item.venueCode == 'HV' || item.venueCode == 'ST' || item.venueCode == "CH" )&&(item.status=="STARTED"||item.status=="DECLARED"||item.status=="DEFINED"))
                            setMeetingData(meetings)
                        }
                    })
                    
                    // meetings = meetings.data.meetings.filter(item => (item.venueCode == 'HV' || item.venueCode == 'ST' || item.venueCode == "CH" ));
                    //console.log(meetings)
                    async function callApi(withMeetingHorseData){
                        const batchSize = 9; // Number of data sets to handle per API call
                        const numCalls = Math.ceil(withMeetingHorseData.length / batchSize); // Calculate the number of API calls required
                        const apiCalls = []; // Array to store the API call promises
                        //set the data to session storage
                        //sessionStorage.setItem("withMeetingHorseData", JSON.stringify(urlData));
                        for (let i = 0; i < numCalls; i++) {
                            const startIndex = i * batchSize;
                            const endIndex = startIndex + batchSize;
                            const batchData = withMeetingHorseData.slice(startIndex, endIndex);
            
                            // Push the API call promise to the array
                            apiCalls.push(
                                getHorse({ horses: batchData }, t).then((res) => {
                                    return res
                                })
                            );
                        }
                        console.log(horseWithoutMeeting,'ss')
                        if (horseWithoutMeeting?.length) {
                            apiCalls.push(
                                getHorseName({code:horseWithoutMeeting},t).then((res)=>{
                                    return res
                                }
                            ))
                        }

                        const results = await Promise.allSettled(apiCalls)
                        const combinedResults = results.filter(result => result.status === 'fulfilled').map(result => result.value.horse || []).reduce((acc,horse)=>{return {horse:[...acc.horse,...horse]};},{horse:[]});
                        let horseWithName =results.filter(result => result.status === 'fulfilled').map(result => result.value.horses || []).reduce((acc,horse)=>{return {horse:[...acc.horse,...horse]};},{horse:[]})
                        const mapImportDateHorseList = combinedResults.horse.map(i => {
                            i.importDate = i.horseImportDate;
                            return i
                        })
                        horseWithName = [...mapImportDateHorseList,...horseWithName.horse]


                        const newData = horseWithName?.map(item => {
                            const ownerType = urlData.map(i => {
                                if (i.ownerType === 'D') i.ownerType = 'P';
                                return i;
                            }).find(item2 => item.brandNumber == item2.brandNumber);

                            item.importDate = moment(item.importDate, "YYYY-MM-DD").format("YYYY-MM-DD") ;
                            return { ...item, ...ownerType };
                        })


                        // The horses should be displayed in the following sequence:

                        // Sole Owner (sorted by import date, latest first)
                        // Partnership (sorted by import date, latest first)
                        // Syndicate (sorted by import date, latest first)
                        // The rank order > horse import date (latest first)

                        // Note: Horses with owner type D are treated as P

                        function compare(property) {
                            return (a, b) => {
                                const rules = ['O', 'P', 'S'];
                                return rules.indexOf(a[property]) - rules.indexOf(b[property]);
                            } 
                        }

                        const groupSort = groupBy(newData.sort(compare('ownerType')), 'ownerType');
                        for (let t in groupSort) {
                            groupSort[t].sort((a, b) => a.importDate < b.importDate ? 1 : -1)
                        }
                        let sortRes = [];
                        for (let t in groupSort) {
                            sortRes = [...sortRes, ...groupSort[t]]
                        }

                        setHorseData(sortRes)

                        setData(combinedResults);
                        setLoad(false);
                    }
                    //Batch call api if the horse number is > then 9
                    
                    //callApi();
                }        
            })
        
    }, [urlData])
    // if (loading) {
    //     console.log('Loading...');
    //   } else if (error) {
    //     console.log('Error:', error.message);
    //   } else {
    //    console.log(data?.horse)
    //     // setHorseData(data.horse)
    //   }
    //     const GET_HORSE = gql`
    //     query Horse($horseId: ID!) {
    //       horse(id: $horseId) {
    //         id
    //         age
    //         brandNumber
    //         name_en
    //         name_ch
    //         }
    //     }
    //   `;
    // function TryGetHorse({jsonData}) {
    //     const modifiedData = jsonData.map(obj => {
    //         const { brandNo, ...rest } = obj;
    //         return { brandNumber: brandNo, ...rest };
    //     });
    //     console.log(modifiedData)
    //     const { loading, error, data } = useQuery(GET_HORSE, {
    //         variables: {
    //             horses: modifiedData
    //         },
    //     });
    //     if (loading) return 'Loading...';
    //     if (error) return `Error! ${error.message}`;
    //     return (<div>
    //         {data.horse.map(({ id, name_cn,name_en,brandNumber }) => (
    //             <div>{id} {name_cn}{name_en}{brandNumber}</div>
    //         ))}
    //     </div>)

    // }
    useEffect(() => {
        if (data) {
            // const newData = data.horse?.map(item => {
            //     const ownerType = urlData.find(item2 => item.brandNumber == item2.brandNumber);
            //     return { ...item, ...ownerType };
            // })
            // //console.log("URL", newData)
            // //console.log("URLD", data)
            // //const newMeeting = data?.meetings?.filter(item => (item.venueCode == 'HV' || item.venueCode == 'ST' || item.venueCode == "CH" )&&item.status!=="CLOSED"&&item.status!=="ABANDON"&&(item.status=="STARTED"||item.status=="DECLARED"||item.status=="DEFINED"))
            // //console.log(newMeeting, 'newmee')
            // //setMeetingData(newMeeting)
            // setHorseData(newData)
            getRenderData(data.horse)
        }

    }, [data])

    const clickDownloadForm = (fileName) => {
        let url = ''
        switch (fileName) {
            case "Original Replacement Permit":
                url = `https://member.hkjc.com/member/common/pdf/horse-owner-download-form/replacement-permit-org-permit.pdf`
                break;
            case "Normal Replacement Permit":
                url = `https://member.hkjc.com/member/common/pdf/horse-owner-download-form/replacement-permit-nrp.pdf`
                break;
            case "Special Replacement Permit":
                url = `https://member.hkjc.com/member/common/pdf/horse-owner-download-form/replacement-permit-srp.pdf`
                break;
            case "Racehorse Retirement":
                url = `https://member.hkjc.com/member/common/pdf/horse-owner-download-form/racehorse-retirement-and-disposal.pdf`
                break;
            case "Racehorse Retirement Ch":
                url = `https://member.hkjc.com/member/common/pdf/horse-owner-download-form/racehorse-retirement-and-disposal_chi.pdf`
                break;
            default:
                break;
        }

        // const newWindow = window.open('');
        // newWindow.location.href = url;
        DownloadForm(url)

        // Usage
        //openNewWindow(url);

        // fetch(url)
        //     .then(response => response.blob())
        //     .then(blob => {
        //         saveAs(blob, `${fileName}.pdf`);
        //     })
        //     .catch(error => {
        //         console.error('download error:', error);
        //     });
        // window.open(url)
    }
    const handleClickRight = () => {
        navigate(`notification?cuno=${cuno}`, { state: 
            { 
                data : horseData
            } 
        });
    }
    useEffect(() => {
        try{
        if (lang == 'en') {
            i18n.changeLanguage('en')
            setLangCode('en')
        }
        if (lang == 'ch') {
            i18n.changeLanguage('ch')
            setLangCode('ch')
        }

        const fetchData = async()=>{
            try {
            const response = await fetch('Config/PromotionConfig.json');
            const data = await response.json();
            setPromotion(data)
            } catch (error) {
            console.error('Error fetching JSON:', error);
            }
        }
    
        //test apolloclient
        fetchData();
        //get data from mobile client
        if (useDummy) {
            setHorseData(DummyData);
            setHorseData(prev => [...prev, DummyData[0], DummyData[0], DummyData[0], DummyData[0], DummyData[0], DummyData[0], DummyData[0], DummyData[0]])
        } else {
            getDataFromMobile();
        }
    
 
        }catch (error) {
            console.error('Error fetching JSON:', error);
        }

    }, [])
    const getDataFromMobile = () => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const data = urlParams.get("data")
        console.log("url", data);
        setCuno(urlParams.get("cuno"))
        if (data && data !== "null" && data !== "[]") {
            var json = JSON.parse(data)
            const modifiedData = json?.map(obj => {
                const { brandNo, ...rest } = obj;
                return { brandNumber: brandNo, ...rest };
            }) || [];
            //getGraphQLData(json)
            setUrlData(modifiedData);
        } else {
            setLoad(false);
        }
    }

    const clickMore = () => {
        setShowMore(!showMore)
    }
    const getSeeALlUrl = () => {
        let url = ''
        if (lang == 'en') {
            url = window.globalConfig.OWNER_DOWNLOADFORM_URL.replace('{{lang}}', 'english');
        }
        if (lang == 'ch') {
            url = window.globalConfig.OWNER_DOWNLOADFORM_URL.replace('{{lang}}', 'chinese');
        }
        DownloadForm(url)
    }

    const renderMyhorseItem = () => {
        if (horseData != null) {
            let newArr = []
            if (horseData.length > 6) {
                if (showMore) {
                    newArr = horseData.slice(0, 6);
                } else {
                    newArr = horseData;
                }
            } else {
                newArr = horseData;
            }

            return (
                newArr.map((item, index) =>
                    <Grid xs={4} key={item.brandNumber + index}>
                        <Link to={`HorseProfile/${item.brandNumber}/${item.ownerType}?cuno=${cuno}`}>
                            <HorseAvatar item={item} index={index} lang={lang} />

                        </Link>
                    </Grid>
                )
            )
        }

    }
    function FormatDateToString(d, pvalue) {
        let wName = ''
        const dateDefaultFormat = 'YYYY-MM-DD';
        const dayNum = dayjs(d, dateDefaultFormat).day();
        switch (dayNum) {
            case 0:
                wName = lang === "en" ? "Sunday" : "星期日"
                break;
            case 1:
                wName = lang === "en" ? "Monday" : "星期一"
                break;
            case 2:
                wName = lang === "en" ? "Tuesday" : "星期二"
                break;
            case 3:
                wName = lang === "en" ? "Wednesday" : "星期三"
                break;
            case 4:
                wName = lang === "en" ? "Thursday" : "星期四"
                break;
            case 5:
                wName = lang === "en" ? "Friday" : "星期五"
                break;
            case 6:
                wName = lang === "en" ? "Saturday" : "星期六"
                break;
            default:
                break;
        }

        if (pvalue === "promotion") {
            if (lang === "en") {
                return wName + ", " + dayjs(d, dateDefaultFormat).format('D MMMM YYYY');
            } else {
                return dayjs(d, dateDefaultFormat).format('YYYY年M月D日') + " (" + wName + ") "
            }
        }
        else {
            if (lang === "en") {

                return dayjs(d, dateDefaultFormat).format('D MMMM YYYY');
            }
            else {
                return dayjs(d, dateDefaultFormat).format('YYYY年M月D日')
            }

        }
    }
    const getWinningData = (itemData) => {
        const threeDaysBefore = new Date(businessDate).getTime() - 2 * 24 * 60 * 60 * 1000;
        const horseName = lang === 'ch' ? itemData?.name_ch === "" ? "未命名" : itemData?.name_ch : itemData?.name_en === itemData.brandNumber ? "UNNAMED" : itemData?.name_en
        const filterData = []
        for (const item of itemData.horseFormRecord) {
            const dateObj = new Date(item.date.replace('+08:00', ''))
            const dateTimeBefore = dateObj.getTime();
            if (dateTimeBefore >= threeDaysBefore) {
                filterData.push({ ...item, horseName: horseName })
            }
        }
        return filterData;
    }
    const isSOD = (race) => {
        return dayjs(race.date, "YYYY-MM-DD").format("YYYY-MM-DD") === businessDate;
    }
    const getRenderData = (propData) => {
        let resultData = [];
        propData?.map((item) => {
            item.futureEntry?.map((entry) => {
                if (entry?.runnerStatus) {
                    resultData.push({
                        sortId: entry?.runnerStatus === 'Entry' ? 4 : 5,
                        brandNumber: item?.brandNumber,
                        runnerStatus: isNull(entry?.runnerStatus === 'Entry' ? 'Entry' : 'reserve'),
                        horseName: isNull(lang === 'ch' ? item?.name_ch === "" ? "未命名" : item?.name_ch : item?.name_en === item.brandNumber ? "UNNAMED" : item?.name_en),
                        className: isNull(lang === 'en' ? entry?.className.english : entry?.className.chinese),
                        distance: isNull(entry?.distance),
                        track: isNull(lang === 'en' ? entry?.track?.english : entry?.track?.chinese),
                        date: isNull(entry ? entry?.date.replace("+08:00", "") : entry?.date.replace("+08:00", "")),
                        reserveNo: isNull(entry?.reserveNo),
                        raceNo: isNull(entry?.raceNo),
                        startTime: isNull(entry?.startTime),
                        drawNo: isNull(entry?.barrierDrNo),
                        raceCourse: isNull(lang === 'en' ? entry?.venue.english : entry?.venue.chinese),
                    })
                }
            })
            item.futureRaceCard?.map((race) => {
    
                if (race?.isHorseOwner) {
                    if (race?.runnerStatus && (race?.runnerStatus !== 'Standby' && !isSOD(race))||(race?.runnerStatus == 'Scratched' && isSOD(race))) {
                        resultData.push({
                            sortId: race?.runnerStatus === 'Declared' ? 3 : 6,
                            runnerStatus: isNull(race?.runnerStatus),
                            horseName: isNull(lang === 'ch' ? item?.name_ch === "" ? "未命名" : item?.name_ch : item?.name_en === item.brandNumber ? "UNNAMED" : item?.name_en),
                            horseNo: isNull(race?.horseNo),
                            className: isNull(lang === 'en' ? race?.className.english : race?.className.chinese),
                            distance: isNull(race?.distance),
                            track: isNull(lang === 'en' ? race?.track?.english : race?.track?.chinese),
                            date: isNull(race ? race?.date.replace("+08:00", "") : race?.date.replace("+08:00", "")),
                            jockey: lang === 'en' ? race?.jockey?.name_en : race?.jockey?.name_ch,
                            raceNo: isNull(race?.raceNo),
                            startTime: isNull(race?.startTime),
                            drawNo: isNull(race?.barrierDrNo),
                            raceCourse: isNull(lang === 'en' ? race?.venue.english : race?.venue.chinese),
                        })
                    }
                }
                return ''
            })

            const filterWinningData = getWinningData(item);
            filterWinningData?.map((data) => {
                if (data?.winningHorse&&!data?.overseas) {
                    resultData.push({
                        sortId: 2,
                        runnerStatus: 'winningMoment',
                        horseNo: isNull(data?.runnerResults?.filter((i) => Number(i.placeNo) === 1 && i.barrierDrNo === data.barrierDrNo)[0]?.horseNo),
                        raceNo: isNull(data.raceNo),
                        track: isNull(lang === 'en' ? data?.track?.english : data?.track?.chinese),
                        horseName: isNull(data.horseName),
                        className: isNull(lang === 'en' ? data?.className?.english : data?.className?.chinese),
                        distance: isNull(data?.distance),
                        raceName: isNull(lang === 'en' ? data?.raceName?.english : data?.raceName?.chinese),
                        date: isNull(data ? data?.date.replace("+08:00", "") : data?.date.replace("+08:00", "")),
                    })
                }
                return ''
            })
        })
        setNewsRenderData(resultData)
    }
    const renderPromotionCard = ()=>{

        if(promotion.length>0){
            return(           
                promotion.map(item=>{
                    return(
                        <NewsCard data={item}
                            className='owner' 
                            id="promotionIcon" 
                            newsContent={lang === "en" ? item.contentEN:decodeURIComponent(item.contentCH)}
                            newsOperator={lang === "en" ? item.buttonEN:decodeURIComponent(item.buttonCH)} 
                            newsType={"promotion"} 
                            pathCH={item.pathCH}
                            pathEN={item.pathEN}
                        />
                    )
                })
            )
        }       
    }
    const renderNewsCard = () => {
        let renderArr = []
        const data = newsRenderData;
        data.sort((a, b) => {
            if (a.sortId !== b.sortId) {
                return a.sortId - b.sortId;
            } else {
                const raceNoA = typeof a.raceNo === 'string' ? Number(a.raceNo?.replace('P', '')) : Number(a.raceNo)
                const raceNoB = typeof b.raceNo === 'string' ? Number(b.raceNo?.replace('P', '')) : Number(b.raceNo)
                const dateA = new Date(a.date)?.getTime()
                const dateB = new Date(b.date)?.getTime()
                if (dateA === dateB) {
                    if (raceNoA !== raceNoB) {
                        return raceNoA - raceNoB;
                    } else {
                        return a.horseNo - b.horseNo;
                    }
                } else {
                    return dateA - dateB;
                }
            }
        });
        renderArr = data.map((item) => {
            const type = item.runnerStatus?.toLowerCase()
            let show = true;
            let content = ''
            let operator = ''
            const newDate = FormatDateToString(item.date);
            switch (type) {
                case "entry":
                    content = lang === "en" ? `${item.horseName} will run in the ${item.className}, ${item.distance}M (${item.track}) on ${newDate}.` : `${item.horseName}將於${newDate}出賽(${item.className}${item.distance}米${item.track})。`
                    operator = "Lbl_Share"
                    break;
                case "reserve":
                    content = lang === "en" ? `${item.horseName} is Reserve ${item.reserveNo} in the ${item.className}, ${item.distance}M (${item.track}) on ${newDate}.` : `${item.horseName}暫於${newDate}(${item.className}${item.distance}米${item.track})列為後備馬匹${item.reserveNo}。`
                    operator = "Lbl_Share"
                    break;
                case "scratched":
                    content = lang === "en" ? `${item.horseName} scratched from ${newDate} ${item.raceCourse} Race Meeting.` : `${item.horseName}退出${newDate}${item.raceCourse}競賽。`
                    break;
                case "declared":
                    content = lang === "en" ? `${item.horseName} will be ridden by ${item.jockey} in Race ${item.raceNo} (${item.distance}M) with No.${item.horseNo} at ${dayjs(item.startTime).format("HH:mm")} on ${newDate}, Drawn ${item.drawNo} ${item.raceCourse} Racecourse.` : `${item.horseName}已被列為${newDate}第${item.raceNo}場(${item.distance}米, ${dayjs(item.startTime).format("HH:mm")}開跑)第${item.horseNo}號馬, 騎師${item.jockey}, 第${item.drawNo}檔, ${item.raceCourse}馬場。`
                    operator = "Lbl_Share"
                    break;
                case "winningmoment":
                    content = lang === "en" ? `Congratulations! ${item.horseName} wins the ${item.className}, ${item.distance}M (${item.track}) - ${item.raceName} on ${newDate}! Let's celebrate the victorious moment!` : `恭喜！${item.horseName}勝出${newDate}(${item.className}${item.distance}米${item.track})${item.raceName}！慶祝光輝時刻！`
                    // operator = "Lbl_WinMoment_ViewPhoto"
                    break;
                default:
                    show = false;
                    break;
            }
            return show && <NewsCard data={item} className='owner' entry='ownerProfile' id={type} newsType={type} newsOperator={operator} newsContent={content} />
        })
        return renderArr;
    }
    return (
        <>
            {Load&&<LoadingOverlay/>}
            <Header title='Lbl_OwnerProfile' rightType='notify' handleCLickRight={handleClickRight} />
            <div className="owner-profile-container">
                <div className={`${newsRenderData.length === 1 ? 'top-news-card' : "TabletNewsContainer top-news-card-multiple "}`}>
                    {
                        renderPromotionCard()
                    }
                    {
                        renderNewsCard()
                    }
                    {/* <NewsCard id="winningIcon" handleClick={() => { console.log("666") }} newsDate={"2/11/2023"} newsContent={" Congratulations! SURE JOYFUL wins the Class 3, 1800M (TURF) - SUCCESS HANDICAP on 1 October 2023! Come and witness the victorious moment!"} newsOperator={"Lbl_WinMoment_ViewPhoto"} newsType={"winning"} />
                    <NewsCard id="reserveIcon" handleClick={() => { shareToMobile("SURE JOYFUL is Reserve 1 in the Class 3, 1800M (TURF) on 1 October 2023.", "https://racing.hkjc.com/racing/racing/information/English/racing/Entries.aspx") }} newsDate={"1/11/2023"} newsContent={"SURE JOYFUL is Reserve 1 in the Class 3, 1800M (TURF) on 1 October 2023."} newsOperator={"Lbl_Share"} newsType={"reserve"} />
                    <NewsCard id="entryIcon"
                        handleClick={() => {
                            shareToMobile("SURE JOYFUL will run in the Class 3, 1800M (TURF) on 01/10/2023.",
                                "https://racing.hkjc.com/racing/racing/information/English/racing/Entries.aspx")
                        }}
                        newsDate={"31/10/2023"}
                        newsContent={"SURE JOYFUL will run in the Class 3, 1800M (TURF) on 01/10/2023."}
                        newsOperator={"Lbl_Share"}
                        newsType={"entry"}
                    />
                    <NewsCard id="scratchedIcon" handleClick={() => { console.log("666") }} newsDate={"30/10/2023"} newsContent={"CAN'T GO WONG scratched from 01/10/2023 Sha Tin Race Meeting."} newsType={"scratched"} />
                    <NewsCard id="nextRaceIcon" handleClick={() => {
                        {
                            shareToMobile("SURE JOYFUL No 9 will be ridden by K C Leung in Race 7 (1650M) at 22:15 on 11 October 2023, Drawn 3 Happy Valley Racecourse. ",
                                "https://racing.hkjc.com/racing/racing/information/English/racing/RaceCard.aspx")
                        }
                    }} newsDate={"29/10/2023"} newsContent={"SURE JOYFUL No 9 will be ridden by K C Leung in Race 7 (1650M) at 22:15 on 11 October 2023, Drawn 3 Happy Valley Racecourse. "} newsOperator={'Lbl_Share'} newsType={"nextRace"} /> */}
                </div>

                {   // My Horse 
                    horseData == null || horseData.length == 0
                        ? <></>
                        : <div className="TabletBaseContainer my-horse-container">
                            <div className="my-horse-title PlayFair fontSize22 lineHeight26">
                                <p>{t("Lbl_MyHorse")}</p>
                                <div className="my-horse-title-line" />
                            </div>
                            <Grid style={{ 'justify-content': horseData.length === 1 ? 'center' : 'flex-start' }} marginTop={"24px"} container spacing={2} >
                                {
                                    renderMyhorseItem()
                                }
                            </Grid>
                            {horseData.length > 6 &&
                                <div onClick={clickMore} className="item-more" id={"See_ALL_Form"}>
                                    <p className="Label2-Medium">{showMore ? t('Lbl_More') : t('Lbl_Less')}&nbsp;&nbsp;&nbsp;<img alt='' src={showMore ? navigateTop : navigateBottom} /></p>
                                </div>
                            }
                        </div>
                }
                <div className="owner-profile-other-container-owner">
                    {/* {horseData == null || horseData.length == 0 ? <></> : <HeadToHead data={data} />} */}
                    {horseData == null || horseData.length == 0 ? <></> : ifShowMCLloyd(data, businessDate) && <MCLloyd data={data} />}
                    {meetingData ? <RacingFixture data={data} upcoming={meetingData} /> : null}
                    <div className="owner-download-form-container">
                        <div className="download-form-title-wrapper">
                            <p className="Title/Title1-Bold PlayFair fontSize22 fontWeight700">{t("Lbl_UsefulFroms")}</p>
                            <div onClick={getSeeALlUrl} className="Label2-Medium see-all">{t("Lbl_SeeAll")}<span style={{ display: 'flex' }}> <img alt="" src={shareIcon} /></span></div>
                        </div>
                        <div className="form-content">
                            <div className="form-item" id={"NormalReplacementPermitForm"} >
                                <p className="fontSize16">{t("Lbl_NormalReplacementPermit")}</p>
                                <img onClick={() => clickDownloadForm("Normal Replacement Permit")} alt="" src={downloadImg} className="form-download-btn" />
                            </div>
                            <div className="form-item-line" />
                            <div className="form-item" id={"SpecialReplacementPermitForm"} >
                                <p className="fontSize16">{t("Lbl_SpecialReplacementPermit")}</p>
                                <img onClick={() => clickDownloadForm("Special Replacement Permit")} alt="" src={downloadImg} className="form-download-btn" />
                            </div>
                            <div className="form-item-line" />
                            <div className="form-item" id={"OriginalReplacementPermitForm"} >
                                <p className="fontSize16">{t("Lbl_OriginalReplacementPermit")}</p>
                                <img onClick={() => clickDownloadForm("Original Replacement Permit")} alt="" src={downloadImg} className="form-download-btn" />
                            </div>
                            <div className="form-item-line" />
                            <div className="form-item" id={"RacehorseRetirementForm"} >
                                <p className="fontSize16">{t("Lbl_RacehorseRetirement")}</p>
                                <img onClick={() => lang == 'ch' ? clickDownloadForm("Racehorse Retirement Ch") : clickDownloadForm("Racehorse Retirement")} alt="" src={downloadImg} className="form-download-btn" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LandingPage;