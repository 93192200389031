import styles from '../Css/MovementRecord.module.css'
import { Divider, Grid, Paper,Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from "react-i18next";
import React from 'react';
function MovementRecord({data,lang}){
    useEffect(()=>{
        sortDate();
    },[data])
    const [sortData,setSortData] = useState(null);
    const{t,i18n} = useTranslation();
    const navigate = useNavigate();
    const locationMapping = (code)=>{
        switch(code){
            case 'HK':
                if(lang=='ch'){
                    return '香港'
                }else{
                    return 'Hong Kong'
                }

            case 'CH':
                if(lang=='ch'){
                    return '從化'
                }else{
                    return 'Conghua'
                }
            default:
                return "--"
        }
    }
    function sortDate(){
        if(data!==null){
            data = data.slice().sort((a,b)=>{
                return  moment(b.time,"YYYY-MM-DD").format("YYYYMMDD") - moment(a.time,"YYYY-MM-DD").format("YYYYMMDD") 
            })
            setSortData(data)
        }

    }
    return(
        <div className={styles.Base}>
            {sortData==null?<></>:
            <>            
            <div className={styles.Header}>
                <div id="MovementRecordTitle" className={`Title/Title1-Bold ${styles.Title} PlayFair`}   >
                {t("Lbl_MovementRecord")}
                </div>
                <div style={{flex:1}}/>
                {sortData.length>3?
                <div  className={`Label2-Medium ${styles.More}`} id={"See_All_M"}  onClick={()=>{
                    navigate(`MovementRecords?cuno=${window.cuno}`,{state:{data:sortData,lang:lang}});
                }}>
                    {t("Lbl_SeeAll")}
                </div>:null}
            </div>
            <Grid container px={2} mb={2} >
                <Box xs ={12} className={styles.Table} id = {"Movement_Table"} >
                    <Grid item container justifyContent="center" alignItems="center" xs={12}  id={"Movement_Headers"} className={styles.TableHeader} py={0.5}>
                        <Grid item xs={4} className={styles.TableHeaderCell}>
                            <Typography className={"Label2-Medium fontWeight700 fontSize14"}>{t("Lbl_ArrivalDate")}</Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem className={styles.DividerHeader}  />
                        <Grid item xs={4} className={styles.TableHeaderCell}>
                            <Typography className="Label2-Medium fontWeight700 fontSize14">{t("Lbl_From")}</Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem className={styles.DividerHeader}  />
                        <Grid item  xs={4} className={styles.TableHeaderCell}>
                            <Typography className="Label2-Medium fontWeight700 fontSize14">{t("Lbl_To")}</Typography> 
                        </Grid>
                    </Grid> 
                    {sortData.map((item,index)=>
                        index<3?
                        <React.Fragment key={"Movement_Row"+index}>                       
                            <Grid item xs={12} container py={0.5} id={"Movement_Row"+index} >
                                
                                <Grid item xs={4} className={styles.TableHeaderCell}>
                                    <Typography className="Label2-Medium fontSize14" id={"Profile_ArrivalDate"+index}>{item.time ? moment(item.time,'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem className={styles.DividerCell}  />
                                <Grid  item xs={4} className={styles.TableHeaderCell}>
                                    <Typography className="Label2-Medium fontSize14" id={"Profile_From"+index}>{locationMapping(item.from)}</Typography>
                                </Grid>   
                                <Divider orientation="vertical" flexItem className={styles.DividerCell}  />          
                                <Grid  item xs={4} className={styles.TableHeaderCell}>
                                    <Typography className="Label2-Medium fontSize14" id={"Profile_To"+index}>{locationMapping(item.to)}</Typography>
                                </Grid>
                            </Grid>
                            {
                            index<2&&<Grid xs={12}>
                                <Divider  flexItem className={styles.DividerVer} />  
                            </Grid>
                            }
                        </React.Fragment>
                        :null
                    )}
                </Box>     
            </Grid>
            </>
            }
        </div>
    )
}
export default MovementRecord;