import { useEffect, useState } from 'react'
import styles from '../Css/Header.module.css'
import { Box, Divider, Typography } from '@mui/material';
import Back from '../img/Back.svg'
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OnGoBackBtnClick } from "../utils";
import notifyIcon from '../img/notification_right-icon.svg'
import { Helmet } from 'react-helmet';
const fixedTopStyle = {
    position: 'fixed',
    top: 0,
    width: '100%',
}
const Header = ({ title, rightType, handleCLickRight, fixedTop,style }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [rightIcon, setRightIcon] = useState(null);
    const getRightIconSrc = (rightType) => {
        let icon = null;
        switch (rightType) {
            case 'notify':
                icon = notifyIcon;
                break;
            default:
                break
        }
        setRightIcon(icon)
    }
    useEffect(() => {
        getRightIconSrc(rightType)
    }, [rightType])
    useEffect(()=>{
        var st =document.querySelector('meta[name="description"]')?.content;
        document.querySelector('meta[name="app-webview-title"]')?.setAttribute("content",t(title))
        console.log(st,document.querySelector('meta[name="app-webview-title"]')?.content)
    },[title])
    return (
        <div className={styles.Base} style={fixedTop ? fixedTopStyle : {}}>
            <Helmet>
                <title>{t(title)}</title>
                <meta name="app-webview-title" content={t(title)}/>
                
            </Helmet>
            <Box className={`${styles.header} PlayFair`} display="flex" alignItems="center">
                <div className={`Label2-Bold ${styles.backButton}`} id={"BackBtn"} onClick={() => OnGoBackBtnClick(navigate)}>
                    <img src={Back} />
                    {/* {t('Lbl_Back')} */}
                </div>
                <div className={"Headline\/H2-Bold fontSize22"} id={"Title"}> {t(title)} </div>
                <img className={styles.rightButton} id={"rightButton"} alt='' onClick={() => {
                    handleCLickRight()
                }} src={rightIcon} />

            </Box>
        </div>
    )
}
export default Header;