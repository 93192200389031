import { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import LoadingIcon from "./loading-icon.svg";
import "./loading.css"
export default function LoadingOverlay({ showLoading }){
  const [show, setShow] = useState(false);
  useEffect(() => {
   // setShow(typeof document !== "undefined");
  }, []);

  //if (!showLoading) return null;

  return(
    <div style={{flex:1,display:'flex',position:'fixed',zIndex:999,background:'white',width:'100%',height:'100%',alignItems:'center',justifyContent:'center'}}>
      <div className={"blink"}>
        <img src={LoadingIcon} />
      </div>
    </div>
  );

};
