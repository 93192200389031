import React, { useEffect, useState, useContext} from 'react';
import { styled } from '@mui/material/styles';
import "../Css/MCLloyd.css"
import {
    Grid,

    Divider,
    Typography,
} from '@mui/material';

import RDCompare from '../../Component/RDCompare';
import RPCompare from '../../Component/RPCompare';
import SectionalSpeedChart from '../../PostRaceAnalysis/SectionalSpeedChart';
import { useTranslation } from "react-i18next";
import MCLloydShare from '../../img/MCLloydShare.svg';
import { useParams } from "react-router-dom";
import { DownloadForm,imageShare } from '../../utils';
import QmarkIconDialog from '../../Component/QmarkIconDialog';
import domtoimage from 'dom-to-image';
import CircularProgress from '@mui/material/CircularProgress';
import { getPostRaceConfig } from '../../request/requestList';
import HorseContext from '../../utils/horseContext';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import html2canvas from 'html2canvas';

import 'swiper/css';
import 'swiper/css/pagination';

const qmarkcss = {
    "display": "inline-block",
    "vertical-align": "sub",
    "padding-left": "10px",
}

function trimDataURL(dataURL) {
    // Remove the scheme
    let trimmedURL = dataURL.replace('data:', '');
  
    // Remove the media type
    const mediaTypeEnd = trimmedURL.indexOf(';');
    if (mediaTypeEnd !== -1) {
      trimmedURL = trimmedURL.slice(mediaTypeEnd + 1);
    }
  
    // Remove any parameters
    const base64Index = trimmedURL.indexOf('base64,');
    if (base64Index !== -1) {
        trimmedURL = trimmedURL.slice(base64Index + 7);
    }

    return trimmedURL;
}

const MCLoydButtom = (props) => {
    const { type, idx, dataSourse = [] } = props;
    const { t, i18n } = useTranslation();
    const [capturing, setCapturing]  = useState(false);

    const capScreen=(title,idx)=>{
        const ele = document.querySelector(`#McLloydCard${idx}`); 

        domtoimage.toPng(ele).then(blob => {
            console.log('blob----success', trimDataURL(blob))
            // let img = canvas.toDataURL("image/png",0.1).toString();
            // window.saveAs(blob, 'my-node.png');
            imageShare(title,trimDataURL(blob))
            // console.log('domtoimage----success', blob)
            setCapturing(false);
        }).catch( e => {

            capScreenByhtml2canvas(ele,title )
            // setCapturing(false);
            console.log('domtoimage error ------', JSON.stringify(e))
        });
    }

    const capScreenByhtml2canvas=(dom, title)=>{
        html2canvas(dom).then(canvas => {
            let img = canvas.toDataURL("image/png",0.1).toString();

            console.log('html2canvas----success', trimDataURL(img))
            imageShare(title,trimDataURL(img))
            setCapturing(false);
        }).catch( e => {
            setCapturing(false);
            console.log('capScreenByhtml2canvas error ------', JSON.stringify(e))
        });
    }

  
    const onShareBtnClick = (ty) => {
        if (capturing) return

        setCapturing(true);
        switch (ty) {
            case 'SSC':
                capScreen(t('Lbl_RD_SectionalSpeedComparison'),idx);
                break;
            case 'RDC':
                capScreen(t('Lbl_RD_RunningDistanceComparison'), idx);
                break;
            case 'RPC':
                capScreen(t('Lbl_RD_RailPositionComparison'), idx);
                break;
            default:
                break;
        }
    }
    
    return dataSourse?.length ? <>
            <Grid container mt={1} mb={1}>
                <Divider variant="middle" style={{ background: '#CED3D9', opacity: 0.3, flex: 1, margin: 0 }} />
            </Grid>
            <Grid container className="MCLloyd-Btn" py={1} >
                {/* {   data?.cvideoLink && <div style={{ display: "flex"}} onClick={() => DownloadForm(data?.cvideoLink)}>
                    <div><img alt="" src={MCLloydDownload} /></div>
                    <p id={`PRA_Chart_Download_${idx}`} >{t('Lbl_Download')}</p>
                    </div>
                } */}

                {/* capturing UI tbc */}
                {capturing && <CircularProgress color="inherit" /> }
                <div onClick={() => onShareBtnClick(type)}><img alt="" src={MCLloydShare} /></div>
               
                <p id={`PRA_Chart_Share_${idx}`} onClick={() => onShareBtnClick(type)}>{t('Lbl_Share')}</p>
            </Grid>
        </> : null
}

const MCLloydSlider = (props) => {
    const { data, idx, isRaceDetail } = props;
    const { t, i18n } = useTranslation();
    const [hideBottom,setHideBottom] = useState(false);
    const { clientData } = useContext(HorseContext);
    const { id, ownerType, lang } = useParams();

    const [ showRDComparison, setshowRDComparison] = useState(false);
    const [ showRPComparison, setshowRPComparison] = useState(false);

    const [ RDTitle, setRDTitle] = useState(t('Lbl_RD_RunningDistanceComparison'));
    const [ RDDescription, setRDDescription] = useState(t('Lbl_RD_IconTips'));

    const [ RPTitle, setRPTitle] = useState(t('Lbl_RD_RailPositionComparison'));
    const [ RPDescription, setRPDescription] = useState(t('Lbl_Rail_IconTips'));
    useEffect(() => {
        const sliderItemRoot = document.getElementsByClassName('sliderItemRoot');
        console.log('【dom----】', sliderItemRoot)

        for (let i = 0; i < sliderItemRoot.length; i ++) {
            sliderItemRoot[i].style.height = "100%";
            sliderItemRoot[i].parentNode.style.height = "100%";
        }

        const params =  {
            horses: isRaceDetail ? [{
                "brandNumber": id,
                "ownerType": ownerType,
            }] : clientData
        };
        getPostRaceConfig(params, t).then(res => {
            const wcasConfig = res?.postRaceAnalysis;
            if (wcasConfig?.length) {
                const rdc = wcasConfig[0]?.enable;
                const rdtitle = window.globalConfig.isLangUS ? wcasConfig[0]?.name_en: wcasConfig[0]?.name_ch
                const rddec = window.globalConfig.isLangUS ? wcasConfig[0]?.description_en: wcasConfig[0]?.description_ch

                const rpc = wcasConfig?.[1]?.enable;
                const rptitle = window.globalConfig.isLangUS ? wcasConfig[1]?.name_en: wcasConfig[1]?.name_ch
                const rpdec = window.globalConfig.isLangUS ? wcasConfig[1]?.description_en: wcasConfig[1]?.description_ch
                setshowRDComparison(rdc);
                setRDTitle(rdtitle);
                setRDDescription(rddec);

                setshowRPComparison(rpc);

                setRPTitle(rptitle);
                setRPDescription(rpdec);
            }
        })
    }, []);

    return (
        <>
          {/* <Grid container > */}
          <div className="MCLloyd-sliderContainer">
            <Swiper
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >   
                {
                    showRDComparison && <SwiperSlide>
                        <div className="sliderItemRoot">
                            <Typography paragraph className='MCLloyd-Item-Right-Title PlayFair' textAlign={'left'} id={`PRA_RDC_${idx}`} >
                                {RDTitle}
                                <div style={qmarkcss}>
                                <QmarkIconDialog id={"Qmark_RDC"} type="RDC" description={RDDescription}/>
                                </div>
                            </Typography>
                            {/* <div  id={`PRA_RDC_Chart_${idx}`}> image placeholder</div> */}
                            <RDCompare 
                                id={`PRA_RDC_Chart_${idx}`} 
                                data={data}
                                mCLoydButtom={<MCLoydButtom idx={idx} type="RDC" dataSourse={data?.runnerResults}/>}/>
                        </div>
                    </SwiperSlide>
                }
                <SwiperSlide>
                    <div className="sliderItemRoot">
                        <Typography paragraph className='MCLloyd-Item-Right-Title PlayFair' textAlign={'left'} id={`PRA_SSC_${idx}`} >
                            {t('Lbl_RD_SectionalSpeedComparison')}
                            <div style={qmarkcss}>
                            <QmarkIconDialog id={"Qmark_SSC"} type="SSC" />
                            </div>
                            
                        </Typography>
                        <SectionalSpeedChart 
                            id={`PRA_SSC_Chart_${idx}`} 
                            data={data} 
                            hideBottom={setHideBottom} 
                            mCLoydButtom={<MCLoydButtom idx={idx} type="SSC" dataSourse={data?.runnerResults}/>}/>
                        {/* <div  id={`PRA_SSC_Chart_${idx}`}> </div> */}

                    </div>
                </SwiperSlide>
                {
                    showRPComparison && 
                    <SwiperSlide>
                        <div className="sliderItemRoot">
                        <Typography paragraph className='MCLloyd-Item-Right-Title PlayFair' textAlign={'left'} id={`PRA_RPC_${idx}`} >
                            {RPTitle}
                            <div style={qmarkcss}>
                            <QmarkIconDialog id={"Qmark_RPC"} type="RPC" description={RPDescription}/>
                            </div>
                            
                        </Typography>
                        <RPCompare 
                            id={`PRA_RPC_Chart_${idx}`} 
                            titleId={`PRA_RPC_${idx}`}
                            data={data}
                            mCLoydButtom={<MCLoydButtom idx={idx} type="RPC" dataSourse={data?.runnerResults}/>}/>

                        </div>
                    </SwiperSlide>
                }
            </Swiper>
      </div>
      </>
    )
}

export default MCLloydSlider;