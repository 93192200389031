
import styles from './css/MyRecord.module.css'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Divider, Grid, Paper, Typography, Box, breadcrumbsClasses } from '@mui/material';
import { useTranslation } from "react-i18next";
import location from '../../img/WinningMoment/location.svg';
import date from '../../img/WinningMoment/date.svg';
import replayIcon from '../../img/replayIcon.svg';
import moment from 'moment';
import RaceDetailIcon from '../../img/RaceDetailIcon.svg';
import PlayVideoIcon from '../../Component/PlayVideoIcon';
import { getVideoInfo } from '../../utils';

import viewPhotoIcon from '../../img/viewPhoto.svg';

function MyRecord({ data, type, isTitle, propId, propOwnerType }) {
    const { t, i18n } = useTranslation();
    const { id, lang, ownerType } = useParams();
    const navigate = useNavigate();
    const [renderData, setRenderData] = useState(null)
    const [bottomName, setBottomName] = useState([])

    useEffect(() => {
        handleData()
    }, [data])
    const getRenderData = (data) => {
        return data?.filter((item) => !item.overseas)[0]
    }
    const handleData = () => {
        let result;
        if (data?.length) {
            const record = getRenderData(data);
            result = {
                season: record.season,
                raceName: record.raceName?.code ? lang === 'ch' ? record.raceName?.chinese : record.raceName?.english : "--",
                venue: lang === 'ch' ? record.venue?.chinese : record.venue?.english,
                date: moment(record.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                placeNo: Number(record.placeNo) > 0 ? Number(record.placeNo) : '--',
                link: getVideoInfo(record).link,
                resultIndex: record.resultIndex,

            }
            const commonContent = {
                Race: record.raceNo ? record.raceNo : '--',
                RaceClass: lang === 'ch' ? record.className?.chinese : record.className?.english,
                Distance: record.distance + `${lang === 'ch' ? '米' : 'M'}`,
                Track: record.track?.code ? lang === 'en' ? record.track?.english : record.track?.chinese : '--',
                Course: record.course?.code ? lang === 'en' ? `"`+record.course?.code+`"` : `"`+record.course?.code+`"` : '--',
                Going: record.going?.code ? lang === 'en' ? record.going?.english : record.going?.chinese : '--',
                Trainer: record.trainer == null ? '--' : lang === 'ch' ? record.trainer?.name_ch : record.trainer?.name_en,
                Jockey: record.jockey == null ? '--' : lang === 'ch' ? record.jockey?.name_ch : record.jockey?.name_en,
                FinishTime: record.finalTimeOfRace === null || record.finalTimeOfRace === '' ? '--' : record.finalTimeOfRace,
            }
            switch (type) {
                case 'horseProfile':
                    result = {
                        ...result,
                        content: commonContent
                    }
                    setBottomName(['Lbl_RaceReplay', 'Lbl_RaceDetails'])
                    break;
                case 'raceDetail':
                    result = {
                        ...result,
                        content: commonContent
                    }
                    setBottomName(['Lbl_RaceReplay'])
                    break;
                case 'postRace':
                    result = {
                        ...result,
                        content: commonContent
                    }

                    const postRaceBtns = ['Lbl_RaceReplay', 'Lbl_RaceDetails']
                    setBottomName(postRaceBtns)
                    break;
                default:
                    break;
            }
        }
        setRenderData(result)
    }
    const centerCss = {
        display: "flex",
        alignItems: "center"
    }
    const handleClickDetail = () => {
        navigate(`/${lang}/RaceDetail/${id || propId}/${ownerType || propOwnerType}?cuno=${window.cuno}`, { state: { isHistory: false } })
    }
    const renderContent = () => {
        console.log('renderContent----------', renderData)
        const arr = []
        renderData.content && Object.entries(renderData.content).forEach(([key, value]) => {
            arr.push(<Grid key={key} item xs={4} mb={1}>
                <Typography className={`Label3-Regular ${styles.GridTitle}`}>{t(`Lbl_${key}`)}</Typography>
                <Typography id={`FR_${key}`} className={`${styles.GridContent}`}>{value}</Typography>
            </Grid>)
        })
        return arr;
    }

    const renderBottom = () => {
        return bottomName?.map((name) => {
            return (
                name === 'Lbl_RaceReplay' ?
                    <Grid style={{
                        display: !renderData.link ? 'none' : 'flex',
                        alignItems : "center",
                    }} textAlign="left" sm={2} >
                        {<PlayVideoIcon
                            iconSize={18}
                            id='FR_raceReplayImg'
                            videoLink={renderData.link}
                            content={<Typography id='FR_raceReplayText' marginLeft="4px" className={`${styles.RaceText}`}>
                                {t(name)}
                            </Typography>} />}
                    </Grid> :
                    <Grid id='FR_raceDetails' sm={2} ml={2}>
                        <div className={styles.bottomItem}>
                            <img id='FR_raceDetailImg' onClick={() => handleClickDetail()} alt='' src={RaceDetailIcon} />
                            <Typography id='FR_raceDetailText' onClick={() => handleClickDetail()} marginLeft="4px" textAlign="left" className={`${styles.RaceText}`}>
                                {t(name)}
                            </Typography>
                        </div>
                    </Grid>
            )
        }
        )
    }
    const renderContainer = () => {
        return (
            <Grid container item direction="row" alignItems="center">
                <Grid xs={12} container style={{ position: "relative" }}>
                    <Grid item xs={10} >
                        <Typography id='FR_season' className={`Label3-Regular ${styles.GridTitle}`}>{renderData.season} {t(`Lbl_WinMoment_Season`)}</Typography>
                    </Grid>
                    <Grid item xs={11} my={0.5} style={{
                        maxWidth: "calc(100% - 74px)"
                    }}>
                        <Typography style={{ margin: "0", lineHeight: "23px" }} id='FR_title' className={`Title/Title1-Bold ${styles.FormCardTitle} PlayFair`} >{renderData.raceName}</Typography>
                    </Grid>
                    <Grid container xs={12} alignItems="center" >
                        <Grid style={centerCss} xs={4} >
                            <img alt='' src={location} style={{ paddingRight: 3 }}/>
                            <Typography id='FR_position' className={`${styles.GridTitle} ${styles.black}`}>{renderData.venue}</Typography>
                        </Grid>
                        <Grid style={centerCss} xs={4}>
                            <img alt='' src={date} style={{ paddingRight: 3 }}/>
                            <Typography id='FR_date' className={`${styles.GridTitle} ${styles.black}`}>{renderData.date}</Typography>
                        </Grid>
                    </Grid>
                    <Grid alignItems="center" container >
                        <div id='FR_placing' className={styles.PlacingContent} >
                            <p 
                            // className={`Label3-Bold`}
                            >{t("Lbl_Placing")}</p>
                            <span className={lang==='ch'&&renderData?.resultIndex?.trim().includes("DH")?`Label3-Bold PlayFair`:`Number/Number1-Bold PlayFair`} style={{ lineHeight: '16px'}}>{renderData?.resultIndex?.trim().includes("DH")?renderData.placeNo+`${t("Lbl_DH")}`:renderData.placeNo}</span>
                        </div>
                    </Grid>
                </Grid>


                <Grid container mt={2} mb={2}>
                    <Divider variant="middle" style={{ background: '#CED3D9', opacity: 0.3, flex: 1, margin: 0 }} />
                </Grid>
                <Grid container item >
                    {
                        renderContent()
                    }
                </Grid>
                {
                    bottomName.length === 1 && !renderData.link ? null : <Grid container mb={2}>
                        <Divider variant="middle" style={{ background: '#CED3D9', opacity: 0.3, flex: 1, margin: 0 }} />
                    </Grid>
                }
                <Grid container justifyContent="flex-end" alignItems="center">
                    {renderBottom()}
                </Grid>
            </Grid>
        )
    }
    return (
        <div id='FR_container' className={styles.Base}>
            {isTitle ? <div className={styles.Header}>
                <div id='FormRecordTitle' className={`Title/Title1-Bold ${styles.Title} PlayFair`}>
                    {t("Lbl_FormRecord")}
                </div>
                <div style={{ flex: 1 }} />
                {data.length > 1 ?
                    <div className={`${styles.More}`} id={"See_All_F"}
                        onClick={() => {
                            navigate(`/${lang}/formrecords/${id}/${ownerType}?cuno=${window.cuno}`);
                        }}
                    >
                        {t("Lbl_SeeAll")}
                    </div>
                    : null
                }
            </div> : null}
            {!renderData ?
                <Grid className={styles.drawerContentContainer}>
                    <div className={styles.drawerContentWrapper}>
                        <div id='FR_noRunning' className={`Label1-SemiBold ${styles.drawerContentNoData}`} style={{ textAlign: 'left' }}>
                            {t('Lbl_NoRunningRecord')}
                        </div>
                    </div>
                </Grid>
                :
                <Grid container px={2} >
                    {type !== 'postRace' ? <Grid container py={2} className={styles.TopContainer}>
                        {renderContainer()}
                    </Grid> :
                        renderContainer()
                    }
                </Grid>
            }
        </div >
    )
}
export default MyRecord;