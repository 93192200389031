import moment from 'moment';

function getTCDataContent(data, currentdate = moment().format("YYYY-MM-DD"), exceptRace = false) {
    const dateContent = [{
        day: currentdate,
        tw_list: [],
    }];

    if (data && data.length) {
        // dateContent[0].day = moment(data[0].date, "YYYY-MM-DD").format("YYYY-MM-DD");
        const tarDateList = data.filter((item)=> moment(item.date, "YYYY-MM-DD").isSame(currentdate));
        console.log('[------tarDateList]', tarDateList)
        return getTWCardData(tarDateList, dateContent, exceptRace);
    } 

    return dateContent;
}

function getTWCardData (data, dateContent, exceptRace) {
    const isUS = window.globalConfig.isLangUS;
    if (data.length) {
        data.forEach(item => {
            let videoUrl = isUS ? item?.videoURL1200mp4?.english: item?.videoURL1200mp4?.chinese;
     
            const _suffix = Boolean(videoUrl) ? '1' : '0';
            const commonElement = {
                venue:  isUS ? item?.venue?.english: item?.venue?.chinese,
                workout: isUS ? item?.workout?.english: item?.workout?.chinese,
                videoUrl: videoUrl,
                trainingType: item.trainingType,
                track: isUS ? item?.track?.english: item?.track?.chinese,
                gear: item?.gear,
            }

            const lessCommonElement = {
                venue:  isUS ? item?.venue?.english: item?.venue?.chinese,
                trainingType: item.trainingType,
            }
            if (item.trainingType === "Barrier Trial") {
                dateContent[0].tw_list.push({
                    type: `B_${_suffix}`,
                    ...commonElement
                })
            } else if (item.trainingType === "Trotting"){
                dateContent[0].tw_list.push({
                    type: `T_${_suffix}`,
                    ...commonElement
                })
            }
            else if (item.trainingType === "Swimming"){
                dateContent[0].tw_list.push({
                    type: `S_${_suffix}`,
                    ...lessCommonElement
                })
            }
            else if (item.trainingType === "Gallop"){
                dateContent[0].tw_list.push({
                    type: `G_${_suffix}`,
                    ...commonElement
                })
            }
            else if (item.trainingType === "Race" && !exceptRace){
                dateContent[0].tw_list.push({
                    type: `R_${_suffix}`,
                    ...commonElement
                })
            }
            else if (item.trainingType === "Equine Treadmill"){
                dateContent[0].tw_list.push({
                    type: `ET_${_suffix}`,
                    ...lessCommonElement
                })
            }
            else if (item.trainingType === "Aqua Walker"){
                dateContent[0].tw_list.push({
                    type: `AW_${_suffix}`,
                    ...lessCommonElement
                })
            }
        });
    }

    function compare(property) {
        return (a, b) => {
            const rules = ['Race', 'Barrier Trial', 'Gallop', 'Trotting', 'Swimming', 'Equine Treadmill', 'Aqua Walker'];
            return rules.indexOf(a[property]) - rules.indexOf(b[property]);
        } 
    }
    dateContent[0].tw_list?.sort(compare('trainingType'))

    return dateContent
}

export {
    getTCDataContent,
}