import Dialog from '@mui/material/Dialog';
import React, { Component, useEffect, useState, useRef,} from 'react'
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from "react-i18next";
import Qmark from '../img/Qmark.svg'
import '../Css/QmarkIconDialog.css'

const QmarkIconDialog = (props) => {
    const { id, type, description } = props;
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const handleClose = () => setOpen(false);
    return (
        <div className="Qmark-container">
            <img id={id} src={Qmark} onClick={()=> setOpen(true)} />
            <Dialog
                open={open}
                // onClose={handleClose}
                className="Qmark-Dialog"
                >
                    <Grid p={2} textAlign="center">
                        {/* <Grid className="Qmark-title PlayFair" id={`Qmark-title-${type}`}>
                            {type === "SSC" ? t('Lbl_RD_SectionalSpeedComparison')  : t('Lbl_RD_RunningDistanceComparison')}
                        </Grid> */}
                        <Grid className="Qmark-content" id={`Qmark-content-${type}`}>
                            {type === "SSC" && t('Lbl_SS_IconTips')}
                            {type === "RDC" && description}
                            {type === "RPC" && description}
                            {type === "COR" && t('Lbl_CommentsOnRunning_IconTips')}
                        </Grid>
                       
                        {type === "COR" && <Grid className="Qmark-footer" id={`Qmark-footer-${type}`}>
                            {t('Lbl_CommentsOnRunning_Footer')}
                        </Grid>}

                        {type === "SSC" && <Grid className="Qmark-footer" id={`Qmark-footer-${type}`}>
                            {t('Lbl_IconTips_Footer')}
                        </Grid>}

                        <Grid className="Qmark-button" id={`Qmark-button-${type}`} onClick={handleClose}>
                            {t('Lbl_GotIt')}
                        </Grid>
                        
                    </Grid>
            </Dialog>
        </div>
    )
}
 
export default QmarkIconDialog;