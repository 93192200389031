
import styles from './css/SectionalTime.module.css'
import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Divider, Grid, Paper, Typography, Box } from '@mui/material';
import { useTranslation } from "react-i18next";
import SectionalTabs from './SectionalTabs';
import moment from 'moment';

function customParseFloat(str) {
    const strSplit = str.split(":");
    if (strSplit.length > 1) {
        return strSplit[0]*60 + parseFloat(strSplit[1])
    }
    return parseFloat(str)
}

function SectionalTime({ data, horseName }) {
    const { t, i18n } = useTranslation();
    const { id, lang, ownerType } = useParams();
    const [value, setValue] = useState(0);
    const centerCss = {
        display: "flex",
        alignItems: "center"
    }
    const clickTab = (val) => {
        console.log(val)
        setValue(val);
    }


    function getUSPart(idx) {
        let part = '';
        switch (idx) {
            case 1:
                part = '1st';
                break;
            case 2:
                part = '2nd';
                break;
            case 3:
                part = '3rd';
                break;
            default:
                part = `${idx}th`;
                break;
        }

        return part
    }
    const getSectionSplitTime = (type) => {
        const splitTime = type === 'horse' ? data?.sectionTimeSplits : data?.raceTimeSplits;
        let resultArr = [];
        splitTime?.map((item, index) => {
            if (splitTime?.length - index <= 4) {
                resultArr.push(item.sectionTime)
            }
            return ''
        })
        return resultArr;
    }
    const getRenderTimeData = (data, type) => {
        const splitTimeArr = getSectionSplitTime(type)
        console.log(splitTimeArr,'arr')
        const arrLength = data?.length - 2
        let resultData = []
        data?.map((item, index) => {
            if (index < arrLength) {
                if (data?.length - index <= 4) {
                    resultData.push({
                        sectionNo: item.sectionNo,
                        sectionTime: index === arrLength - 1 ?((Number(splitTimeArr[2]))&&(Number(splitTimeArr[3])))?(Number(splitTimeArr[2]) + Number(splitTimeArr[3])).toFixed(2):"--" : (Number(splitTimeArr[0]) + Number(splitTimeArr[1])).toFixed(2),
                        extraTime: index === arrLength - 1 ? `${splitTimeArr[2]} ${splitTimeArr[3]?splitTimeArr[3]:'　--'}` : `${splitTimeArr[0]} ${splitTimeArr[1]}`
                    })
                } else {
                    resultData.push({
                        sectionNo: item.sectionNo,
                        sectionTime: item.sectionTime,
                    })
                }
            }
            return '';
        })
        return resultData.map((i) => {
            if (Number(i.sectionTime) === 0) {
                return { sectionNo: i.sectionNo, sectionTime: '--' }
            }else{
                return i;
            }
        });
    }
    // const RenderHorseSectionInto = () => {
    //     const { t, i18n } = useTranslation();
    //     const renderData = getRenderTimeData(data?.sectionTimeSplits, 'horse')
    //     return (
    //         <div className={styles.SectionalWrapper} id="RD_Sec_Content">
    //             {
    //                 renderData.map((i, idx) => {
    //                     const isUS = window.globalConfig.isLangUS;
    //                     let part = ''
    //                     part = isUS ? t('Lbl_SecPart').replace('{{Num}}', getUSPart(i.sectionNo))
    //                         : t('Lbl_SecPart').replace('{{Num}}', i.sectionNo)
    //                     return (
    //                         <>
    //                             <div className={styles.SectionContentItem}>
    //                                 <p id={`RD_SecL_${idx}`} className={styles.ItemPlace}>{part}</p>
    //                                 <p id={`RD_SecT_${idx}`} className={styles.ItemContent}>{i.sectionalTime}</p>
    //                                 <p id={`RD_SecS_${idx}`} className={styles.ItemExtra}>{i.extraTime?i.extraTime:''}</p>
    //                             </div>
    //                             {<Divider orientation="vertical" flexItem style={{ background: '#E0E0E0', opacity: '0.3' }} />}
    //                         </>
    //                     )
    //                 })
    //             }
    //             <div className={styles.SectionContentItem}>
    //                 <p id={`FinishTime`} className={styles.ItemPlace}>{t('Lbl_Time')}</p>
    //                 <p id={`FinishTimeVal`} className={styles.ItemContent}>{data?.finalTimeOfRace}</p>
    //             </div>

    //         </div >
    //     )
    // }
    const RenderHorseSectionInfo = () => {
        const { t, i18n } = useTranslation();
        // console.log('data?.sectionTimeSplits?',data?.sectionTimeSplits.length>0?getRenderTimeData(data?.sectionTimeSplits, 'horse'):data.sectionInfos)
        const renderData = data?.sectionTimeSplits?.length>0&&data.sectionTimeSplits.length!=data.sectionInfos.length?getRenderTimeData(data?.sectionTimeSplits || [], 'horse'):data?.sectionInfos
        console.log(data,"REND")
        //getRenderTimeData(data?.sectionTimeSplits, 'horse')
        return (
            <div className={styles.SectionalWrapper} id="RD_Sec_Content">
                {
                    renderData?.map((i, idx) => {
                        const isUS = window.globalConfig.isLangUS;
                        let part = ''
                        part = isUS ? t('Lbl_SecPart').replace('{{Num}}', getUSPart(i.sectionNo))
                            : t('Lbl_SecPart').replace('{{Num}}', i.sectionNo)
                        return (
                            <>
                                <div className={styles.SectionContentItem}>
                                    <p id={`RD_SecL_${idx}`} className={styles.ItemPlace}>{part}</p>
                                    <p id={`RD_SecT_${idx}`} className={styles.ItemContent}>{i.sectionTime}</p>
                                    <p id={`RD_SecS_${idx}`} className={styles.ItemExtra}>{i.extraTime?i.extraTime:''}</p>
                                </div>
                                {<Divider orientation="vertical" flexItem style={{ background: '#CED3D9', opacity: '0.3' }} />}
                            </>
                        )
                    })
                }
                <div className={styles.SectionContentItem}>
                    <p id={`FinishTime`} className={styles.ItemPlace}>{t('Lbl_Time')}</p>
                    <p id={`FinishTimeVal`} className={styles.ItemContent}>{data?.finalTimeOfRace ? data?.finalTimeOfRace : "--"}</p>
                </div>

            </div >
        )
    }

    const formatSeconds = (sec) => {
        const seconds = Number(sec)
        if (seconds >= 60) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = (seconds % 60).toFixed(2);
            return `${minutes}:${remainingSeconds<10?'0'+remainingSeconds:remainingSeconds}`;
            //const milliseconds = seconds * 1000;
            // const duration = moment.duration(milliseconds);
            // const minutes = duration.minutes();
            // const secondsValue = duration.seconds();
            // const millisecondsValue = Math.floor(duration.milliseconds() / 10);
            // return `${minutes}:${secondsValue < 10 ? `0${secondsValue}` : secondsValue}.${millisecondsValue < 10 ? `0${millisecondsValue}` : millisecondsValue}`;
        } else {
            return parseFloat(sec).toFixed(2);
        }
    }

    const getRaceTime = (data) => {
        const resultArr = []
        data?.map((item, index) => {
            if (Number(index) > 0) {
                resultArr.push((Number(item.sectionTime) + Number(resultArr[index - 1])).toFixed(2))
            } else {
                resultArr.push(item.sectionTime)
            }
        })
        return resultArr.map((i) => formatSeconds(i));
    }
    const RenderRace = () => {
        const { t, i18n } = useTranslation();
        
        console.log(data?.raceTimeSplits,"ren")
      
        const resultArr = [];
        let raceTime = [];
        data?.raceTimeSplits?.forEach(obj => {
            const {sectionNo,sectionTime,gpSec} = obj;
            console.log(resultArr.hasOwnProperty(gpSec),gpSec,'have')
            if(gpSec==-1){
                resultArr.push(obj);
                raceTime.push(obj.sectionTime)
            }
            if(!resultArr.some(obj=>obj.gpSec==gpSec)){
                obj.extraTime = sectionTime;
                resultArr.push(obj);
            }else{
                resultArr.map(element=>{
                    if(element.gpSec==obj.gpSec&&obj.gpSec!=-1){
                        element.extraTime = element.sectionTime+" "+obj.sectionTime;     
                        element.totalSectionTime = (parseFloat(element.sectionTime)+ parseFloat(obj.sectionTime)).toFixed(2); 
                        raceTime.push(formatSeconds(element.totalSectionTime))
                      //  element.sectionTime = parseFloat(element.sectionTime)+ parseFloat(obj.sectionTime);         
                    }

                })
            }
        });
        for (let i = 1; i < raceTime.length; i++) {
            raceTime[i] = formatSeconds((customParseFloat(raceTime[i]) + customParseFloat(raceTime[i-1])))
        }   
        return (
            <div className={styles.SectionalWrapper2} id="RD_Race_ContentL">
                <div className={styles.SectionContentItemFirstColumn}>
                    <p className={styles.ItemPlaceRace} id="RD_Race_Text1">{t('Lbl_RaceTime')}</p>
                    <p className={styles.ItemPlaceRace} id="RD_Race_Text2">{t('Lbl_SectionalTime')}</p>
                </div>
                <div className={styles.SectionRightContent} id="RD_Race_ContentR">
                    {/* {renderData.map((i, idx) => {
                        return (
                            <div className={styles.SectionContentItem2} key={i.sectionTime + idx}>
                                <p id={`RD_RaceS_${idx}`} className={styles.ItemContent}>{`(${raceTime[idx]})`}</p>
                                <p id={`RD_RaceS_${idx}`} className={styles.ItemContent} style={{ paddingTop: 10 }}>{i.sectionTime}</p>
                                <p id={`RD_RaceS_${idx}`} className={styles.ItemExtra}>{i.extraTime}</p>
                            </div>
                        )
                    })} */}
                    {resultArr.map((i, idx) => {
                        return (
                            <div className={styles.SectionContentItem2} key={i.sectionTime + idx}>
                                <p id={`RD_RaceS_${idx}`} className={styles.ItemContent}>{`(${raceTime[idx]})`}</p>
                                <p id={`RD_RaceS_${idx}`} className={styles.ItemContent} style={{ paddingTop: 10 }}>{i.totalSectionTime?i.totalSectionTime:i.sectionTime}</p>
                                <p id={`RD_RaceS_${idx}`} className={styles.ItemExtra}>{i.extraTime}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    return (
        <div id='FR_container' className={styles.Base}>
            <div className={styles.Header}>
                <div id='SectionalTimeTitle' className={`${styles.Title} PlayFair`}>
                    {t("Lbl_SectionalTime")}
                </div>
                <div style={{ flex: 1 }} />
            </div>
            <Grid container px={2} >
                <Grid container py={2} className={styles.TopContainer}>
                    <SectionalTabs
                        horseName={horseName}
                        clickTab={(val) => clickTab(val)} value={value} />

                    {value === 0 && RenderHorseSectionInfo()}
                    {value === 1 && RenderRace()}
                </Grid>
            </Grid>
        </div >
    )
}
export default SectionalTime;