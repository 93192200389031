
import { 
    Grid, 
} from '@mui/material';
import { useState, useEffect, Fragment } from 'react';
import {  Drawer } from '@mui/material';
import './index.css'
import { useTranslation } from "react-i18next";
import FullRace from './FullRace';
import SplitRace from './SplitRace';
import Picker from '../Picker/PickerComponent';
import close from '../../img/Trackwork/close.svg';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isValidVenueCode } from '../../utils';
const AllSplitDistance = [
    0, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000, 2200
]

const runnerResults = [
    {
      "id": "HK_2010_M144",
      "runningDistance": 0,
      "barrierDrNo": 12,
      "horseName": {
        "code": "M144",
        "chinese": "勇創潮流",
        "english": "TRENDY WIN"
      },
      "winOdds": 0,
      "horseNo": 2,
      "placeNo": "01",
      "sectionTimeSplits": [],
    //   "rail": "1,2,3,4,5,6,7,8,9,10,11",    // HV
    //   "rail": "1,2,3,4,5,6,7,8,9",          // AWT
      "rail": "1,2,3,4,5,6,7,8,7,10,11,12", // TURF
    },
    {
      "id": "HK_2010_M148",
      "runningDistance": 0,
      "barrierDrNo": 1,
      "horseName": {
        "code": "A148",
        "chinese": "公証福將",
        "english": "MEGA HEART"
      },
      "winOdds": 0,
      "horseNo": 3,
      "placeNo": "02",
      "sectionTimeSplits": [],
    //   "rail": "1,2,3,4,5,6,7,8,9,10,11",    // HV
     // "rail": "1,2,3,4,5,6,7,8,9",          // AWT
        "rail": "1,2,3,4,5,6,7,8,9,10,11,12", // TURF
    },
    {
      "id": "HK_2014_T422",
      "runningDistance": 0,
      "barrierDrNo": 11,
      "horseName": {
        "code": "T422", 
        "chinese": "綠野先駒",
        "english": "JENERATOR"
      },
      "winOdds": 0,
      "horseNo": 5,
      "placeNo": "02",
      "sectionTimeSplits": [],
    //   "rail": "1,2,3,4,5,6,7,8,9,10,11",    // HV
    //   "rail": "1,2,3,4,5,6,7,8,9",          // AWT
      "rail": "1,2,3,4,5,6,7,8,9,10,11,12", // TURF
    },
    {
      "id": "HK_2015_V013",
      "runningDistance": 0,
      "barrierDrNo": 7,
      "horseName": {
        "code": "V013",
        "chinese": "閃電王",
        "english": "MCQUEEN"
      },
      "winOdds": 0,
      "horseNo": 7,
      "placeNo": "04",
      "sectionTimeSplits": [],
    //   "rail": "1,2,3,4,5,6,7,8,9,10,11",    // HV
    //   "rail": "1,2,3,4,5,6,7,8,9",          // AWT
      "rail": "1,2,3,4,5,6,7,8,9,10,11,12", // TURF
    }
  ]


const RPCompare = (props) => {
    const { data, mCLoydButtom, titleId } = props;

    // const _distance =  2200
    // const winningHorse = true;
    const {
        _distance,
        trackCode,
        venueCode,
        runnerResults = [],
        id,
        brandNumber,
        winningHorse,
    } = data;
    
    const { t, i18n } = useTranslation();
    const [horseList, setHorseList] = useState([]);
    const [wholeHorseList, setWholeHorseList] = useState([]); // 用于选择下拉时数据准备
    const [wholeDis, setwholeDis] = useState([]); // 用于切换距离
    const [isDisLeft, setisDisLeft] = useState(true);
    const [isDisRight, setisDisRight] = useState(false);
    const [currentDis, setCurrentDis] = useState(0);
    const [value, setValue] = useState(["0"]);
    const [oldValue, setoldValue] = useState(["0"]);
    const [currentHorse, setcurrentHorse] = useState("");
    const [trackType, setTrackType] = useState("");
    const [railData, setRailData] = useState({});
    const [open, setOpen] = useState(false);
    const [showSecondPla, setshowSecondPla] = useState(false);
    const [secondPlaText, setsecondPlaText] = useState('');
    const [noDataCalcHeight, setnoDataCalcHeight] = useState('');

    const getMultisWinners = (data = []) =>
        data.filter(h => parseInt(h.placeNo) === 1)

    const isMultisWinners = (data = []) => getMultisWinners(data).length > 1
    
    const isMultisOtherPla =  (data = [], pla) => getMultisOtherPla(data, pla).length > 1
    const getMultisOtherPla = (data = [], pla) =>
        data.filter(h => parseInt(h.placeNo) === pla)

    const setTempRailData = (targetData, tempRailData, setCurrentDis) =>{
        // 获取分段区间
        const splitLength = targetData[0]?.rail?.split(",")?.length;
        const splitDistance = AllSplitDistance.slice(0, splitLength);
        // 赋值
        for (let i = 0; i <= splitDistance.length - 1; i++) {
            let distanceItem = splitDistance[i];
            tempRailData[distanceItem] = targetData.map(h=> {
                return {
                    horseName: h?.horseName?.code,
                    rail: h?.rail?.split(",")[i],
                }
            })
        }
        setwholeDis(splitDistance);
        setCurrentDis(AllSplitDistance[splitLength - 1]);
    }

    useEffect(() => {
        const noneDataCalcFn = () =>   setnoDataCalcHeight(`calc(100% - ${document.querySelector(`#${titleId}`)?.clientHeight || 0}px)`);
        // owner的马没成绩
        const ownerHorseNoneData = runnerResults
            .filter(h => parseInt(h.placeNo) <= 0 && id === h.id)
        if (ownerHorseNoneData.length || !isValidVenueCode(venueCode)) {
            noneDataCalcFn();
            return;
        }

        // 数据条件是否满足
        const validData = runnerResults
         .filter(h => parseInt(h.placeNo) > 0 && h?.rail)

         // 不满足
        if (!validData.length || !isValidVenueCode(venueCode)) {
            noneDataCalcFn();
            return
        }

        let tempRailData = {};  // 待组装数据
        let targetData = [];    // 待处理目标数据

        // 数据符合
        if (validData.length) { 
            // owner的马没赢，跟第一名比
            if (!winningHorse) {
                targetData = validData.filter(h => parseInt(h.placeNo) === 1 || id === h.id)

                // 存在平头马
                if (isMultisWinners(validData)) {
                    // 备份完整数据
                    setWholeHorseList(targetData);

                    let dropDownList = getMultisWinners(validData)
                    .map(h => i18n.language === 'ch' ? h?.horseName?.chinese : h?.horseName?.english)

                    const fulldropDownList = dropDownList.map(h => h + ` ${t("Lbl_WinningHorse")}`)
                    setHorseList(fulldropDownList);
                    setcurrentHorse(dropDownList[0]); // 默认0下标

                    // 保留owner和0下标 两条数据
                    targetData = targetData.filter(h =>
                        [
                            h?.horseName?.chinese,
                            h?.horseName?.english
                        ].includes(dropDownList[0]) || (id === h.id))
                } else {
                    const compareHorse = targetData.filter(h => parseInt(h.placeNo) === 1);
                    const tempcurrentHorse = i18n.language === 'ch' ? 
                    compareHorse[0]?.horseName?.chinese : compareHorse[0]?.horseName?.english;
                    setcurrentHorse(tempcurrentHorse);
                }
                
                setTempRailData(targetData, tempRailData, setCurrentDis)
                setRailData(tempRailData);
            } else {
                // ！！！ owner的马赢了  ！！！！
                const isManysSecondPla = isMultisOtherPla(validData, 2);
                const isManysThirdPla = isMultisOtherPla(validData, 3);
                const isManysForthPla = isMultisOtherPla (validData, 4);
                targetData = validData.sort((a, b) => a.placeNo - b.placeNo);
                
                let comparePlaceNo = 0;
                const comparePlaceTextMap = {
                    "2": t("Lbl_SecondPla"),
                    "3": t("Lbl_ThirdPla"),
                    "4": t("Lbl_FourthPla"),
                }

                if (isManysSecondPla) comparePlaceNo = 2
                else if (isManysThirdPla) comparePlaceNo = 3
                else if (isManysForthPla) comparePlaceNo = 4

                const setReadyData = (pla) => {
                    const secPlaText = comparePlaceTextMap[pla];
                    setshowSecondPla(true);
                    setsecondPlaText(secPlaText)
                    // 备份完整数据
                    setWholeHorseList(targetData);

                    let dropDownList = getMultisOtherPla(validData, pla)
                    .map(h => i18n.language === 'ch' ? h?.horseName?.chinese : h?.horseName?.english)

                    const fulldropDownList = dropDownList.map(h => h + ` ${secPlaText}`)
                    setHorseList(fulldropDownList);

                    setcurrentHorse(dropDownList[0]); // 默认0下标
                    // 保留owner和0下标 两条数据
                    targetData = targetData.filter(h =>
                        [
                            h?.horseName?.chinese,
                            h?.horseName?.english
                        ].includes(dropDownList[0]) || (id === h.id))
                }
                switch (comparePlaceNo) {
                    case 2:
                    case 3:
                    case 4:
                        setReadyData(comparePlaceNo)
                        break;
                    default:  
                    // owner的马赢了，无多个第二,三，四名
                    const ownerHorse = targetData.filter(h => id === h.id);
                    const compareHorse = targetData.filter(h => parseInt(h.placeNo) !== 1);

                    targetData = [...ownerHorse, compareHorse?.[0]];
                    const tempcurrentHorse = i18n.language === 'ch' ? 
                        compareHorse[0]?.horseName?.chinese : compareHorse[0]?.horseName?.english;
                    setcurrentHorse(tempcurrentHorse);
                    break;
                }
                
                setTempRailData(targetData, tempRailData, setCurrentDis);
                setRailData(tempRailData);
            }

            switch (venueCode) {
                case 'HV':
                    setTrackType('HV')
                    break;
                case 'ST':
                    trackCode === 'AWT' && setTrackType('ST_AWT');
                    trackCode === 'TURF' && setTrackType('ST_TURF');
                    break;
                default:
                    break;
            } 
        }  

    }, [])

    const onArrowClick = (type) => {
        const len = wholeDis.length;
        const lastDis = wholeDis[len - 1];
        if (currentDis === 0 && type === 'right') return 
        if (currentDis === lastDis && type === 'left') return 

        let newDis;
        switch (type) {
            case 'left':
                newDis = currentDis + 200;
                break;
            case 'right':
                newDis = currentDis - 200;
                break;
            default:
                break;
        }
        setCurrentDis(newDis)

        switch (newDis) {
            case 0:
                setisDisRight(true);
                setisDisLeft(false)
                break;
            case lastDis:
                setisDisRight(false);
                setisDisLeft(true)
                break;
            default:
                setisDisRight(false);
                setisDisLeft(false)
                break;
        }
    }

    const isValidData = (data) => (Object.keys(data).length > 0) && isValidVenueCode(venueCode);

    const onScrollChange = (v) => {
        setValue(v);
    };
    const onValueChange = (v) => {
        setValue(v);
      };
    const onDrawerBtn = () => {
        const tempcurrentHorse = horseList[value]
        const lastIdx = tempcurrentHorse.lastIndexOf(" (");
        const tarHorse = tempcurrentHorse.substring(0, lastIdx);
        setcurrentHorse(tarHorse)

        const targetData = wholeHorseList
        .filter(i => [
            i?.horseName?.chinese,
            i?.horseName?.english
        ].includes(tarHorse) || id === i.id);
        let tempRailData = {};

        setTempRailData(targetData, tempRailData, setCurrentDis)
        setRailData(tempRailData);

        setOpen(false);
        setoldValue(value);
        setisDisLeft(true);
        setisDisRight(false);
    }


    const renderCurrentDisText = () => {
        const len = wholeDis.length;
        const lastDis = wholeDis[len - 1];

        const unit = i18n.language === 'ch' ? '米' : 'M';
        
        return currentDis === 0 ? t('Lbl_Finish') : `${currentDis === lastDis ? t('Lbl_StartTo') : ''}${currentDis}${unit}`
    }

    const onDrawerClose = () => {
        setValue(oldValue);
        setOpen(false);
    }
    
    return (
        <Fragment>
            <Grid container className='RPCompare-root' style={{
                height: isValidData(railData) ? "unset" : noDataCalcHeight
            }}>
                {
                        isValidData(railData) ? <div className="RPCompare-content">
                            {
                                horseList.length > 0 && <>
                                    <div className="RPCompare-label">{t('Lbl_RD_MyHorse')} VS:</div>
                                    <div className="CompareHorse" onClick={() => {
                                        setValue(oldValue);
                                        setOpen(true);
                                    }}>
                                        <p>{currentHorse} {showSecondPla ? secondPlaText : t("Lbl_WinningHorse")}</p>
                                        <ExpandMoreIcon/>
                                    </div>
                                </>
                            }

                            <FullRace 
                                distance={_distance}
                                trackType={trackType}
                                currentDis={currentDis}/>

                            <div className='RPCompare-Arrow'>
                                <div className={`RPCompare-Arrow-Item ${isDisLeft ? "Arrow-disable" : ""}`}>
                                    <NavigateBeforeIcon onClick={() => onArrowClick('left')}/>
                                </div>
                                <span>{renderCurrentDisText()}</span>
                                <div className={`RPCompare-Arrow-Item ${isDisRight ? "Arrow-disable" : ""}`}>
                                    <NavigateNextIcon onClick={() => onArrowClick('right')}/>
                                </div>
                            </div>

                            <SplitRace 
                                brandNumber={brandNumber}
                                distance={_distance}
                                railData={railData}
                                trackType={trackType}
                                currentDis={currentDis}/>       

                            <div className='RP-square-container' id="RPmyhorse">
                                <div className='RP-square' id="squareColor_myhorse_RP" />
                                <div className='RP-square-text'>{t('Lbl_RD_MyHorse')} {winningHorse ? t("Lbl_WinningHorse") : ""}</div> 
                            </div>
                            
                            <div className='RP-square-container' id="RPCompare">
                                <div className='RP-square-2' id="squareColor_myhorse_RP_2" />
                                <div className='RP-square-text'>{currentHorse} {showSecondPla ? secondPlaText : !winningHorse ? t("Lbl_WinningHorse") : ""}</div>
                            </div>

                        </div> : <div className="RPCompare-noData"> 
                            {t('Lbl_RailPositionNoData')}
                        </div>
                    }
                  
            </Grid>
            {isValidData(railData) ? <>
                <div className='RP-square-Reminder' id="RPReminder">{t('Lbl_RD_Reminder')}</div>
                {mCLoydButtom}
            </> : null}
           

            <Drawer
                className='WMInfoDrawer'
                anchor={"bottom"}
                open={open}
                onClose={() => onDrawerClose()}
            >
                <div className='WMInfo-drawer'>
                    <div className='WMInfo-header PlayFair' id="WM_IndexTitle">
                    {t('Lbl_RD_MyHorse')} VS
                    <img src={close} onClick={() => onDrawerClose()} id="WM_Index_Close" />
                    </div>

                    <Picker
                      idPrefix="RP_PickerItem"
                      selectedValue={value}
                      onValueChange={onValueChange}
                      onScrollChange={onScrollChange}
                      PList={horseList}
                    />

                    <div className='WMInfo-btn' onClick={onDrawerBtn} id="WM_IndexBtn">
                    {t('Lbl_Done')}
                    </div>

                </div>
            </Drawer>
        </Fragment>
    )
}

export default RPCompare;