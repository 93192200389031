import { Divider, Grid, Typography } from "@mui/material";
import styles from '../Css/HorseStat.module.css'
import { useEffect,useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import { isNull } from '../../utils';

function HorseStat({data,lang}){
    const{t,i18n} = useTranslation();
    useEffect(()=>{
        console.log(window.LangType)
    },[window])
    const convertDollar=(number)=>{
        return parseFloat(number).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });
    }
    return(
        <>
        {data!==null?
        <Grid className={styles.statContainer} container direction="row" justifyContent="center" alignItems="center" my={2}>
            {/* <Grid item xs={4} ml={1}> */}
            <div className={styles.numberItem}>
                <Typography className={"Label3-Medium fontSize11"} >{t('Lbl_SeasonStakes')} </Typography>
                <Typography id='FR_detail_seasonStakes' className="Number/Number2-Bold fontSize16">{convertDollar(data.winningRecord.seasonStakes)}</Typography>
            </div>
              
            {/* </Grid> */}
            {/* <Divider orientation="vertical" flexItem style={{background:'#CED3D9',opacity:'0.3'}}  /> */}
            {/* <Grid item xs={4} ml={1}> */}
            <div className={styles.numberItem} style={{
                margin: 'auto',
                paddingLeft: "calc(4%)",
                borderLeft: "1px solid rgba(206, 211, 217, .3)",
                // borderRight: "1px solid rgba(206, 211, 217, .3)",
            }}>
                <Typography className={"Label3-Medium fontSize11"}>{t('Lbl_TotalStakes')}</Typography>
                <Typography id='FR_detail_totalStakes' className="Number/Number2-Bold fontSize16">{ convertDollar(data.winningRecord.totalStakes)}</Typography>
            </div>
            {/* </Grid> */}
            {/* <Divider orientation="vertical" flexItem style={{background:'#CED3D9',opacity:'0.3'}} /> */}
            {/* <Grid item xs={3} ml={1}> */}
            <div className={styles.numberItem} style={{
                paddingLeft: "calc(4%)",
                borderLeft: "1px solid rgba(206, 211, 217, .3)",
                // borderRight: "1px solid rgba(206, 211, 217, .3)",
            }}>
                <Typography className={"Label3-Medium fontSize11"}>{t('Lbl_CurrentRating')}</Typography>
                <Typography id='FR_detail_rating' className="Number/Number2-Bold fontSize16">{data.rating<=0?'--':data.rating}</Typography>
            </div>
            {/* </Grid> */}
            <Grid item container xs={12} mx={0} my={1} className={styles.Trophies}>
                <Typography id='FR_detail_NoOfStarts' className={"fontSize14"}> {t('Lbl_NoOfStarts')}</Typography> 
                <Grid item xs/>
                <Typography  className="Number/Number2-Bold" id = "HorseStat_NoOfPlacesFirst"  style={{textAlign:'center'}}>{data.winningRecord.firstPlace} 
                    <div className={styles.Gold}/>
                </Typography>
                <Typography className="Number/Number2-Bold" > &nbsp;/&nbsp;
                </Typography>
                <Typography className="Number/Number2-Bold" id = "HorseStat_NoOfPlacesSecond" style={{textAlign:'center'}}>{data.winningRecord.secondPlace} 
                    <div className={styles.Sliver}/>
                </Typography>
                <Typography className="Number/Number2-Bold" >&nbsp;/&nbsp;</Typography>
                <Typography className="Number/Number2-Bold" id = "HorseStat_NoOfPlacesThird" style={{textAlign:'center'}}>{data.winningRecord.thirdPlace} 
                    <div className={styles.Bronze}/>
                </Typography>
                <Typography className="Number/Number2-Bold"> &nbsp;/&nbsp;</Typography>
                <Typography className="Number/Number2-Bold" id = "HorseStat_NoOfStarts" style={{textAlign:'center'}}>{data.winningRecord.totalRun} 
                    <div className={styles.Blue}/>
                </Typography>
               
            </Grid>
            <Grid item xs={12} mx={0}>
                <Typography id='FR_detail_remarks' className="Body/Body3 fontSize11">{t('Lbl_Remarks')}</Typography>
            </Grid>
        </Grid>:<></>}
        </>
    )
}

export default HorseStat;